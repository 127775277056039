import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
} from "@mui/material";
import React, { useContext } from "react";
import useFetch from "src/Components/Common/useFetch";
import { ActorTypesForRecon } from "../../../entity/recon-entity/ReconInterfaces";
import { ledgerUploadName } from "../../../Utils/Recon/Recon360/Constants";
import { Recon360Context } from "../Recon360";
import { ConfigPopupAnchorRecon360Context } from "./ColumnHeaderConfig";

const ReuseSanitizersModal = () => {
  const { businessPartnerSelected, setIsUsedAsTemplateOwn, setIsUsedAsTemplateBp, getLedgerConfigList } =
    useContext(Recon360Context);
  const { openReuseSanitizerModal, setOpenReuseSanitizerModal, fromWhichSide, groupsListRef } = useContext(
    ConfigPopupAnchorRecon360Context
  );
  const closeReuseSanitizersModal = (isSanitizer: boolean) => {
    saveReuseApi(isSanitizer);
    setOpenReuseSanitizerModal(false);
  };

  const saveReuseApi = async (isSanitizer: boolean) => {
    await useFetch("/api/CreateTemplateForReuseConfig", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        keepSanitizerRules: isSanitizer,
        type: fromWhichSide === ledgerUploadName.ownSide ? ActorTypesForRecon.ReconUser : ActorTypesForRecon.Partner,
        groups: groupsListRef.current,
      },
      thenCallBack: () => {
        if (fromWhichSide === ledgerUploadName.ownSide) {
          setIsUsedAsTemplateOwn(true);
        } else {
          setIsUsedAsTemplateBp(true);
        }
        getLedgerConfigList();
      },
    });
  };

  return (
    <div>
      <MuiDialog open={openReuseSanitizerModal} maxWidth="xs" fullWidth={true}>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle>Create a template?</DialogTitle>
          <IconButton
            onClick={() => {
              closeReuseSanitizersModal(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
          <FormLabel>Do you want Sanitizers also in template?</FormLabel>
        </DialogContent>
        <DialogActions className="dialog_footer space_around">
          <Button
            className="theme_btn"
            size="small"
            sx={{
              ".MuiButton-startIcon": {
                display: "inherit",
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            onClick={() => {
              closeReuseSanitizersModal(true);
            }}
          >
            Yes
          </Button>
          <Button
            className="theme_btn theme_btn_warning"
            size="small"
            onClick={() => {
              closeReuseSanitizersModal(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

export default ReuseSanitizersModal;
