import { ArrowBack, AttachFile, Close, Download, Edit, Person } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  List,
  ListItemButton,
  Paper,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import { EmailTemplate, MsmeMailThread, UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import FormatDate from "src/Utils/DateUtils";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerName, uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { EmailInput } from "../../ReconMIS/ClientReporting/AutoCompletes";
import { BcBetaContext } from "../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../CommonLegacy/CommonComponents";
import { IFrame } from "../CommonLegacy/MailThreadDialog";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../MsmePartnerPortal/CommonComponents";
import { BackdropLoader } from "../MsmePartnerPortal/PartnerPortal.common";
import { StateDispatch } from "./CommonComponents";
import { UpdateEmailTemplateDialog, UpdateUserEmailSettingDialog } from "./CommonDialogs";
import EmailEditorWithTemplate from "./EmailEditorWithTemplate";

interface ViewEmailThreadRes {
  emailThread: MsmeMailThread[];
  emailSubject: string;
  ownSideEmails: string[];
  toEmails: string[];
  ccEmails: string[];
}

interface ViewEmailThreadProps {
  setOpen: StateDispatch<boolean>;
  companyId?: string;
  branchCode?: string;
}

export const ViewReplyMailThread = ({ setOpen, ...props }: ViewEmailThreadProps): JSX.Element => {
  const emailList: MsmeMailThread[] = [];

  const { emailBusinessPartnerId, businessPartnerName, openReplyEmail, setOpenReplyEmail, setEmailTemplateContent } =
    useContext(PartnerCommsContext);
  const { listAllBusinessPartnersWSR, companyId, branchCode } = useContext(BcBetaContext);

  const [mailThreads, setMailThreads] = useState<MsmeMailThread[]>(emailList);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailsLists, setEmailsLists] = useState<{
    ownSideEmails: string[];
    toEmails: string[];
    ccEmails: string[];
  }>({
    ownSideEmails: [],
    toEmails: [],
    ccEmails: [],
  });

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  const handleListItemClick = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setEmailTemplateContent({} as EmailTemplate); // Replace with the actual type
    listAllBusinessPartnersWSR();
    setOpen(false);
  };

  const ViewEmailThread = () => {
    return useFetch<ViewEmailThreadRes>(API_ENDPOINTS.VIEW_EMAIL_THREAD.url, "GET", {
      failureMessage: API_ENDPOINTS.VIEW_EMAIL_THREAD.failureMessage,
      config: {
        params: {
          companyId: props.companyId,
          branchCode: props.branchCode,
          businessPartnerId: emailBusinessPartnerId,
        },
      },
      thenCallBack: (res) => {
        if (res.data) {
          setMailThreads(res.data?.emailThread?.length > 0 ? res.data?.emailThread : emailList);
          setEmailSubject(res.data.emailSubject || "");
          setEmailsLists({
            ownSideEmails: res.data.ownSideEmails || [],
            toEmails: res.data.toEmails || [],
            ccEmails: res.data.ccEmails || [],
          });
        }
      },
    });
  };

  useEffect(() => {
    const loadMailThread = async () => {
      setOpenBackDrop(true);
      await ViewEmailThread();
      setOpenBackDrop(false);
    };
    loadMailThread();

    return () => {
      handleClose();
    };
  }, []);

  const GetAttachmentsZip = async () => {
    return useFetch<{ buffer: string; fileName: string }>(API_ENDPOINTS.GET_ATTACHMENTS_ZIP.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ATTACHMENTS_ZIP.failureMessage,
      config: {
        params: {
          companyId: companyId,
          branchCode: branchCode,
          businessPartnerId: emailBusinessPartnerId,
          mailThreadIndex: mailThreads?.length - selectedIndex - 1, // reverse order
        },
      },
      thenCallBack: (res) => {
        const zipData = Buffer.from(res.data.buffer, "base64");
        const fileType = "application/x-zip-compressed";
        const blob = new Blob([zipData], { type: fileType });
        saveAs(blob, res.data.fileName);
      },
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        sx={{
          borderRadius: "32px",
          border: "1px solid #111",
          background: "#FFF",
        }}
        onClick={() => handleClose()}
      >
        Back
      </Button>

      <Box display="flex" minHeight={"80vh"} p={2} gap={2} justifyContent="center" flexWrap="wrap">
        {/* Left Menu */}
        <Paper sx={{ width: "100%", maxWidth: 360, maxHeight: "80vh" }}>
          <List component="nav" aria-label="main email list">
            {mailThreads?.map((item, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <Avatar
                    sx={{ bgcolor: emailsLists.ownSideEmails.includes(item.from) ? "#2296f3" : "#29b17c", mr: 2 }}
                  >
                    <Person />
                  </Avatar>
                  <Box className="textOverflow_hidden">
                    <Typography className="textOverflow_hidden" title={item.from} variant="body1" color="textPrimary">
                      {item.from}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {FormatDate(item.emailTime, "DD/MM/YYYY HH:mm:ss").toString()}
                    </Typography>
                  </Box>
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>

        {/* Right Section */}
        <Paper
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            // maxHeight: "100vh",
            overflow: "auto",
          }}
        >
          {/* Subject, BusinessPartnerName and Reply Button */}
          <Stack
            sx={{
              gap: 2,
              p: 2,
              bgcolor: "#fff",
              borderBottom: 1,
              borderColor: "#dedede",
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" color="textPrimary">
                {emailSubject}
              </Typography>
              <Button
                variant="outlined"
                startIcon={openReplyEmail ? <Close /> : <ArrowBack />}
                size="small"
                sx={{ borderRadius: "4px !important" }}
                onClick={() => setOpenReplyEmail(!openReplyEmail)}
                color={openReplyEmail ? "error" : "primary"}
              >
                {openReplyEmail ? "DISCARD" : "REPLY"}
              </Button>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="textSecondary">
                Business Partner:
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                {businessPartnerName}
              </Typography>
            </Box>
          </Stack>

          {/* Reply Email Section */}
          <Collapse in={openReplyEmail}>
            <ReplyEmail
              setOpenReplyEmail={setOpenReplyEmail}
              emailsList={emailsLists}
              emailBusinessPartnerId={emailBusinessPartnerId}
              afterSendEmail={() => {
                setOpenReplyEmail(false);
                setTimeout(() => {
                  ViewEmailThread();
                }, 5_000);
                setTimeout(() => {
                  ViewEmailThread();
                }, 10_000);
                listAllBusinessPartnersWSR();
              }}
            />
          </Collapse>

          {/* Viewing Email Section  */}
          {/* Email from, to, cc and date section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              p: 2,
              bgcolor: "#f8f8f8",
              borderBottom: 1,
              borderTop: openReplyEmail ? 1 : 0,
              borderColor: "#dedede",
            }}
          >
            <Avatar sx={{ bgcolor: "grey.300" }}>
              <Person />
            </Avatar>
            <Box flex={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" color="textPrimary">
                  {mailThreads[selectedIndex]?.from}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {FormatDate(mailThreads[selectedIndex]?.emailTime, "DD/MM/YYYY HH:mm:ss").toString()}
                </Typography>
              </Box>
              <Box display="flex" gap={2}>
                <Typography variant="body2" color="textPrimary">
                  To:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {mailThreads[selectedIndex]?.to?.join(", ")}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  CC:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {mailThreads[selectedIndex]?.cc?.join(", ")}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Download List Section */}
          <Box
            className="download_list_section"
            sx={{ p: 2, border: 1, borderColor: "#dfdfdf", borderTop: "0px", bgcolor: "#fff" }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {mailThreads[selectedIndex]?.attachments?.map((name, index) => (
                <Chip
                  key={index}
                  avatar={
                    <Avatar>
                      <AttachFile fontSize="small" sx={{ color: "#fff" }} />
                    </Avatar>
                  }
                  label={name}
                  onDelete={() => {
                    // Download Attachment
                    const fileName = mailThreads[selectedIndex]?.attachments[index];
                    const fileUri = mailThreads[selectedIndex]?.s3Link[index];

                    const link = document.createElement("a");
                    link.href = fileUri;
                    link.download = fileName;
                    link.style.display = "none";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                  deleteIcon={
                    <IconButton size="small">
                      <Download fontSize="small" color="info" />
                    </IconButton>
                  }
                  className="download-item"
                />
              ))}
            </Box>
            {mailThreads[selectedIndex]?.attachments?.length > 0 && (
              <Button className="download-all-btn" sx={{ mt: 2 }} color="primary" onClick={GetAttachmentsZip}>
                Download All
              </Button>
            )}
          </Box>

          {/* Email Body Section */}
          <Box p={2} flex={1} bgcolor="white">
            <IFrame referrerPolicy="no-referrer" content="abc" style={{ width: "100%", minHeight: 300 }}>
              <div
                className="unsafeHtmlBody"
                dangerouslySetInnerHTML={{ __html: mailThreads[selectedIndex]?.body }}
                style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: "#000C" }}
              />
            </IFrame>
          </Box>
        </Paper>
      </Box>
      <BackdropLoader openBackDrop={openBackDrop} loadingText={""} />
    </>
  );
};

export const EmailFields = ({ label, emails, isSender }: { label: string; emails: string[]; isSender?: boolean }) => {
  return (
    <Box className="email_field" sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="subtitle2" sx={{ color: "#00000061", mr: 2, width: 54 }}>
        {label}
      </Typography>

      <EmailFieldSingle emails={emails} isSender={isSender} />
    </Box>
  );
};

export const EmailFieldSingle = ({ emails, isSender, sx }: { emails: string[]; isSender?: boolean; sx?: SxProps }) => {
  return (
    <Box
      className="d_flex vertical_center_align"
      sx={{
        bgcolor: "background.paper",
        borderRadius: 1,
        p: 0.5,
        flex: 1,
        border: 1,
        borderColor: "#dfdfdf",
        minHeight: 34,
        overflow: "auto",
        gap: "4px",
        minWidth: 500,
        ...sx,
      }}
    >
      {emails &&
        emails.map((email, _i) => (
          <Chip
            key={email + _i}
            avatar={
              <Avatar sx={{ bgcolor: isSender ? "#1c5e21" : "#bdbdbd" }}>
                <Person sx={{ color: "#fff", fontSize: 14 }} />
              </Avatar>
            }
            variant="filled"
            label={email}
            color={isSender ? "success" : "default"}
            size="small"
            sx={{ "*": { fontFamily: "inherit", fontSize: 14 } }}
          />
        ))}
    </Box>
  );
};

interface ReplyEmailProps {
  setOpenReplyEmail: StateDispatch<boolean>;
  emailsList: { ownSideEmails: string[]; toEmails: string[]; ccEmails: string[] };
  emailBusinessPartnerId: number;
  afterSendEmail: () => void;
}

export const ReplyEmail = ({
  setOpenReplyEmail,
  emailsList,
  emailBusinessPartnerId,
  afterSendEmail,
}: ReplyEmailProps) => {
  const { actor } = useContext(userContext);
  // const [userEmailSetting, setUserEmailSetting] = useState<ListUserEmailSettingRes["userEmailSetting"]>();

  const [files, setFiles] = useState<File[]>([]);

  const { branchCode, companyId, currentRows, listUserEmailTemplates, withoutTemplateCheck, setWithoutTemplateCheck } =
    useContext(BcBetaContext);
  const { communicationTypeId, emailTemplates, emailTemplateContent, userEmailSetting } =
    useContext(PartnerCommsContext);
  const [emailSubject, setEmailSubject] = useState(emailTemplateContent?.emailSubject || "");
  const [editorContent, setEditorContent] = useState(emailTemplateContent?.emailBody || "");
  const [emailTemplateId, setEmailTemplateId] = useState(emailTemplateContent?.templateId || null);
  const [templateError, setTemplateError] = useState(false);
  const [openUpdateEmailSettingDlg, setOpenUpdateEmailSettingDlg] = useState(false);

  const [toEmails, setToEmails] = useState<string[]>(Array.isArray(emailsList.toEmails) ? emailsList.toEmails : []);
  const [ccEmails, setCcEmails] = useState<string[]>(Array.isArray(emailsList.ccEmails) ? emailsList.ccEmails : []);

  useEffect(() => {
    setToEmails(emailsList.toEmails);
    setCcEmails(emailsList.ccEmails);
  }, [emailsList]);

  const emailTemplateName =
    emailTemplates.find((item) => item.templateId === emailTemplateId)?.templateName ||
    emailTemplateContent?.templateName;

  // update email Template section
  const [openUpdateEmailTemplate, setOpenUpdateEmailTemplate] = useState(false);

  const checkIfUserEmailTemplateHasToUpdate = async () => {
    setWithoutTemplateCheck(false);

    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }
    setTemplateError(false);

    if (editorContent.trim() === "<div><br></div>") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", UseCaseType.balanceConfirmationBeta);
    bodyFormData.append("templateName", emailTemplateName);

    return new Promise<void>((resolve, reject) => {
      useFetch(API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.url, "POST", {
        failureMessage: API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.failureMessage,
        data: bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        thenCallBack: async (response) => {
          // check response here
          if (response.data.isTemplateUpdated) {
            setOpenUpdateEmailTemplate(true);
            resolve();
          } else {
            await sendEmail();
            resolve();
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const updateUserEmailTemplate = async () => {
    setOpenUpdateEmailTemplate(false);

    if (emailSubject.trim() === "") return;

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateName);
    bodyFormData.append("templateType", UseCaseType.balanceConfirmationBeta);
    bodyFormData.append("senderCompanyId", companyId);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return new Promise<void>((resolve, reject) => {
      useFetch(API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: async () => {
          await sendEmail();

          // reload templates after Adding/Updating User Email Template
          listUserEmailTemplates();

          resolve();

          // props.setLoader(true);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const sendEmail = async () => {
    const EndPointBC = API_ENDPOINTS.SEND_EMAIL.url;

    try {
      // get the text content from inner html string
      const parser = new DOMParser();
      const doc = parser.parseFromString(editorContent, "text/html");
      const textContent = doc.body.textContent || "";

      if (textContent.trim() === "") {
        toast.error(<CustomToast message={"Email Body Cannot be Empty"} />);
        return;
      }
    } catch (error) {
      console.error(error);
      toast.error(<CustomToast message={"Invalid Email Body"} />);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("companyId", companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", branchCode || DEFAULT_BRANCH_CODE);
    bodyFormData.append("to", toEmails.join(","));
    bodyFormData.append("cc", ccEmails.join(","));
    bodyFormData.append("communicationTypeId", communicationTypeId?.toString());

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return await useFetch(EndPointBC, "POST", {
      failureMessage: API_ENDPOINTS.SEND_EMAIL.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        afterSendEmail();
      },
      catchCallBack: (_err) => {
        // setShowLoadingIcon(false);
      },
    });
  };

  return (
    <>
      <Stack>
        <Box
          sx={{
            p: 2,
            bgcolor: "#f8f8f8",
            borderBottom: 1,
            borderColor: "#dedede",
          }}
        >
          <Stack spacing={2}>
            <Box className="d_flex" gap={2}>
              <EmailFields label="From:" emails={[userEmailSetting?.from_email]} isSender />
              <IconButton
                onClick={async () => {
                  // logic
                  const tab = "UserEmailSetting";
                  const authDialog = 1;

                  const params = `tab=${tab}&authDialog=${authDialog}`;
                  const uri = `/${actor.name}/reconSettings?${params}`;
                  window.open(uri, "_blank")?.focus();
                  setOpenUpdateEmailSettingDlg(true);
                }}
              >
                <Edit />
              </IconButton>
            </Box>

            <FormControl>
              <Box display={"inline-flex"} gap={4} alignItems="center">
                <FormLabel className="" sx={{ width: 40 }}>
                  To :
                </FormLabel>
                <EmailInput emails={toEmails} setEmails={setToEmails} label="" limitTags={3} sx={{ width: "100%" }} />
              </Box>
            </FormControl>

            <FormControl>
              <Box display={"inline-flex"} gap={4} alignItems="center">
                <FormLabel className="" sx={{ width: 40 }}>
                  CC :
                </FormLabel>
                <EmailInput emails={ccEmails} setEmails={setCcEmails} label="" limitTags={3} sx={{ width: "100%" }} />
              </Box>
            </FormControl>
          </Stack>
        </Box>

        {/* Email Editor With Template */}
        <EmailEditorWithTemplate
          inputEditorState={{
            editorContent: editorContent || "",
            emailSubject: emailSubject || "",
            templateError: templateError,
          }}
          returnEditorState={(emailSubject, editorContent, templateId, files) => {
            setEmailSubject(emailSubject);
            setEditorContent(editorContent);
            setEmailTemplateId(templateId);
            setFiles(files);
          }}
          sectionsCustomizations={{
            subject: currentRows[0]?.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation,
          }}
          otherProps={{
            branchCode: branchCode,
            companyId: companyId,
            listUserEmailTemplates: listUserEmailTemplates,
            templateType: UseCaseType.balanceConfirmationBeta,
          }}
        />

        <Box display="flex" justifyContent={"flex-end"} alignItems="center" bgcolor="white" p={2} gap={2} boxShadow={4}>
          <Button variant="text" color="inherit" onClick={() => setOpenReplyEmail(false)}>
            CANCEL
          </Button>
          <NdAsyncButton
            className="theme_btn"
            buttonComponent={Button}
            onClick={async () => {
              if (withoutTemplateCheck) {
                uiLogger(
                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_PARTNER_REQUESTS_REPLY_SEND_CLICK.functionName,
                  companyId,
                  branchCode,
                  { message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_PARTNER_REQUESTS_REPLY_SEND_CLICK.message }
                );
                await sendEmail();
              } else {
                uiLogger(
                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_SEND_VIEW_MAIL_SEND_CLICK.functionName,
                  companyId,
                  branchCode,
                  { message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_SEND_VIEW_MAIL_SEND_CLICK.message }
                );
                if (emailTemplateId) {
                  await checkIfUserEmailTemplateHasToUpdate();
                } else await sendEmail();
              }
              setWithoutTemplateCheck(false);
            }}
          >
            SEND EMAIL
          </NdAsyncButton>
        </Box>
      </Stack>

      {/* Update Email Template Dialog */}
      <UpdateEmailTemplateDialog
        open={openUpdateEmailTemplate}
        setOpen={setOpenUpdateEmailTemplate}
        yesAction={async () => {
          await updateUserEmailTemplate();
          uiLogger(uiLoggerName.ui_UpdatedEmailTemplate, companyId, branchCode);
        }}
        noAction={async () => {
          await sendEmail();
        }}
      />
      {/* Update User Email Settings Dialog */}
      <UpdateUserEmailSettingDialog open={openUpdateEmailSettingDlg} setOpen={setOpenUpdateEmailSettingDlg} />
    </>
  );
};

export default ViewReplyMailThread;
