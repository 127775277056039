import { formatDate } from "src/Utils/DateUtils";

export const MIS_MAIL_TYPES = {
  quickEmail: "Quick Email",
  automatedEmail: "Automated Email",
};

export const AUTOMATED_MAIL_FREQUENCY_LIST = ["daily", "weekly", "biweekly", "monthly"];

export enum AUTOMATED_MAIL_FREQUENCY_MAP {
  daily = "Daily",
  weekly = "Weekly",
  biweekly = "Bi-Weekly",
  monthly = "Monthly",
}

export interface TemplateDetails {
  id: number;
  name: string;
  isActive: boolean;
  attachedReports: { id: number; name: string }[];
  frequency: string;
  startDate: Date;
  endDate: Date;
  status: AUTO_MAIL_TEMPLATE_STATUS;
}
export enum AUTO_MAIL_TEMPLATE_STATUS {
  Active = "Active",
  Paused = "Paused",
  Completed = "Completed",
  Error = "Error",
}
export function generateEmailSchedule(startDate: Date, endDate: Date, frequency: string): string {
  const formattedEndDate = endDate ? formatDate(endDate) : null;

  switch (frequency) {
    case "daily":
      return formattedEndDate ? `Auto-Email every day till ${formattedEndDate}` : `Auto-Email every day`;
    case "weekly": {
      const weekDay = new Date(startDate).toLocaleString("en-US", { weekday: "long" });
      return formattedEndDate
        ? `Auto-Email on ${weekDay}, every week till ${formattedEndDate}`
        : `Auto-Email on ${weekDay}, every week`;
    }
    case "biweekly": {
      const biWeeklyWeekDay = new Date(startDate).toLocaleString("en-US", { weekday: "long" });
      return formattedEndDate
        ? `Auto-Email on ${biWeeklyWeekDay}, every alternate week till ${formattedEndDate}`
        : `Auto-Email on ${biWeeklyWeekDay}, every alternate week`;
    }
    case "monthly": {
      const monthDay = new Date(startDate).getDate();
      return formattedEndDate
        ? `Auto-Email on ${monthDay}${getOrdinalSuffix(monthDay)} of every month till ${formattedEndDate}`
        : `Auto-Email on ${monthDay}${getOrdinalSuffix(monthDay)} of every month`;
    }
    default:
      return "Invalid frequency";
  }
}

// Helper function to get the ordinal suffix (e.g., "st", "nd", "rd", "th")
function getOrdinalSuffix(day: number): string {
  const j = day % 10;
  const k = day % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
}
