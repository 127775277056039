import { Close, Delete } from "@mui/icons-material";
import { ContentPaste } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import BusinessDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import { ledgerUploadName } from "src/Utils/Recon/Recon360/Constants";
import { uiLoggerNamesRecon } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { ConfigTemplateType } from "../../../entity/recon-entity/ReconInterfaces";
import { Currencies } from "../../../Utils/Common/Constants";
import { DefaultCurrency } from "../../../Utils/MoneyUtils";
import Loader from "../../Common/Loader";
import {
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  TallyOrSapCompanyId,
} from "../PartnerCommunication/CommonLegacy/CommonComponents";

const options = [
  {
    value: "Customer",
    label: "Customer",
  },
  {
    value: "Vendor",
    label: "Vendor",
  },
];

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface PartnerUseCaseData {
  businessPartnerId: number;
  applicableTds: { value: number }[];
  relationship: string;
  erpSoftware: string;
}
interface EditBpProps {
  openBusinessPopup: boolean;
  businessPartnerId: number;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  setOpenBusinessPopup: StateDispatch<boolean>;
  storePartnerUseCaseMasterData?: React.MutableRefObject<PartnerUseCaseData[]>;
  consumerComponent?: "Recon360" | "PartnerMaster";
  consumerComponentWhichSide?: string;
  afterDelete?: () => void;
}

const EditBusinessPartner = (props: EditBpProps) => {
  const { actor } = useContext(userContext);
  const [configList, setConfigList] = useState<any[]>([]);
  const [selectedAccountingSoftware, setSelectedAccountingSoftware] = useState<string>("");
  const [selectedRelationship, setSelectedRelationship] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [companyGstNumber, setCompanyGstNumber] = useState<string>("");
  const initialTdsArr = [
    { value: "0.10000" },
    { value: "0.09524" },
    { value: "0.08929" },
    { value: "0.08475" },
    { value: "0.07813" },
  ];
  const [tdsArr, setTdsArr] = useState<{ value: any }[]>(initialTdsArr);
  const [errSelectedRelationship, setErrSelectedRelationship] = useState<boolean>(false);
  const [errCompanyName, setErrCompanyName] = useState<boolean>(false);
  const [errorPartnerNickname, setErrorPartnerNickname] = useState<boolean>(false);
  const [errCompanyGstNumber, setErrCompanyGstNumber] = useState<boolean>(false);
  const [errPinCode, setErrPinCode] = useState<boolean>(false);
  const [pinCode, setPinCode] = useState<string>("");

  const [vendorCode, setVendorCode] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const [showLoadingIconUpdate, setShowLoadingIconUpdate] = useState<boolean>(false);
  const [openDeleteBp, setOpenDeleteBp] = useState(false);
  const [currency, setCurrency] = useState<string>(DefaultCurrency.INR);
  const [partnerNickname, setPartnerNickname] = useState<string>("");

  // Define GST and TDS values
  const gstValues = [0, 5, 12, 18, 28] as const;
  const tdsValues = [0.1, 1, 2, 5, 10] as const;
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [cellToggles, setCellToggles] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [allCombinations, setAllCombinations] = useState([]);

  useEffect(() => {
    getLedgerConfigList();
    getBusinessPartnerDetails();
    getAllCombinations();
    // eslint-disable-next-line
  }, []);

  const getAllCombinations = () => {
    const combinationsArr = gstValues.flatMap((gst) =>
      tdsValues.map((tds) => ({
        gst,
        tds,
        combination: (tds / (1 + gst / 100)).toFixed(5),
      }))
    );

    setAllCombinations(combinationsArr);
  };

  // Modify the toggleCell function to update tdsArr based on the selected combination values
  const toggleCell = (rowIndex, colIndex) => {
    const newCellToggles = [...cellToggles];
    newCellToggles[rowIndex][colIndex] = !newCellToggles[rowIndex][colIndex];

    const selectedGST = gstValues[rowIndex];
    const selectedTDS = tdsValues[colIndex];
    const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

    if (newCellToggles[rowIndex][colIndex]) {
      // If the cell is toggled (checked), add the combination value to tdsArr
      const newTdsArr = [...tdsArr, { value: combination }];
      setTdsArr(newTdsArr);
    } else {
      // If the cell is unselected (unchecked), remove the corresponding combination value from tdsArr
      const newTdsArr = tdsArr.filter((item) => Number(item.value) !== Number(combination));
      setTdsArr(newTdsArr);
    }

    setCellToggles(newCellToggles);
  };

  const handleCheckboxChange = () => {
    setShowInput(!showInput);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (e.target.value.trim().length < 1) return;
    const newValue = e.target.value.split(",");
    const outputValues: number[] = [];
    let inpError = false;

    newValue.forEach((value) => {
      const floatVal = parseFloat(value);
      if (floatVal >= 0 && floatVal <= 100) {
        outputValues.push(floatVal);
      } else inpError = true;
    });

    setInputError(inpError);
    if (!inpError) setInputValue(outputValues.join(", "));
  };
  const handleAddTag = () => {
    let uniqArr: { value: string }[] = [...tdsArr];

    inputValue.split(" ").forEach((val) => {
      if (val.trim() !== "") {
        const numericValue = parseFloat(val);
        if (numericValue >= 0 && numericValue <= 100) {
          const stringValue = numericValue.toString(); // Convert to a fixed-format string
          uniqArr = removeDuplicates([...uniqArr, { value: stringValue }]);

          // Check if stringValue exists in allCombinations
          const existsInCombinations = allCombinations.some(
            (combination) => Number(combination.combination) === Number(stringValue)
          );

          // Check if stringValue already exists in tdsArr
          const existsInTdsArr = tdsArr.some((tds) => Number(tds.value) === Number(stringValue));
          if (existsInTdsArr) {
            toast.warning(<CustomToast message="TDS % already selected" />);
          } else if (existsInCombinations) {
            toast.success(<CustomToast message="TDS % Value present in existing combinations selected" />);
          }
          // setShowInput(false);
        } else {
          alert("Please enter a number between 0 and 100.");
        }
      }
    });
    setTdsArr(Array.from(uniqArr));
    setInputValue("");
    // Update cellToggles after adding the tag
    updateCellToggles(uniqArr);
  };
  const handleDeleteTag = (value) => {
    const updatedTdsArr = tdsArr.filter((tds) => tds.value !== value);
    const deduplicatedArr = removeDuplicates(updatedTdsArr);
    setTdsArr(deduplicatedArr);
    updateCellToggles(deduplicatedArr);
  };

  const removeDuplicates = (arr: { value: string }[]) => {
    const uniqueValuesMap = new Map();
    const uniqueValuesArray: { value: string }[] = [];

    arr.forEach((tag) => {
      const numericValue = parseFloat(tag.value);

      if (!isNaN(numericValue)) {
        const stringValue = numericValue.toFixed(5).toString();

        if (!uniqueValuesMap.has(stringValue)) {
          uniqueValuesMap.set(stringValue, true);
          uniqueValuesArray.push(tag);
        }
      }
    });

    return uniqueValuesArray;
  };

  // Function to update cellToggles based on tdsArr and combination
  const updateCellToggles = (arr) => {
    const rows = gstValues.length;
    const cols = tdsValues.length;

    const updatedCellToggles = Array.from({ length: rows }, (_, rowIndex) =>
      Array.from({ length: cols }, (__, colIndex) => {
        const selectedGST = gstValues[rowIndex];
        const selectedTDS = tdsValues[colIndex];
        const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

        // Check if the combination value exists in tdsArr

        return arr.some((item) => Number(item.value) === Number(combination));
      })
    );

    setCellToggles(updatedCellToggles);
  };

  const getBusinessPartnerDetails = () => {
    useFetch("/api/GetBusinessPartnerDetails", "GET", {
      config: {
        params: {
          businessPartnerId: props.businessPartnerId,
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        setSelectedAccountingSoftware(response.data.businessPartnerInfo?.erpSoftware);
        setSelectedRelationship(response.data.businessPartnerInfo?.relationship);
        setCompanyName(response.data.businessPartnerInfo?.partnerName);
        setPartnerNickname(response.data.businessPartnerInfo?.bpCommonName);
        setCompanyGstNumber(response.data.businessPartnerInfo?.gstin);
        setTdsArr(getUniqueList(response.data.businessPartnerInfo?.applicableTDS || []));
        updateCellToggles(removeDuplicates(response.data.businessPartnerInfo?.applicableTDS || []));
        setPinCode(response.data.businessPartnerInfo?.pincode?.toString());
        setVendorCode(response.data.businessPartnerInfo?.vendorCode?.toString());
        setLocation(response.data.businessPartnerInfo?.location);
        setCurrency(response.data.businessPartnerInfo?.currency || DefaultCurrency.INR);

        setLoader(false);
      },
      catchCallBack: () => {
        setLoader(false);
      },
    });
  };

  const getLedgerConfigList = async () => {
    await useFetch("/api/ListAllLedgerConfigTemplate", "GET", {
      config: {
        params: {
          templateType: ConfigTemplateType.ERP,
        },
      },
      thenCallBack: (response) => {
        setConfigList(response.data.ledgerConfigTemplates);
      },
    });
  };

  const handleClose = () => {
    props.setOpenBusinessPopup(false);
    if (props?.consumerComponentWhichSide) {
      props?.consumerComponentWhichSide === ledgerUploadName.ownSide
        ? window.clarity("event", uiLoggerNamesRecon.UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_CANCEL_CLICK.functionName)
        : window.clarity("event", uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_CANCEL_CLICK.functionName);
    }
  };

  const getUniqueList = (originalList: { value: string }[]) => {
    const replacedList = originalList?.map((obj) => ({
      value: parseFloat(obj?.value) === 0 || obj?.value === "" ? 0.1 : parseFloat(obj?.value),
    }));

    const uniqueList = replacedList?.filter(
      (obj, index) => replacedList?.findIndex((o) => o.value === obj.value) === index
    );

    return uniqueList;
  };

  const updateBusinessPartnerDetails = async () => {
    let noErrorPresent = true;

    if (selectedRelationship === undefined) {
      setErrSelectedRelationship(true);
    }
    if (companyName.trim() === "") {
      setErrCompanyName(true);
    }
    if (partnerNickname.trim().length > 16) {
      noErrorPresent = false;
    }
    if (
      companyGstNumber?.trim()?.length > 0 &&
      !companyGstNumber.match("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")
    ) {
      setErrCompanyGstNumber(true);
      noErrorPresent = false;
    }
    if (pinCode?.toString()?.trim()?.length > 0 && !pinCode?.toString()?.match("^[1-9][0-9]{5}$")) {
      setErrPinCode(true);
      noErrorPresent = false;
    }

    if (selectedRelationship !== undefined && companyName.trim() !== "" && noErrorPresent) {
      const tdsArrFilt = getUniqueList(tdsArr)?.filter((item: any) => {
        return item.value.toString().trim() !== "";
      });

      const convertapplicableTDSPercentagesToString = tdsArrFilt.map((item: any) => {
        return `${item.value}%`;
      });
      setShowLoadingIconUpdate(true);
      await useFetch("/api/PartnerMaster/UpdateBusinessPartnerDetails", "POST", {
        showSuccessToast: true,
        data: {
          businessPartnerId: props.businessPartnerId,
          relationship: selectedRelationship,
          partnerName: companyName,
          bpCommonName: partnerNickname,
          vendorCode,
          gstin: companyGstNumber,
          pincode: pinCode,
          applicableTDS: convertapplicableTDSPercentagesToString.toString(),
          erpSoftware: selectedAccountingSoftware,
          location,
          currency,
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: () => {
          const targetObject = props.storePartnerUseCaseMasterData.current.find(
            (v) => v.businessPartnerId === props.businessPartnerId
          );

          if (targetObject) {
            targetObject.applicableTds = getUniqueList(tdsArr);
            targetObject.relationship = selectedRelationship;
            targetObject.erpSoftware = selectedAccountingSoftware;
          }
          setShowLoadingIconUpdate(false);
          resetState();
          handleClose();
        },
        catchCallBack: () => {
          setShowLoadingIconUpdate(false);
        },
      });
    }
  };

  const updateBpTDS = async () => {
    if (props?.consumerComponentWhichSide) {
      props?.consumerComponentWhichSide === ledgerUploadName.ownSide
        ? window.clarity("event", uiLoggerNamesRecon.UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_UPDATE_CLICK.functionName)
        : window.clarity("event", uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_UPDATE_CLICK.functionName);

      uiLogger(
        props?.consumerComponentWhichSide === ledgerUploadName.ownSide
          ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_UPDATE_CLICK.functionName
          : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_UPDATE_CLICK.functionName,
        props.companyId,
        props.branchCode,
        {
          message:
            props?.consumerComponentWhichSide === ledgerUploadName.ownSide
              ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_UPDATE_CLICK.message
              : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_UPDATE_CLICK.message,
          businessPartnerId: props.businessPartnerId,
        }
      );
    }

    const tdsArrFilter = getUniqueList(tdsArr)?.filter((item) => item.value.toString().trim() !== "");

    const convertApplicableTDSPercentagesToString = tdsArrFilter.map((item) => `${item.value}%`);
    setShowLoadingIconUpdate(true);
    useFetch("/api/PartnerMaster/UpdateBusinessPartnerDetails", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: props.businessPartnerId,
        partnerName: companyName,
        applicableTDS: convertApplicableTDSPercentagesToString.toString(),
        currency,
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        setShowLoadingIconUpdate(false);
        resetState();
        handleClose();
      },
      catchCallBack: () => {
        setShowLoadingIconUpdate(false);
      },
    });
  };

  const resetState = () => {
    setSelectedRelationship(null);
    setSelectedAccountingSoftware(null);
    setCompanyName("");
    setPartnerNickname("");
    setCompanyGstNumber("");
    setVendorCode("");
    setLocation("");
    setTdsArr(initialTdsArr);
    setPinCode("");
    setErrSelectedRelationship(false);
    setErrCompanyName(false);
    setErrorPartnerNickname(false);
    setErrCompanyGstNumber(false);
    setErrPinCode(false);
    setInputValue("");
  };

  // const SortedCurrencies = Object.keys(PopularCurrencies).map((k) => Currencies.find((v) => v.code === k));
  // SortedCurrencies.push(...Currencies);

  return (
    <>
      <BusinessDialog
        fullWidth={true}
        maxWidth="md"
        open={props.openBusinessPopup}
        onClose={handleClose}
        aria-labelledby="add-business-partner"
        className="businesspartner_popup"
      >
        <DialogTitle>
          {props.consumerComponent === "Recon360" ? " Update TDS/TCS Details" : "Update Partner Details"}
        </DialogTitle>
        {loader ? (
          <Grid className="center_align_ver_horiz mb_80">
            <Loader />
          </Grid>
        ) : (
          <DialogContent>
            <Box sx={{ width: "100%" }}>
              <Grid container={true} spacing={2} component={"div"} hidden={props.consumerComponent === "Recon360"}>
                <Grid item={true} xs={12} sm={12}>
                  <FormControl fullWidth={true} error={errSelectedRelationship}>
                    <Select
                      name="relationship"
                      variant="outlined"
                      required={true}
                      fullWidth={true}
                      displayEmpty={true}
                      error={errSelectedRelationship}
                      onChange={(e) => {
                        setSelectedRelationship(e.target.value);
                        setErrSelectedRelationship(false);
                      }}
                      value={selectedRelationship}
                      renderValue={() => (
                        <span style={{ textTransform: "capitalize" }}>
                          {selectedRelationship === "" ? "Select User Type" : selectedRelationship}
                        </span>
                      )}
                    >
                      <MenuItem disabled={true}>Select User Type</MenuItem>
                      {options.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          selected={selectedRelationship === item.value ? true : false}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errSelectedRelationship ? "please select user type" : ""}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    disabled={true}
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                    label="Partner Name"
                    name="companyName"
                    error={errCompanyName}
                    helperText={errCompanyName ? "please enter partner name" : ""}
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      if (e.target.value.trim() !== "") {
                        setErrCompanyName(false);
                      } else {
                        setErrCompanyName(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required={false}
                    fullWidth={true}
                    label="Partner Common Name"
                    name="partnerNickname"
                    error={errorPartnerNickname}
                    helperText={errorPartnerNickname ? "Maximum 16 chars allowed" : ""}
                    value={partnerNickname}
                    onChange={(e) => {
                      setPartnerNickname(e.target.value);
                      if (e.target.value.trim().length <= 16) {
                        setErrorPartnerNickname(false);
                      } else {
                        setErrorPartnerNickname(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    // required={true}
                    name="companyGstNumber"
                    label="GSTIN"
                    type="text"
                    error={errCompanyGstNumber}
                    helperText={errCompanyGstNumber ? "please enter correct company GST number" : ""}
                    value={companyGstNumber}
                    onChange={(e) => {
                      setCompanyGstNumber(e.target.value);
                      if (e.target.value.length > 0) {
                        if (!e.target.value.match("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")) {
                          setErrCompanyGstNumber(true);
                        } else {
                          setErrCompanyGstNumber(false);
                        }
                      } else {
                        setErrCompanyGstNumber(false);
                      }
                    }}
                  />
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    disabled={true}
                    variant="outlined"
                    fullWidth={true}
                    name="vendorCode"
                    label="Vendor Code"
                    type="text"
                    value={vendorCode}
                    onChange={(e) => {
                      setVendorCode(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    disabled={true}
                    variant="outlined"
                    fullWidth={true}
                    name="location"
                    label="Location"
                    type="text"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <Select
                    name="accountingSoftware"
                    variant="outlined"
                    fullWidth={true}
                    displayEmpty={true}
                    onChange={(e) => {
                      setSelectedAccountingSoftware(e.target.value);
                    }}
                    value={selectedAccountingSoftware}
                    renderValue={() => (
                      <span>
                        {selectedAccountingSoftware === "" ? "Select Accouting software" : selectedAccountingSoftware}
                      </span>
                    )}
                  >
                    <MenuItem selected={true} disabled={true} value="">
                      Select Accouting software
                    </MenuItem>
                    {configList.map((item) => (
                      <MenuItem key={item.templateId} value={item.templateName}>
                        {item.templateName}
                      </MenuItem>
                    ))}
                    <MenuItem key="other" value="Others" className="dropdown_other_highlight">
                      Other
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    // required={true}
                    name="PINCode"
                    label="PIN Code"
                    type="text"
                    error={errPinCode}
                    helperText={errPinCode ? "please enter correct PIN Code" : ""}
                    value={pinCode}
                    onChange={(e) => {
                      setPinCode(e.target.value);
                      if (e.target.value.length > 0) {
                        if (!e.target.value.match("^[1-9][0-9]{5}$")) {
                          setErrPinCode(true);
                        } else {
                          setErrPinCode(false);
                        }
                      } else {
                        setErrPinCode(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    select={true}
                    variant="outlined"
                    fullWidth={true}
                    name="Currency"
                    label="Currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    {Currencies.map((_cur) => (
                      <MenuItem key={_cur.code} value={_cur.code}>
                        {_cur.code} - {_cur.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} sm={12}>
                  <div>
                    <label>TDS/TCS Percentage</label>
                  </div>
                  <table className="tds_gst_table mt_10">
                    <thead>
                      <tr>
                        <th className="text_center" colSpan={tdsValues.length + 2}>
                          TDS
                        </th>
                      </tr>
                      <tr>
                        <th />
                        <th />
                        {tdsValues.map((tds, index) => (
                          <th key={index} className="text_center">
                            {tds}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {gstValues.map((gst, rowIndex) => (
                        <tr key={rowIndex}>
                          {rowIndex === 0 ? (
                            <th
                              rowSpan={gstValues.length}
                              className="text_center"
                              style={{
                                verticalAlign: "middle",
                                borderTop: "1px solid #53526c",
                                borderRight: "1px solid #53526c",
                                borderBottom: "none",
                                borderBottomLeftRadius: 12,
                              }}
                            >
                              GST
                            </th>
                          ) : null}
                          <th className="text_center" style={{ borderBottomLeftRadius: 0 }}>
                            {gst}
                          </th>
                          {tdsValues.map((tds, colIndex) => {
                            const selectedGST = gstValues[rowIndex];
                            const selectedTDS = tdsValues[colIndex];
                            const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

                            return (
                              <td
                                key={`${colIndex}-${tds}`}
                                className={
                                  cellToggles[rowIndex][colIndex]
                                    ? "toggled text_center cursor_pointer"
                                    : "text_center cursor_pointer"
                                }
                                onClick={() => toggleCell(rowIndex, colIndex)}
                              >
                                {cellToggles[rowIndex][colIndex] ? (
                                  <div className="check" title={`${combination}%`} style={{ width: "30px" }} />
                                ) : (
                                  <div
                                    className="check unchecked"
                                    title={`${combination}%`}
                                    style={{ width: "30px" }}
                                  />
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
                <Grid item={true} xs={12} sm={12}>
                  <div className="mt_20">
                    <FormControlLabel
                      control={<Checkbox checked={showInput} onChange={handleCheckboxChange} />}
                      label="Add TDS % Manually (if it doesn't fit in the table)"
                      sx={{ "& > span": { fontWeight: 600 } }}
                    />
                    {/* <Checkbox onChange={handleCheckboxChange} /> */}
                    {/* <label className="fw_600">Add TDS % Manually (if it doesn't fit in the table)</label> */}
                  </div>
                  {showInput && (
                    <div className="d_flex gap_10" style={{ alignItems: "flex-start" }}>
                      <TextField
                        error={inputError}
                        variant="outlined"
                        style={{ minWidth: "50%" }}
                        label="TDS/TCS Percentage"
                        name="value"
                        value={inputValue}
                        type="number"
                        multiline={true}
                        maxRows={4}
                        onChange={(e) =>
                          setInputValue(
                            e.target.value
                              .replace(/[^0-9.,\s]*/g, "") // non digits, dots or spaces
                              .replace(/(\.){2,}/g, ".") // decimal points
                          )
                        }
                        onBlur={handleInputBlur}
                        placeholder="Between 0 - 100, comma separated values"
                        helperText={inputError ? "Please Enter Correct Values." : ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Typography mx={1}>%</Typography>
                              <IconButton
                                edge="end"
                                title="Paste from clipboard"
                                onClick={async () => {
                                  const str = await navigator?.clipboard?.readText();
                                  setInputValue(str.replace(/[^0-9.\s]*/g, ""));
                                }}
                              >
                                <ContentPaste />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <IconButton disabled={inputError} color="primary" onClick={handleAddTag} sx={{ top: 8 }}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  )}
                  <div className="tag-container mt_20">
                    {tdsArr
                      .filter(
                        (tds) =>
                          !allCombinations.some((combination) => Number(combination.combination) === Number(tds.value))
                      )
                      .map((tds, index) => (
                        <Chip
                          key={index}
                          label={tds.value}
                          onDelete={() => handleDeleteTag(tds.value)}
                          color="primary"
                          variant="outlined"
                          style={{ marginRight: "8px", marginBottom: "8px" }}
                        />
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        )}
        {!loader && (
          <DialogActions className="businessmodal_footer">
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => setOpenDeleteBp(true)}
              className="businesspartner_btn theme_btn_no_bg"
              sx={{ mr: "auto" }}
              hidden={props.consumerComponent === "Recon360" || actor.integration}
            >
              Delete Partner
            </Button>
            <Button onClick={handleClose} className="businesspartner_btn theme_outline_btn cursor_pointer">
              Cancel
            </Button>
            <Button
              disabled={showLoadingIconUpdate}
              size="small"
              onClick={() => (props?.consumerComponent === "Recon360" ? updateBpTDS() : updateBusinessPartnerDetails())}
              className="businesspartner_btn theme_btn center_align_ver_horiz"
            >
              <LoadingIcon loading={showLoadingIconUpdate} />
              Update
            </Button>
          </DialogActions>
        )}
        <DeletePartnerDialog
          open={openDeleteBp}
          setOpen={setOpenDeleteBp}
          businessPartnerId={props.businessPartnerId}
          businessPartnerName={companyName}
          companyId={props.companyId}
          branchCode={props.branchCode}
          afterDelete={props.afterDelete}
        />
      </BusinessDialog>
    </>
  );
};

interface DeletePartnerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  businessPartnerId: number;
  businessPartnerName: string;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  afterDelete: () => void;
}

export const DeletePartnerDialog = (props: DeletePartnerProps) => {
  const [checkBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const DeletePartner = () => {
    setLoading(true);
    useFetch("/api/PartnerMaster/DeleteBusinessPartner", "DELETE", {
      showSuccessToast: true,
      config: {
        data: {
          partnerIds: [props.businessPartnerId],
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: () => {
        setLoading(false);
        props.setOpen(false);
        if (props.afterDelete) props.afterDelete();
      },
      catchCallBack: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Are you sure ?</DialogTitle>
        <IconButton
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <FormLabel>
          The Following Business Partner Will be Deleted:{" "}
          <Typography component={"span"} color={"InfoText"}>
            {props.businessPartnerName}
          </Typography>
        </FormLabel>
        <br />
        <FormLabel>Once the partner is deleted it will not be recovered.</FormLabel>
        <FormLabel sx={{ px: 2 }}>
          <ul style={{ listStyle: "initial" }}>
            <li>Partner configuration will be deleted.</li>
            <li>All partner users will get deleted.</li>
          </ul>
        </FormLabel>
        {/* <Box className="vertical_center_align" sx={{ gap: 1, mt: 2 }}>
          <IndeterminateCheckbox checked={checkBox} onChange={(e) => setCheckBox(e.target.checked)} />
          <Typography component={"label"} onClick={() => setCheckBox(!checkBox)} sx={{cursor: "pointer"}}>
            I have Read and Understood.
          </Typography>
        </Box> */}
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button
          variant="contained"
          className="theme_outline_btn"
          autoFocus={true}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="theme_btn_no_bg"
          color="error"
          disabled={checkBox}
          onClick={() => {
            DeletePartner();
          }}
          startIcon={loading ? <LoadingIcon loading={loading} /> : <Delete />}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBusinessPartner;
