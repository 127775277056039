import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { userContext } from "src/Components/Contexts/userContext";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";

export interface GetExistingTicketResponse {
  count: number;
  businessPartnerIds: number[];
}
interface ReviewTaskContentCardProps {
  title: string;
  subTitle: string;
  description: string;
  severity: "info" | "error" | "warning" | "success";
  chipText: string;
  chipColor: "error" | "warning" | "info" | "success" | "primary" | "secondary";
  showActionButton?: boolean;
  onActionButtonClick?: () => void;
}

const ReviewTaskContentCard = ({
  title,
  subTitle,
  description,
  severity,
  chipText,
  chipColor,
  showActionButton = false,
  onActionButtonClick,
}: ReviewTaskContentCardProps) => {
  return (
    <Box sx={{ border: "1px solid #E0E0E0", borderRadius: "4px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 16px",
        }}
      >
        <Box>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.60)", fontSize: 14 }}>
            {subTitle}
          </Typography>
        </Box>
        <Chip label={chipText} color={chipColor} sx={{ "& .MuiChip-label": { fontSize: 13 } }} />
      </Box>
      <Alert
        severity={severity}
        sx={{ "& .MuiAlert-message": { fontSize: "14px" } }}
        action={
          showActionButton && (
            <Button color="inherit" size="small" onClick={onActionButtonClick}>
              View Tasks
            </Button>
          )
        }
      >
        {description}
      </Alert>
    </Box>
  );
};

export default function ReviewMoveBulkTasksModal({
  open,
  setOpen,
  selectedTasks,
  setShowMoveTaskToReconModal,
  setMoveToReconTaskDetails,
  setTasksToMoveCount,
  businessPartnerDetailsForWhichTaskAlreadyExists,
  isLoadingAlreadyExistingTasks,
  getAlreadyExistingTasks,
  companyId,
  branchCode,
}) {
  const { actor } = useContext(userContext);

  let moveToReconTasksCount = 0;
  let ledgerReceivedButNotApprovedCount = 0;
  let approvedAndTaskAlreadyExistsCount = 0;
  let notApprovedAndTaskAlreadyExistsCount = 0;
  let balanceConfirmedCount = 0;
  let responsePendingCount = 0;
  let mailNotSentCount = 0;
  const tasksToMoveToRecon = [];
  const alreadyExistingTaskIds = [];

  selectedTasks.forEach((task) => {
    if (task.statusOverview === "Not Confirmed" || task.statusOverview === "Ledger Submitted") {
      const existingTaskDetails = businessPartnerDetailsForWhichTaskAlreadyExists.find(
        (obj) => obj.businessPartnerId === task.businessPartnerId
      );
      if (existingTaskDetails) {
        if (task.isApproved) approvedAndTaskAlreadyExistsCount++;
        else notApprovedAndTaskAlreadyExistsCount++;
        alreadyExistingTaskIds.push(existingTaskDetails.ticketId);
      } else {
        if (task.isApproved) {
          moveToReconTasksCount++;
          tasksToMoveToRecon.push({ ticketId: task.ticketId, businessPartnerId: task.businessPartnerId });
        } else ledgerReceivedButNotApprovedCount++;
      }
    }
    if (task.statusOverview === "Confirmed") balanceConfirmedCount++;
    if (task.statusOverview === "Pending") responsePendingCount++;
    if (task.statusOverview === "-") mailNotSentCount++;
  });

  useEffect(() => {
    getAlreadyExistingTasks(true);
  }, []);

  const handleCopyTaskIdAndRedirect = () => {
    uiLogger(
      uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKMOVETASKTORECON_VIEWTASKS_CLICK.functionName,
      companyId,
      branchCode,
      {
        message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKMOVETASKTORECON_VIEWTASKS_CLICK.message,
      }
    );
    window.localStorage.setItem("bpIdsForWhichTasksAlreadyExists", JSON.stringify(alreadyExistingTaskIds));
    const uri = `/${actor.name}/recon360/Summary/Ledger`;
    window.open(uri, "_blank");
  };

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "868px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
        <Typography variant="h6">Review Tasks</Typography>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Stack sx={{ padding: 2, gap: 2, height: "1100px", overflow: "auto" }}>
        {isLoadingAlreadyExistingTasks ? (
          <>
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "200px" }} />
            <Skeleton variant="rounded" width="100%" height={120} />
            <Skeleton variant="rounded" width="100%" height={120} />
            <Skeleton variant="rounded" width="100%" height={120} />
            <Skeleton variant="rounded" width="100%" height={120} />
            <Skeleton variant="rounded" width="100%" height={120} />
          </>
        ) : (
          <>
            <Typography variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {`${moveToReconTasksCount} Tasks to be moved to Recon`}
            </Typography>
            {moveToReconTasksCount > 0 && (
              <ReviewTaskContentCard
                title="Ledger received and Response approved"
                subTitle={`${moveToReconTasksCount} out of ${selectedTasks.length || 0} selected Tasks`}
                chipText="Tasks will move to recon"
                chipColor="primary"
                description="Response is received and approved"
                severity="info"
              />
            )}
            {ledgerReceivedButNotApprovedCount > 0 && (
              <ReviewTaskContentCard
                title="Ledger received but response is not approved"
                subTitle={`${ledgerReceivedButNotApprovedCount} out of ${selectedTasks.length} selected Tasks`}
                chipText="Tasks will not move to recon"
                chipColor="error"
                description="Approve these responses to move tasks to recon"
                severity="error"
              />
            )}
            {approvedAndTaskAlreadyExistsCount + notApprovedAndTaskAlreadyExistsCount > 0 && (
              <ReviewTaskContentCard
                title="Ledger received but task already exists for Partner(s)"
                subTitle={`${approvedAndTaskAlreadyExistsCount + notApprovedAndTaskAlreadyExistsCount} out of ${
                  selectedTasks.length
                } selected Tasks | ${approvedAndTaskAlreadyExistsCount} approved and ${notApprovedAndTaskAlreadyExistsCount} not approved`}
                chipText="Tasks will not move to recon"
                chipColor="error"
                description="Click ‘View Tasks’ button to view only tasks that already exists for partners. "
                severity="error"
                showActionButton={true}
                onActionButtonClick={handleCopyTaskIdAndRedirect}
              />
            )}
            {balanceConfirmedCount > 0 && (
              <ReviewTaskContentCard
                title="Balance confirmed by Partner(s)"
                subTitle={`${balanceConfirmedCount} out of ${selectedTasks.length} selected Tasks`}
                chipText="Tasks will not move to recon"
                chipColor="error"
                description="Balance is confirmed by the partner"
                severity="error"
              />
            )}
            {responsePendingCount > 0 && (
              <ReviewTaskContentCard
                title="Response not Received from Partner(s)"
                subTitle={`${responsePendingCount} out of ${selectedTasks.length} selected Tasks`}
                chipText="Tasks will not move to recon"
                chipColor="error"
                description="Partner reply is pending"
                severity="error"
              />
            )}
            {mailNotSentCount > 0 && (
              <ReviewTaskContentCard
                title="Mail not sent to Partner(s)"
                subTitle={`${mailNotSentCount} out of ${selectedTasks.length} selected Tasks`}
                chipText="Tasks will not move to recon"
                chipColor="error"
                description="No mail sent to these partners"
                severity="error"
              />
            )}
          </>
        )}
      </Stack>

      <DialogActions sx={{ padding: 2, background: "#F1F1F1" }}>
        <Button
          disabled={moveToReconTasksCount === 0}
          variant="contained"
          sx={{
            background: "#27B27C",
            padding: "8px 22px",
            color: "#FFF",
            borderRadius: "4px !important",
            fontWeight: 500,
            fontSize: "15px",
            "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
          }}
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKMOVETASKTORECON_CONTINUE_CLICK.functionName,
              companyId,
              branchCode,
              {
                message:
                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKMOVETASKTORECON_CONTINUE_CLICK.message,
              }
            );
            setOpen(false);
            setMoveToReconTaskDetails(tasksToMoveToRecon);
            setShowMoveTaskToReconModal(true);
            setTasksToMoveCount(moveToReconTasksCount);
          }}
        >
          CONTINUE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
