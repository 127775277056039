import { Alert, Button, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { Dialog } from "src/Components/Dialog/Dialog";
import { DocumentTypeConfigContext } from "./DocumentTypeConfig";

const DeleteDocTypeSubGrp = () => {
  const {
    isOpenDeleteDocTypeSubGrp,
    setIsOpenDeleteDocTypeSubGrp,
    editGroupEle,
    documentTypeRow,
    setDocumentTypeRow,
    groupsListRef,
  } = useContext(DocumentTypeConfigContext);
  const [showDeleteLoadingIcon, setShowDeleteLoadingIcon] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteDocTypeSubGrp(false);
  };
  const handleDelete = () => {
    setShowDeleteLoadingIcon(true);
    // Use the filter method to remove the editGroupEle object from documentTypeRow
    const updatedDocumentTypeRow = documentTypeRow.filter((item) => {
      return item.name !== editGroupEle.name || item.group?.groupId !== editGroupEle.group?.groupId;
    });
    setDocumentTypeRow(updatedDocumentTypeRow);
    // Filter out the object that matches the criteria
    groupsListRef.current = groupsListRef.current.filter(
      (group) => !(group.docType === editGroupEle.name && group.groupDetails.groupId === editGroupEle.group.groupId)
    );

    setShowDeleteLoadingIcon(false);
    handleCloseDeleteModal();
  };
  return (
    <div>
      <Dialog open={isOpenDeleteDocTypeSubGrp} onClose={() => setIsOpenDeleteDocTypeSubGrp(true)}>
        <header className="modal-card-head">
          <p className="modal-card-title">Are you sure</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              handleCloseDeleteModal();
            }}
          />
        </header>
        <section className="modal-card-body">
          <Grid xs={12} md={12}>
            <div>
              <Alert severity="warning">
                Deleting this group would lead to the deletion of the corresponding groups from all partners. Anything
                mapped in the group would be dropped.
              </Alert>
            </div>
          </Grid>
        </section>
        <footer className="modal-card-foot right_align">
          <div>
            <Button onClick={handleCloseDeleteModal} className="mr_10">
              Cancel
            </Button>
            <Button
              variant="contained"
              className="theme_btn theme_btn_warning"
              onClick={handleDelete}
              sx={{ marginRight: 1 }}
            >
              <LoadingIcon loading={showDeleteLoadingIcon} />
              Delete
            </Button>
          </div>
        </footer>
      </Dialog>
    </div>
  );
};

export default DeleteDocTypeSubGrp;
