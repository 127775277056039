import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import QRCode from "qrcode";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
// import Nakad from "../../Graphics/Nakad_Blue.png";
// import Nakad from "../../Graphics/nakad-logo.png";
import Nakad from "../../Graphics/NAKAD_FINAL (1).png";
import { userContext } from "../Contexts/userContext";
import Register from "../Register/Register";
import illustration from "./../../Graphics/illustration.svg";

import Grid from "@mui/material/Grid";

import { ArrowBack } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  createFilterOptions,
  FormControl,
  TextField,
} from "@mui/material";
import { clarity } from "react-microsoft-clarity";
import { ActorTypes, FrontendActor } from "src/entity/models/FrontendActor";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { ENV_NAME } from "src/Utils/Recon/ENV/Constants";
import { uiLoggerNamesRecon } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { ZapScaleEventsConstants } from "src/Utils/ZapscaleEvents/Contsants";
import LoadingIcon from "../Common/LoadingIcon";
import useFetch from "../Common/useFetch";
import CreatePasswordModal from "./CreatePasswordModal";
import "./Login.scss";
// Declare a module augmentation to extend the Window interface
declare global {
  interface Window {
    zapscale: {
      trackEvent(eventName: string): void;
      // Add any other properties or methods here if needed
    };
  }
}

const eye = <FontAwesomeIcon icon={faEye as IconProp} />;

const Login = () => {
  const { actor, setActor } = useContext(userContext);
  const history = useHistory();
  const location = useLocation();
  const [form, setForm] = useState({
    companyid: "",
    username: "",
    password: "",
    actorId: 0,
  });
  const [credInput, setCredInput] = useState(false);
  const [showAccountSelect, setShowAccountSelect] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const disableSignUpBtnForNow = true;
  const [showMFA, setShowMFA] = useState(false);
  const [mfaQrReg, setMfaQrReg] = useState(false);
  const mfaForUserId = useRef<FrontendActor["userId"]>(null);
  const [qrImage, setQrImage] = useState("");
  const [otp, setOTP] = useState("");
  const [otpVerified, setOtpVerified] = useState<boolean>(null);
  const otpBoxRef = useRef<HTMLInputElement>(null);
  const [currentSelectedAccountId, setCurrentSelectedAccountId] = useState(null);
  const [isLoggingInLoading, setIsLoggingInLoading] = useState(false);
  const [isCollectingLoginCredsLoading, setIsCollectingLoginCredsLoading] = useState(false);
  const [showCreatePasswordModal, setShowCreatePasswordModal] = useState<boolean>(false);

  const update = (event: React.ChangeEvent<HTMLInputElement>) =>
    setForm({ ...form, [event.target.name]: event.target.value });

  const collectLoginCreds = async () => {
    setIsCollectingLoginCredsLoading(true);
    await useFetch(API_ENDPOINTS.FETCH_USER_EMAIL_ACCOUNTS.url, "POST", {
      failureMessage: API_ENDPOINTS.FETCH_USER_EMAIL_ACCOUNTS.failureMessage,
      data: { username: form.username },
      thenCallBack: (response) => {
        setIsCollectingLoginCredsLoading(false);

        setForm({ ...form, actorId: response.data.accounts[0].id });
        setCompanyList(response.data.accounts);
        if (response.data.accounts.length === 1) {
          loginUser({ preventDefault: () => {} }, response.data.accounts[0].id);
        } else {
          setCredInput(true);
          setShowAccountSelect(true);
        }
      },
      catchCallBack: () => {
        setIsCollectingLoginCredsLoading(false);
        document.getElementById("flash-msg").style.display = "block";
        document.getElementById("msg").innerHTML = "Could not fetch Accounts. Please Try Again";
      },
    });
  };
  useEffect(() => {
    if (location.state !== undefined && (location.state as any).alert === "true") {
      document.getElementById("flash-msg").style.display = "block";
      document.getElementById("msg").innerHTML = "Please Login to access Action page";
    }
  }, [location]);

  useEffect(() => {
    const promise = axios.get<FrontendActor>("/loginCheck").then((response) => {
      if (response.status === 200) {
        clarity.setTag("RU", `${response.data.actorInfo.name}-${response.data.actorId}`);
        clarity.setTag("User Email", `${response.data?.userEmail}`);
        setActor({
          name: response.data.actorInfo.name as string,
          id: response.data.actorId,
          acceptedTNC: response.data.acceptedTNC,
          actorType: response.data.actorType,
          integration: response.data.integration,
          userId: response.data.userId,
          actorInfo: response.data.actorInfo,
          replyToEmail: response.data.replyToEmail,
          userType: response.data.userType,
          dynamicContents: response.data.dynamicContents,
          branchLevelReconcilation: response.data.branchLevelReconcilation,
          mfaEnabled: response.data.mfaEnabled,
          mfaVerified: response.data.mfaVerified,
          actorName: response.data?.actorName,
          userName: response.data?.userName,
          userEmail: response.data?.userEmail,
          workflowFeatureFlag: response.data?.workflowFeatureFlag,
          zsMonitorAccount: response.data?.zsMonitorAccount,
          clientId: response.data?.clientId,
          crmId: response.data?.crmId,
          clientType: response.data?.clientType,
          accountType: response.data?.accountType,
          userRole: response.data?.userRole,
          userAgeInDays: response.data?.userAgeInDays,
          actorAgeInDays: response.data?.actorAgeInDays,
        });
        if (response.data.mfaEnabled) {
          setShowMFA(true);
          setMfaQrReg(response.data.mfaVerified);
          mfaForUserId.current = response.data.userId;

          if (response.data?.mfaVerified === false) {
            getQRCode();
          }
          return;
        }
        if (!response.data.isFirstLogin) {
          redirect(response.data, history, response.data.actorInfo.name);
        }
      }
    });

    promise.catch(() => {});
    // eslint-disable-next-line
  }, []);

  const loginUser = async (event: any, actorId?: number) => {
    setIsLoggingInLoading(true);
    event.preventDefault();
    for (let RETRY_COUNT = 3; RETRY_COUNT > 0; RETRY_COUNT--) {
      await useFetch<FrontendActor>(API_ENDPOINTS.LOGIN.url, "POST", {
        failureMessage: API_ENDPOINTS.LOGIN.failureMessage,
        data: { username: form.username, password: form.password, actorId: actorId ? actorId : form.actorId },
        thenCallBack: (response) => {
          setActor({
            name: response.data.actorInfo.name,
            id: response.data.actorId,
            acceptedTNC: response.data.acceptedTNC,
            actorType: response.data.actorType,
            integration: response.data.integration,
            userId: response.data.userId,
            actorInfo: response.data.actorInfo,
            replyToEmail: response.data.replyToEmail,
            userType: response.data.userType,
            dynamicContents: response.data.dynamicContents,
            branchLevelReconcilation: response.data.branchLevelReconcilation,
            mfaEnabled: response.data.mfaEnabled,
            mfaVerified: response.data.mfaVerified,
            actorName: response.data?.actorName,
            userName: response.data?.userName,
            userEmail: response.data?.userEmail,
            workflowFeatureFlag: response.data?.workflowFeatureFlag,
            zsMonitorAccount: response.data?.zsMonitorAccount,
            clientId: response.data?.clientId,
            crmId: response.data?.crmId,
            clientType: response.data?.clientType,
            accountType: response.data?.accountType,
            userRole: response.data?.userRole,
            userAgeInDays: response.data?.userAgeInDays,
            actorAgeInDays: response.data?.actorAgeInDays,
          });
          if (response.data.isFirstLogin) {
            setShowCreatePasswordModal(true);
            RETRY_COUNT = 0;
            return;
          }
          uiLogger(
            import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.production
              ? uiLoggerNamesRecon.UI_PROD_LOGIN_CLICK.functionName
              : import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.staging
              ? uiLoggerNamesRecon.UI_STAGE_LOGIN_CLICK.functionName
              : uiLoggerNamesRecon.UI_DEV_LOGIN_CLICK.functionName,
            null,
            null,
            {
              message:
                import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.production
                  ? uiLoggerNamesRecon.UI_PROD_LOGIN_CLICK.message
                  : import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.staging
                  ? uiLoggerNamesRecon.UI_STAGE_LOGIN_CLICK.message
                  : uiLoggerNamesRecon.UI_DEV_LOGIN_CLICK.message,
            }
          );

          clarity.setTag("RU", `${response.data.actorInfo.name}-${response.data.actorId}`);
          clarity.setTag("User Email", `${response.data?.userEmail}`);
          setIsLoggingInLoading(false);

          if (
            response.data?.zsMonitorAccount &&
            import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.production &&
            window.zapscale
          ) {
            window.zapscale.trackEvent(ZapScaleEventsConstants.LOGGED_IN);
          }

          if (response.data.mfaEnabled) {
            setShowMFA(true);
            setMfaQrReg(response.data.mfaVerified);
            mfaForUserId.current = response.data.userId;

            if (response.data.mfaVerified === false) {
              getQRCode();
            }
            RETRY_COUNT = 0;
            return;
          }
          if (response.status === 200) {
            RETRY_COUNT = 0;
            redirect(response.data, history, response.data.actorInfo.name);
          } else if (response.status === 401) {
            document.getElementById("flash-msg").style.display = "block";
            document.getElementById("msg").innerHTML = "Wrong Credential ! Please Try Again";
          }
        },
        catchCallBack: () => {
          setIsLoggingInLoading(false);
          setCredInput(false);
          setShowAccountSelect(false);
          document.getElementById("flash-msg").style.display = "block";
          document.getElementById("msg").innerHTML = "Login Failed! Please Try Again";
        },
      });
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const closeflash = () => {
    document.getElementById("flash-msg").style.display = "none";
  };

  const getQRCode = () => {
    useFetch<{
      data?: {
        qrCodeUri: string;
      };
    }>(API_ENDPOINTS.GENERATE_QR_CODE_FOR_2FA.url, "POST", {
      failureMessage: API_ENDPOINTS.GENERATE_QR_CODE_FOR_2FA.failureMessage,
      data: {
        userId: mfaForUserId.current || actor?.userId,
      },
      thenCallBack: (_res) => {
        QRCode.toDataURL(_res.data.data.qrCodeUri, { errorCorrectionLevel: "medium" }).then((url) => {
          setQrImage(url);
        });
      },
    });
  };

  const registerMFAotp = () => {
    useFetch<{
      data?: {
        error: string | null;
        userVerified: boolean;
      };
    }>(API_ENDPOINTS.VERIFY_REGISTRATION_OF_2FA.url, "POST", {
      failureMessage: API_ENDPOINTS.VERIFY_REGISTRATION_OF_2FA.failureMessage,
      data: {
        userId: mfaForUserId.current || actor?.userId,
        verificationCode: otp, // otp
      },
      thenCallBack(response) {
        actor.mfaVerified = response.data.data.userVerified;
        setOtpVerified(response.data.data.userVerified);
        if (response.data.data.userVerified) {
          redirect(actor, history, actor.actorInfo.name);
        }
      },
    });
  };

  const loginMFAotp = () => {
    useFetch<{
      data?: {
        otpVerified: boolean;
      };
    }>(API_ENDPOINTS.LOGIN_VERIFICATION_CHALLENGE_2FA.url, "POST", {
      failureMessage: API_ENDPOINTS.LOGIN_VERIFICATION_CHALLENGE_2FA.failureMessage,
      data: {
        userId: mfaForUserId.current || actor?.userId,
        verificationCode: otp, // otp
      },
      thenCallBack: (response) => {
        setOtpVerified(response.data.data.otpVerified);
        if (response.data.data.otpVerified) {
          redirect(actor, history, actor.actorInfo.name);
        }
      },
    });
  };

  const logout = () => {
    useFetch(API_ENDPOINTS.LOGOUT.url, "GET", {
      failureMessage: API_ENDPOINTS.LOGOUT.failureMessage,
      thenCallBack: () => {
        setShowMFA(false);
        setOtpVerified(null);
        if (otpBoxRef.current) otpBoxRef.current.value = "";
      },
    });
  };

  return (
    <Box className=" login-page">
      <Grid container={true}>
        <Grid item={true} sm={12} md={5} className="login-left">
          <img src={illustration} alt="" />
        </Grid>

        {showSignUpForm ? (
          <Grid item={true} sm={12} md={7} className="login-right">
            <Grid item={true} sm={12} md={12}>
              <Register setShowSignUpForm={setShowSignUpForm} />
              <p className="contact">
                Reach us at <a href="mailto:support@nakad.co">support@nakad.co</a>
              </p>
            </Grid>
          </Grid>
        ) : (
          <Grid item={true} sm={12} md={7} className="login-right">
            <div className="login-right-height">
              <div className="login-right-center" style={{ position: "relative" }}>
                <Button
                  startIcon={<ArrowBack />}
                  variant="contained"
                  sx={{ position: "absolute", left: 10, top: 10, bgcolor: "white" }}
                  color="inherit"
                  onClick={logout}
                  hidden={!showMFA}
                >
                  Back
                </Button>
                <div className="center-box column is-5" hidden={showMFA}>
                  <img src={Nakad} alt="" style={{ width: "90%" }} />
                  <p className="enter-info-text">Please enter your user information</p>
                  <div className="notification is-danger" id="flash-msg">
                    <button className="delete" onClick={closeflash} />
                    <p id="msg" />
                  </div>

                  {showAccountSelect ? (
                    <>
                      <Autocomplete
                        onChange={(_, value) => {
                          setCurrentSelectedAccountId(value.id);
                          setForm({ ...form, actorId: parseInt(value.id) });
                        }}
                        className="update-custom-border-radius"
                        disableClearable
                        options={companyList}
                        getOptionLabel={(option) => option.name}
                        filterOptions={createFilterOptions({
                          stringify: (option) => `${option.id} ${option.name}`,
                        })}
                        sx={{ width: 350, background: "#fff", mt: "16px", mx: "auto" }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id} style={{ display: "block" }}>
                            <div style={{ wordWrap: "break-word" }}>{option.name}</div>
                            <div>
                              <span style={{ color: "#666666", fontSize: "12px" }}>Account ID: {option.id}</span>
                            </div>
                          </li>
                        )}
                        renderInput={(params) => <TextField {...params} label="Select Account" />}
                      />
                    </>
                  ) : (
                    <div>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input input-box"
                            type="text"
                            name="username"
                            placeholder="User ID"
                            value={form.username}
                            onChange={update}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control has-icons-right">
                          <div style={{ display: "flex" }}>
                            <input
                              className="input input-box"
                              type={passwordShown ? "text" : "password"}
                              name="password"
                              placeholder="Password"
                              value={form.password}
                              onChange={update}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  collectLoginCreds();
                                  event.preventDefault();
                                }
                              }}
                              required={true}
                            />
                            <i onClick={togglePasswordVisiblity} id="eye">
                              {eye}
                            </i>
                          </div>
                        </div>
                        <Link className="help has-text-right" to="/reset">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className="buttons">
                    {credInput ? (
                      <Button
                        startIcon={<LoadingIcon loading={isLoggingInLoading} />}
                        onClick={loginUser}
                        disabled={currentSelectedAccountId ? isLoggingInLoading : true}
                        variant="contained"
                        className="fw_600 fs_16"
                        sx={{
                          margin: " 25px auto",
                          padding: "10px 24px",
                          borderRadius: "10px !important",
                          background: "#541C4C",
                          color: "#fff",
                          "&:hover, &.Mui-focusVisible": { background: "#541C4C" },
                        }}
                      >
                        Login
                      </Button>
                    ) : (
                      <Button
                        startIcon={<LoadingIcon loading={isCollectingLoginCredsLoading} />}
                        disabled={isCollectingLoginCredsLoading}
                        onClick={collectLoginCreds}
                        variant="contained"
                        className="fw_600 fs_16"
                        sx={{
                          margin: " 25px auto",
                          padding: "10px 24px",
                          borderRadius: "10px !important",
                          background: "#541C4C",
                          color: "#fff",
                          "&:hover, &.Mui-focusVisible": { background: "#541C4C" },
                        }}
                      >
                        Login
                      </Button>
                    )}
                    {disableSignUpBtnForNow ? null : (
                      <button
                        onClick={() => {
                          setShowSignUpForm(true);
                        }}
                        className="button login-button"
                      >
                        Sign Up
                      </button>
                    )}
                  </div>
                  {/* </form> */}
                </div>
                <Box
                  className="center-box column is-9 fade_in"
                  gap={3}
                  sx={{ display: "flex", flexFlow: "column" }}
                  hidden={!showMFA}
                >
                  <h2 style={{ fontSize: 32, fontWeight: 600, color: "#000" }}>Multi Factor Authentication</h2>
                  <Card className="d_flex" sx={{ width: "100%", bgcolor: "#fffff3" }} hidden={mfaQrReg}>
                    <Box className="left" minWidth={228} borderRight={"1px solid #eee"} p={3}>
                      <div
                        className="imgBox center_align_ver_horiz"
                        style={{ border: "1px solid #541c4c", borderRadius: 8, width: 180, height: 180 }}
                      >
                        {!qrImage ? (
                          <CircularProgress size={32} />
                        ) : (
                          <img
                            src={qrImage ? qrImage : "logo192.png"}
                            alt="QR Code"
                            width={180}
                            style={{ borderRadius: 8 }}
                          />
                        )}
                      </div>
                      <p style={{ fontSize: 14, color: "#541c4c" }}>Scan this QR from an Authenticator App</p>
                    </Box>
                    <Box component={"ol"} sx={{ ml: 3, p: 3, "& *": { fontSize: 14, textAlign: "left" } }}>
                      <li>
                        Download an <b>Authenticator App</b> (
                        <a
                          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Google Authenticator
                        </a>
                        ,{" "}
                        <a
                          href="https://www.microsoft.com/en-in/security/mobile-authenticator-app"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Microsoft Authenticator
                        </a>
                        , or any preferred app) from your device's App Store.
                      </li>
                      <li>Open the app and complete the setup as per instructions in the app.</li>
                      <li>
                        Tap <b>"Scan a barcode or QR code"</b>. (You might need to give the app permission to access
                        your camera.)
                      </li>
                      <li>
                        Point your device's camera at the QR code shown here. The app will automatically recognize and
                        add your account.
                      </li>
                      <li>
                        The Authenticator app will show a <b>6-digit code</b> that refreshes at given interval of time,
                        for your account.
                      </li>
                      <li>
                        <b>Enter this code</b> in the input area given below, to complete the setup.
                      </li>
                    </Box>
                  </Card>
                  <Box className="d_flex" flexDirection={"column"} width={350} gap={2} mx={"auto"}>
                    <p style={{ fontSize: 14 }}>Enter the 6-Digit Code from the Authenticator App</p>
                    <FormControl
                      sx={{ flexFlow: "row", gap: 2, justifyContent: "center" }}
                      component={"form"}
                      name="loginBox"
                      target="#"
                      onSubmit={(_e) => _e.preventDefault()}
                    >
                      <div className="control">
                        <input
                          ref={otpBoxRef}
                          className="input input-box"
                          type="number"
                          name="otpBox"
                          placeholder="6-Digit Code"
                          onChange={(_e) => setOTP(_e.target.value)}
                          required={true}
                          style={{ margin: 0, cursor: "unset" }}
                          minLength={6}
                        />
                      </div>
                      <button
                        type="submit"
                        className="button login-button"
                        onClick={() => (actor.mfaVerified ? loginMFAotp() : registerMFAotp())}
                        style={{ margin: 0 }}
                      >
                        Submit
                      </button>
                    </FormControl>
                    <div
                      className="notification is-danger"
                      style={{ position: "relative", right: 0 }}
                      hidden={otpVerified === null || otpVerified === true}
                    >
                      <p id="msg">Incorrect / Expired Code</p>
                    </div>
                  </Box>
                </Box>
              </div>
              <p className="contact">
                Reach us at{" "}
                <a
                  href="mailto:support@nakad.co"
                  // onClick={() => {
                  //   uiLogger(uiLoggerName.UI_PROD_SUPPORT_CLICK);
                  // }}
                >
                  support@nakad.co
                </a>
              </p>
            </div>
          </Grid>
        )}
      </Grid>
      {showCreatePasswordModal && (
        <CreatePasswordModal
          open={showCreatePasswordModal}
          setOpen={setShowCreatePasswordModal}
          setShowAccountSelect={setShowAccountSelect}
          setForm={setForm}
          setIsLoggingInLoading={setIsLoggingInLoading}
        />
      )}
    </Box>
  );
};

export default Login;

function redirect(actor: FrontendActor, history: ReturnType<typeof useHistory>, actorName: string) {
  if (actor.actorType === ActorTypes.Admin) {
    sessionStorage.setItem("isSessionActive", "true");
    history.push("/admin/userlist");
  } else if (actor.actorType === ActorTypes.User) {
    sessionStorage.setItem("isSessionActive", "true");
    const accountName = actorName
      .toString()
      .toLowerCase()
      .replace(/[^a-z\d]+/gi, "");
    history.push(`/${accountName}/recon360/Summary/Ledger`);
    // history.push(`/${actor}/recon360/Summary`);
  } else {
    alert(actor.actorType + " not implemented");
  }
}
