import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SendIcon from "@mui/icons-material/Send";
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import React from "react";
import { uiLoggerNamesReportsMailAutomation } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import { MIS_MAIL_TYPES } from "./Interfaces";

const MailMISReportsActions = ({ setCurrentMailSetupType, setIsEditingMailTemplateFlag }) => {
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: 2,
          backgroundColor: "#ffffff",
          borderRadius: 1,
          width: "50%",
        }}
      >
        <ListItem disablePadding>
          <ListItemButton
            sx={{ display: "flex", justifyContent: "space-between", paddingX: 0 }}
            onClick={() => {
              uiLogger(
                uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_SENDQUICKEMAIL_CLICK.functionName,
                DEFAULT_COMPANY_ID,
                DEFAULT_BRANCH_CODE,
                {
                  message: uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_SENDQUICKEMAIL_CLICK.message,
                }
              );
              setCurrentMailSetupType(MIS_MAIL_TYPES.quickEmail);
              setIsEditingMailTemplateFlag(false);
            }}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 40,
                  height: 40,
                  backgroundColor: "#541c4c",
                  borderRadius: 1,
                }}
              >
                <SendIcon sx={{ color: "#ffffff" }} />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary="Send Quick Email"
              primaryTypographyProps={{
                sx: {
                  fontWeight: 600,
                  fontSize: "22px",
                },
              }}
            />
            <ListItemIcon sx={{ position: "relative", right: "-30px" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Box>
          <Typography variant="body2" className="fw_400 fs_14" color="rgba(0, 0, 0, 0.60)">
            Send MIS reports instantly with ease to your management and internal team members
          </Typography>
        </Box>
      </Paper>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: 2,
          backgroundColor: "#ffffff",
          borderRadius: 1,
          width: "50%",
        }}
      >
        <ListItem disablePadding>
          <ListItemButton
            sx={{ display: "flex", justifyContent: "space-between", paddingX: 0 }}
            onClick={() => {
              uiLogger(
                uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_SENDAUTOMATEEMAIL_CLICK.functionName,
                DEFAULT_COMPANY_ID,
                DEFAULT_BRANCH_CODE,
                {
                  message: uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_SENDAUTOMATEEMAIL_CLICK.message,
                }
              );
              setCurrentMailSetupType(MIS_MAIL_TYPES.automatedEmail);
              setIsEditingMailTemplateFlag(false);
            }}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 40,
                  height: 40,
                  backgroundColor: "#541c4c",
                  borderRadius: 1,
                }}
              >
                <SendIcon sx={{ color: "#ffffff" }} />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary="Send Automated Email"
              primaryTypographyProps={{
                sx: {
                  fontWeight: 600,
                  fontSize: "22px",
                },
              }}
            />
            <ListItemIcon sx={{ position: "relative", right: "-30px" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Box>
          <Typography variant="body2" className="fw_400 fs_14" color="rgba(0, 0, 0, 0.60)">
            Automate email templates to send MIS reports regularly and effortlessly to your management and internal team
            members
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default MailMISReportsActions;
