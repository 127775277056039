import Quill from "quill";
import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./quill.snow.scss";

export const FontsList = [
  "Sans-serif",
  "Serif",
  "Courier",
  "Georgia",
  "Gill Sans",
  "Garamond",
  "Helvetica",
  "Monospace",
  "Palatino",
  "Tahoma",
  "Verdana",
];

export const toolbarOptions = [
  // ["blockquote", "code-block"],
  // ["link", "image", "video", "formula"],
  [{ font: FontsList }],
  // [{ size: ["small", false, "large", "x-large"] }], // custom dropdown
  [{ size: ["12px", "14px", "16px", "18px", "24px", "32px", "48px"] }], // Custom size options

  // [{ header: 1 }, { header: 2 }], // custom button values
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
  ["link", "image"],
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  // ["clean"], // remove formatting button
];

export const formatHtml = (html) => {
  const tab = "\t";
  let result = "";
  let indent = "";

  html.split(/>\s*</).forEach(function (element) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length);
    }
    result += indent + "<" + element + ">\r\n";
    if (element.match(/^<?\w[^>]*[^/]$/) && !element.startsWith("input")) {
      indent += tab;
    }
  });

  return result.substring(1, result.length - 3);
};

// Get the block blot (default is <p>)
const Block = Quill.import("blots/block") as any;

// Extend it to create a div-based block
class DivBlock extends Block {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static tagName = "div"; // Change the tag from <p> to <div>
}

interface EditorProps {
  defaultValue: any;
  onTextChange: any;
  onSelectionChange: any;
  onBlur: React.FocusEventHandler<HTMLDivElement>;
}
// Editor is an uncontrolled React component
export const Editor = forwardRef(({ defaultValue, onTextChange, onSelectionChange, onBlur }: EditorProps, ref: any) => {
  const containerRef = useRef(null);
  const defaultValueRef = useRef(defaultValue);
  const onTextChangeRef = useRef(onTextChange);
  const onSelectionChangeRef = useRef(onSelectionChange);

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
    onSelectionChangeRef.current = onSelectionChange;
  });

  // useEffect(() => {
  //   ref.current?.enable(!readOnly);
  // }, [ref, readOnly]);

  useEffect(() => {
    const container = containerRef.current;
    const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));

    // configure Quill to use inline styles so the email's format properly
    // Register the custom blot
    Quill.register(DivBlock, true);

    const AlignStyle = Quill.import("attributors/style/align") as any;
    Quill.register(AlignStyle, true);

    const BackgroundStyle = Quill.import("attributors/style/background") as any;
    Quill.register(BackgroundStyle, true);
    const ColorStyle = Quill.import("attributors/style/color") as any;
    Quill.register(ColorStyle, true);

    const DirectionStyle = Quill.import("attributors/style/direction") as any;
    Quill.register(DirectionStyle, true);

    // Font Registration
    const FontStyle = Quill.import("attributors/style/font") as any;
    FontStyle.whitelist = [...FontsList];
    Quill.register(FontStyle, true);
    // const FontAttributor = Quill.import("attributors/class/font") as any;
    // FontAttributor.whitelist = ["sofia", "slabo", "roboto", "inconsolata", "ubuntu"];
    // Quill.register(FontAttributor, true);

    // Size Registration

    // for inline styles sizes
    const SizeStyle = Quill.import("attributors/style/size") as any;
    SizeStyle.whitelist = ["12px", "14px", "16px", "18px", "24px", "32px", "48px"]; // Use pixel values
    Quill.register(SizeStyle, true);

    // for class name sizes
    // const Size = Quill.import("attributors/class/size") as any;
    // Size.whitelist = ["small", "normal", "large", "huge"]; // or your custom sizes
    // Quill.register(Size, true);

    // map sizes in toolbar to sizes in array
    const Size = Quill.import("formats/size") as any;
    // Size.whitelist = ["small", false, "large", "x-large"];
    Size.whitelist = ["12px", "14px", "16px", "18px", "24px", "32px", "48px"]; // Use pixel values

    Quill.register(Size, true);

    const quill = new Quill(editorContainer, {
      modules: {
        toolbar: toolbarOptions,
      },
      theme: "snow",
    });

    ref.current = quill;

    if (defaultValueRef.current) {
      quill.setContents(defaultValueRef.current);
    }

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      onTextChangeRef.current?.(...args);
    });

    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      onSelectionChangeRef.current?.(...args);
    });

    // console.log(quill.getModule("toolbar"), Quill.imports, quill);

    return () => {
      ref.current = null;
      container.innerHTML = "";
    };
  }, [ref]);

  return (
    <div
      ref={containerRef}
      onBlur={(e) => {
        onBlur(e);
        document.body.blur();
      }}
    ></div>
  );
});

Editor.displayName = "Editor";

interface NewEditorProps {
  key?: React.Key;
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
}

const NewEditor = ({ content, setContent }: NewEditorProps) => {
  const [, setRange] = useState();
  const [, setLastChange] = useState();

  // Use a ref to access the quill instance directly
  const quillRef = useRef();

  const updateContent = () => {
    const newContent = document.querySelector(".ql-editor")?.innerHTML;
    if (content === newContent) return;
    if (setContent) {
      setContent(newContent);
    }
  };

  useEffect(() => {
    (quillRef.current as Quill)?.clipboard?.dangerouslyPasteHTML(content);
  }, []);

  return (
    <>
      <Editor
        ref={quillRef}
        defaultValue={content}
        onSelectionChange={setRange}
        onTextChange={setLastChange}
        onBlur={updateContent}
      />
    </>
  );
};

export default NewEditor;
