import { MoreVert } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, Button, Chip, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useRef, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import ReactTable from "src/Components/ReactTable/ReactTable";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesPartnerRiskManagement } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import ConfirmDeleteReportModal from "./ConfirmDeleteReportModal";
import {
  FUNNEL_REPORT_ALL_COLUMNS,
  MISReportDetails,
  REPORT_TYPES,
  STATUS_REPORT_ALL_COLUMNS,
  VIEW_COLUMNS,
} from "./Interfaces";
import ViewReportCustomisationsModal from "./ViewReportCustomisationsModal";

export default function ReportTableSingle({ report, reportColumns, setPreviewReportDetails, listAllReports }) {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [anchorElOpts, setAnchorElOpts] = React.useState<null | HTMLElement>(null);
  const handleMenuCloseOpts = () => {
    setAnchorElOpts(null);
  };
  const currentReport = useRef<MISReportDetails>();
  const [deleteReportDetails, setDeleteReportDetails] = useState<{
    id: number;
    reportName: string;
    templateNames?: { id: number; name: string }[];
  }>(null);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState<boolean>(false);
  const [showViewReportCustomisationsModal, setShowViewReportCustomisationsModal] = useState<boolean>(false);
  const currentReportDetails = useRef<MISReportDetails>(null);

  const rawCols = report.columnsOrder || [];
  let hiddenColumnsArr = [];
  if (report.reportType === REPORT_TYPES.Funnel) {
    hiddenColumnsArr = [...FUNNEL_REPORT_ALL_COLUMNS, ...VIEW_COLUMNS];
  }
  if (report.reportType === REPORT_TYPES.Status) {
    hiddenColumnsArr = [...STATUS_REPORT_ALL_COLUMNS, ...VIEW_COLUMNS];
  }

  const hiddenColumnsObj = hiddenColumnsArr
    .filter((item) => !rawCols.includes(item))
    .reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});

  const handleDownloadReport = (reportId) => {
    useFetch(API_ENDPOINTS.DOWNLOAD_REPORT_PARTNER_RISK_MANAGEMENT.url, "GET", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_REPORT_PARTNER_RISK_MANAGEMENT.failureMessage,
      showSuccessToast: true,
      config: {
        params: {
          reportId,
        },
      },
      thenCallBack: (response) => {
        const { buffer, filename } = response.data;
        const excelData = Buffer.from(buffer, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, filename);
      },
    });
  };
  return (
    <>
      <Menu
        anchorEl={anchorElOpts}
        open={Boolean(anchorElOpts)}
        onClose={handleMenuCloseOpts}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          key={"downloadReport"}
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_DOWNLOADREPORT_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_DOWNLOADREPORT_CLICK.message }
            );
            handleDownloadReport(currentReport.current.id);
            handleMenuCloseOpts();
          }}
        >
          Download Report
        </MenuItem>
        <MenuItem
          key="editReport"
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_EDITREPORT_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_EDITREPORT_CLICK.message }
            );
            setPreviewReportDetails({
              id: currentReport.current.id,
              reportType: currentReport.current.reportType,
              reportAction: "editReport",
            });
            handleMenuCloseOpts();
          }}
        >
          Edit Report
        </MenuItem>
        <MenuItem
          key={"reportView"}
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_REPORTVIEWSANDFILTERS_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_REPORTVIEWSANDFILTERS_CLICK.message }
            );
            currentReportDetails.current = currentReport.current;
            setShowViewReportCustomisationsModal(true);
            handleMenuCloseOpts();
          }}
        >
          Report View and Filters
        </MenuItem>
        <MenuItem
          key={"duplicateReport"}
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_DUPLICATEREPORT_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_DUPLICATEREPORT_CLICK.message }
            );
            setPreviewReportDetails({
              id: currentReport.current.id,
              reportType: currentReport.current.reportType,
              reportAction: "duplicateReport",
            });
            handleMenuCloseOpts();
          }}
        >
          Duplicate Report
        </MenuItem>

        <MenuItem
          key={"deleteReport"}
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_DELETEREPORT_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_DELETEREPORT_CLICK.message }
            );
            setDeleteReportDetails({
              id: currentReport.current.id,
              reportName: currentReport.current.reportName,
              templateNames: currentReport.current.mailTemplates,
            });
            setShowDeleteReportModal(true);
            handleMenuCloseOpts();
          }}
        >
          Delete Report
        </MenuItem>
      </Menu>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          boxShadow: 5,
          mb: 4,
          overflow: "scroll",
        }}
      >
        <Stack direction="row" p={2}>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 500, fontSize: "20px" }}>
              {report.reportName}
            </Typography>
            {report.mailTemplates?.length > 0 && (
              <Tooltip
                title={`This report is currently being used in automated email: ${report.mailTemplates
                  .map((item) => item.name)
                  .join(",")}`}
              >
                <Chip
                  avatar={<FiberManualRecordIcon color="error" fontSize="small" />}
                  label="Using in email"
                  color="success"
                  variant="outlined"
                  sx={{
                    ".MuiChip-avatar": { color: "inherit" },
                    "& .MuiChip-label": {
                      fontSize: "13px",
                    },
                  }}
                />
              </Tooltip>
            )}
          </Box>
          <Button
            endIcon={<MoreVert />}
            sx={{ color: "rgba(0,0,0,0.6)", ml: "auto" }}
            onClick={(ev) => {
              currentReport.current = report;
              setAnchorElOpts(ev.currentTarget);
            }}
          >
            More Options
          </Button>
          <Tooltip title="Toggle full screen">
            <IconButton
              onClick={() => {
                uiLogger(
                  uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_FULLSCREEN_CLICK.functionName,
                  DEFAULT_COMPANY_ID,
                  DEFAULT_BRANCH_CODE,
                  { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_FULLSCREEN_CLICK.message }
                );
                setIsFullScreen((prev) => !prev);
              }}
            >
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <ReactTable
          additionalState={{
            isFullScreen: isFullScreen,
          }}
          enableColumnActions={false}
          columns={reportColumns}
          rows={report.reportData}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableGrouping={false}
          enableGlobalFilter={false}
          enableColumnPinning={false}
          enableExpanding={true}
          enableColumnDragging={false}
          enableExpandAll={true}
          enableDensityToggle={false}
          enableColumnOrdering={false}
          filterFromLeafRows={true}
          initialStateColumnOrder={report.columnsOrder}
          initialStateColumnVisibility={hiddenColumnsObj}
          internalActionsOrder={isFullScreen ? ["fullScreenToggle"] : []}
          renderTopToolbar={() => (isFullScreen ? null : "")}
          fullScreen={isFullScreen}
          setFullScreen={setIsFullScreen}
        />
      </Box>
      {showDeleteReportModal && (
        <ConfirmDeleteReportModal
          open={showDeleteReportModal}
          setOpen={setShowDeleteReportModal}
          deleteReportDetails={deleteReportDetails}
          listAllReports={listAllReports}
        />
      )}
      {showViewReportCustomisationsModal && (
        <ViewReportCustomisationsModal
          open={showViewReportCustomisationsModal}
          setOpen={setShowViewReportCustomisationsModal}
          reportDetails={currentReportDetails.current}
          setPreviewReportDetails={setPreviewReportDetails}
        />
      )}
    </>
  );
}
