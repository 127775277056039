import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box, Button } from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesPartnerRiskManagement } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import AddMISReportLoading from "./AddMISReportLoading";
import { REPORT_TYPES } from "./Interfaces";
import ReportTableSingle from "./ReportsTableSingle";

export default function ReportTables({
  listAllReports,
  allReports,
  reportColumns,
  isLoadingReport,
  setPreviewReportDetails,
  appliedFilters,
  reportType,
}) {
  const [isDownloadingBulkReports, setIsDownloadingBulkReports] = useState<boolean>(false);

  useEffect(() => {
    listAllReports();
  }, [appliedFilters]);

  const downloadAllReports = () => {
    setIsDownloadingBulkReports(true);
    useFetch(API_ENDPOINTS.DOWNLOAD_ALL_REPORTS_PARTNER_RISK_MANAGEMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_ALL_REPORTS_PARTNER_RISK_MANAGEMENT.failureMessage,
      showSuccessToast: true,
      data: {
        reportType: reportType,
        filters: appliedFilters,
      },
      thenCallBack: (response) => {
        const { buffer, filename } = response.data;
        const excelData = Buffer.from(buffer, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, filename);
        setIsDownloadingBulkReports(false);
      },
      catchCallBack: () => {
        setIsDownloadingBulkReports(false);
      },
    });
  };
  return isLoadingReport ? (
    <AddMISReportLoading />
  ) : (
    <>
      <Box sx={{ display: "flex", justifyContent: "end", marginBottom: 1 }}>
        <Button
          disabled={isDownloadingBulkReports}
          startIcon={isDownloadingBulkReports ? <LoadingIcon loading={true} /> : <SaveAltIcon />}
          variant="text"
          onClick={() => {
            let uiLoggerFunctionName;
            let uiLoggerMessage;
            if (reportType === REPORT_TYPES.Status) {
              uiLoggerFunctionName =
                uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_DOWNLOADALLSTATUSREPORTS_CLICK.functionName;
              uiLoggerMessage = uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_DOWNLOADALLSTATUSREPORTS_CLICK.message;
            } else if (reportType === REPORT_TYPES.Funnel) {
              uiLoggerFunctionName =
                uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_DOWNLOADALLFUNNELREPORTS_CLICK.functionName;
              uiLoggerMessage = uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_DOWNLOADALLFUNNELREPORTS_CLICK.message;
            }
            uiLogger(uiLoggerFunctionName, DEFAULT_COMPANY_ID, DEFAULT_BRANCH_CODE, {
              message: uiLoggerMessage,
            });
            downloadAllReports();
          }}
        >
          Download all {reportType} reports
        </Button>
      </Box>
      {allReports.map((report) => {
        return (
          <ReportTableSingle
            key={report.id}
            report={report}
            reportColumns={reportColumns}
            setPreviewReportDetails={setPreviewReportDetails}
            listAllReports={listAllReports}
          />
        );
      })}
    </>
  );
}
