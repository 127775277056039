/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useMemo } from "react";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import { BcBetaContext } from "../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";
import { NdAsyncButton, NdSecondaryButton } from "../../MsmePartnerPortal/CommonComponents";
import styles from "../../MsmePartnerPortal/PartnerPortal.module.scss";
import { VerifyEmailErrorReportDetails } from "../PartnerCommsInterfaces";
import { CommonDialogProps } from "./BulkMailWarnDialog";

interface BulkMailCheckDialogProps extends CommonDialogProps {
  rowsData: VerifyEmailErrorReportDetails[];
  reportBase64: string;
  cancelAction: () => Promise<void>;
  authenticateAction: () => Promise<void>;
  continueAction: () => Promise<void>;
}

const BulkMailCheckDialog = ({
  open,
  setOpen,
  rowsData,
  reportBase64,
  cancelAction,
  authenticateAction,
  continueAction,
}: BulkMailCheckDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);
  const { selectedRow } = useContext(BcBetaContext);
  const { userEmailSetting } = useContext(PartnerCommsContext);

  const columnDefinition = useMemo(
    (): ColDef<VerifyEmailErrorReportDetails>[] => [
      {
        header: "Business Partner",
        id: "Business partner name",
        sticky: "left",
        enableSorting: true,
        filterVariant: "autocomplete",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase() || "",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden" title={row.original.businessPartnerName}>
            {row.original.businessPartnerName}
          </div>
        ),
        size: 250,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row?.vendorCode || "",
        enableSorting: true,
        size: 200,
        filterVariant: "autocomplete",
      },
      {
        header: "Issue",
        accessorFn: (row) => row?.reason?.toString() || "",
        enableSorting: true,
        size: 300,
        filterVariant: "autocomplete",
        Cell: ({ row }) => (
          <TextField
            className={styles.BR_fix}
            title={row.original.reason.toString()}
            multiline
            minRows={1}
            maxRows={4}
            value={row.original.reason.join(".\n\n")}
            fullWidth
            sx={{ div: { borderRadius: "4px !important" } }}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: styles.BR_fix }} maxWidth="md" fullWidth>
        <DialogTitle bgcolor={"#F9F9F9"}>Issues Found while sending mails</DialogTitle>
        <DialogContent className={styles.flex_column} sx={{ py: 2, px: 3, pt: "16px !important" }}>
          {userEmailSetting.emailCredentialsProvided ? (
            <></>
          ) : (
            <Alert severity="error">Your email "email" is not authenticated and mails cannot be sent.</Alert>
          )}
          <Stack sx={{ py: 2, gap: 2 }}>
            <Typography variant="body1">
              {rowsData.length} out of {selectedRow.length} selected partners have the following issues
            </Typography>
            <ReactTable<VerifyEmailErrorReportDetails>
              columns={columnDefinition}
              rows={rowsData}
              internalActionsOrder={[]}
              additionalInitialState={{ showGlobalFilter: false }}
              renderTopToolbar={() => <Stack p={1}></Stack>}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            className={styles.BR_fix}
            onClick={() => {
              cancelAction();
              setOpen(false);
            }}
            color="inherit"
            disabled={waiting}
          >
            Cancel
          </Button>
          <NdAsyncButton
            variant="outlined"
            className={styles.BR_fix}
            onClick={async () => {
              setWaiting(true);
              const excelData = Buffer.from(reportBase64, "base64");
              const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              const blob = new Blob([excelData], { type: fileType });
              saveAs(blob, `BulkMailErrorReport - ${new Date().toLocaleTimeString()}.xlsx`);
              setOpen(false);
              setWaiting(false);
            }}
            buttonComponent={Button}
          >
            Download Report
          </NdAsyncButton>
          {userEmailSetting.emailCredentialsProvided ? (
            <></>
          ) : (
            <NdSecondaryButton
              className={`${styles.BR_fix}`}
              onClick={() => {
                authenticateAction();
                setOpen(false);
              }}
              variant="contained"
              disabled={waiting}
            >
              Authenticate Email
            </NdSecondaryButton>
          )}
          <NdSecondaryButton
            className={`${styles.BR_fix}`}
            onClick={() => {
              continueAction();
              setOpen(false);
            }}
            variant="contained"
            disabled={waiting || !userEmailSetting.emailCredentialsProvided || rowsData.length === selectedRow.length}
          >
            Continue With Remaining Partners
          </NdSecondaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkMailCheckDialog;
