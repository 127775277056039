import { Autocomplete, AutocompleteProps, Chip, TextField, TextFieldProps } from "@mui/material";
import React from "react";

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

type ModifiedAutoComplete = Partial<AutocompleteProps<any, boolean, true, false>>;

interface GACProps<S, M extends boolean> extends ModifiedAutoComplete {
  options: S[];
  selected: S[] | S;
  setSelected: M extends true ? StateDispatch<S[]> : StateDispatch<S>;
  getOptionLabel: (option: string | S) => string;
  label?: string;
  multiple?: boolean;
  onChange?: (_e: React.SyntheticEvent<Element, Event>, value: M extends true ? S[] : S) => void;
}

export function GetAutoComplete<S, M extends boolean = true>({
  options,
  selected,
  setSelected,
  getOptionLabel,
  label = "Select",
  onChange,
  multiple = true,
  ...others
}: GACProps<S, M>) {
  return (
    <Autocomplete
      value={selected}
      fullWidth={true}
      onChange={(_e, value) => {
        setSelected(value as S & S[]);
        if (onChange) onChange(_e, value as S & S[]);
      }}
      size="small"
      multiple={multiple}
      options={options}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions={true}
      renderInput={(params) => <TextField {...params} label={label} />}
      sx={{ minWidth: 300 }}
      {...others}
    />
  );
}

interface EmailInputProps extends ModifiedAutoComplete {
  emails: string[];
  setEmails: StateDispatch<string[]>;
  label: string;
  placeholder?: string;
  multiple?: boolean;
  textFieldProps?: TextFieldProps;
}

export function EmailInput({
  emails,
  setEmails,
  label,
  placeholder = "Comma Separated Emails",
  textFieldProps,
  ...others
}: EmailInputProps) {
  const AddTagOnInput = (
    _e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement, Element>,
    commas: boolean
  ) => {
    if (commas && _e.target.value.includes(",")) {
      if (!_e.target.value?.trim()) return;
      const splitValues = _e.target.value
        .split(",")
        .filter((v) => v.trim())
        .map((v) => v.trim());
      setEmails((old) => [...old, ...splitValues]);
    } else if (!commas) {
      const values = _e.target.value.trim();
      if (values) setEmails((old) => [...old, values]);
    }
  };
  return (
    <Autocomplete
      multiple={true}
      value={emails}
      size="small"
      options={[]}
      renderTags={() =>
        emails.map((_email) => (
          <Chip
            key={_email}
            label={_email}
            size="small"
            onDelete={() => {
              const _index = emails.indexOf(_email);
              const copy = [...emails];
              if (_index > -1) copy.splice(_index, 1);
              if (_index > -1) setEmails(copy);
            }}
          />
        ))
      }
      sx={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          onChange={(_e) => AddTagOnInput(_e, true)}
          onBlur={(_e) => AddTagOnInput(_e, false)}
          sx={{ ".MuiInputBase-root": { gap: 1 } }}
          {...textFieldProps}
        />
      )}
      noOptionsText={""}
      classes={{ popper: "hidden" }}
      {...others}
    />
  );
}

interface TagsInputProps extends ModifiedAutoComplete {
  tags: string[];
  setTags: StateDispatch<string[]>;
  options?: readonly string[];
  label: string;
  placeholder?: string;
  multiple?: boolean;
  splitter?: "comma" | "space" | "newline";
  textFieldProps?: TextFieldProps;
}

const splitterMap: Record<TagsInputProps["splitter"], "," | " " | "\n"> = {
  comma: ",",
  space: " ",
  newline: "\n",
};

export function TagsInput({
  tags,
  setTags,
  options = [],
  label,
  placeholder = "Comma Separated Tags",
  splitter = "comma",
  textFieldProps,
  ...others
}: TagsInputProps) {
  const splitterVal = splitterMap[splitter];

  const AddTagOnInput = (
    _e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement, Element>,
    commas: boolean
  ) => {
    if (commas && _e.target.value.includes(splitterVal)) {
      const splitValues = _e.target.value
        .split(splitterVal)
        .filter((v) => v.trim())
        .map((v) => v.trim());
      setTags((old) => [...old, ...splitValues]);
    } else if (!commas) {
      const values = _e.target.value.trim();
      if (values) setTags((old) => [...old, values]);
    }
  };
  return (
    <Autocomplete
      multiple={true}
      value={tags}
      size="small"
      options={options}
      onChange={(_e, value) => {
        setTags(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          onChange={(_e) => AddTagOnInput(_e, true)}
          onBlur={(_e) => AddTagOnInput(_e, false)}
          {...textFieldProps}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option + Math.random()}>
            {option}
          </li>
        );
      }}
      noOptionsText={" "}
      {...others}
    />
  );
}

interface TagsInputKeysProps<T extends object> extends ModifiedAutoComplete {
  tags: string[];
  setTags: StateDispatch<string[]>;
  accessor: keyof T;
  uniqueKeyAccessor: keyof T;
  options: readonly T[];
  label: string;
  splitter?: "comma" | "space" | "newline";
  textFieldProps?: TextFieldProps;
}

export function TagsInputUniqueKeys<T extends object>({
  tags,
  setTags,
  accessor,
  uniqueKeyAccessor,
  options,
  label,
  placeholder = "Comma Separated Tags",
  splitter = "comma",
  textFieldProps,
  ...others
}: TagsInputKeysProps<T>) {
  const splitterVal = splitterMap[splitter];

  const AddTagOnInput = (
    _e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement, Element>,
    commas: boolean
  ) => {
    if (commas && _e.target.value.includes(splitterVal)) {
      const splitValues = _e.target.value
        .split(splitterVal)
        .filter((v) => v.trim())
        .map((v) => v.trim());
      setTags((old) => [...old, ...splitValues]);
    } else if (!commas) {
      const values = _e.target.value.trim();
      if (values) setTags((old) => [...old, values]);
    }
  };
  return (
    <Autocomplete<T, boolean, true, false>
      multiple={true}
      value={tags}
      size="small"
      options={options}
      onChange={(_e, value) => {
        setTags(
          (value as (string | T)[]).map((v) => {
            if (typeof v === "string") return v;
            else if (typeof v === "object") return v[accessor] as string;
          })
        );
      }}
      renderTags={(_tagValue, getTagProps) => {
        return tags.map((option, index) => {
          return <Chip size="small" key={index} label={option ? option : " "} {...getTagProps({ index })} />;
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          onChange={(_e) => AddTagOnInput(_e, true)}
          onBlur={(_e) => AddTagOnInput(_e, false)}
          {...textFieldProps}
        />
      )}
      getOptionLabel={(option) => option && (option[accessor] as string)}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[uniqueKeyAccessor] as string}>
            {option[accessor] as string}
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => {
        if (typeof value === "string") return option[accessor] === value;
        else return option[accessor] === value[accessor];
      }}
      noOptionsText={" "}
      {...others}
    />
  );
}
