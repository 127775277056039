import { Add } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";

export const DialogSectionTitleBar = ({ title }: { title: string }) => {
  return (
    <Box sx={{ px: 2, py: 1, bgcolor: "#f3f3f3", borderTop: 1, borderColor: "#dedede" }}>
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
    </Box>
  );
};

export const DialogSection = (props: BoxProps) => {
  return (
    <Box sx={{ p: 2 }} {...props}>
      {props.children}
    </Box>
  );
};

export const RenderCommunicationOptions = (props: {
  name: string;
  description: string;
  value: OptionValues;
  setValue: (value: OptionValues) => void;
  required?: boolean;
  setOpenPDFTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  pdfTemplateId: number | undefined;
  setPdfTemplateId: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  const { pdfTemplates } = useContext(PartnerCommsContext);

  return (
    <React.Fragment key={props.name}>
      <Box sx={{ display: "flex", alignItems: "center", px: 2, py: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">{props.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {props.description}
          </Typography>
        </Box>
        <FormControl disabled={props.required}>
          <RadioGroup
            row
            onChange={(v) => {
              props.setValue(v.target.value as OptionValues);
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="Yes (Mandatory)"
              checked={props.required || props.value === "yes"}
            />
            <FormControlLabel
              value="optional"
              control={<Radio />}
              label="Yes (Optional)"
              checked={props.value === "optional"}
            />
            <FormControlLabel value="no" control={<Radio />} label="No" checked={props.value === "no"} />
          </RadioGroup>
        </FormControl>
      </Box>
      {props.name === "PDF" && (props.value === "optional" || props.value === "yes") && (
        <Box display="flex" alignItems="center" gap={2} px={2} py={1} bgcolor="#f8f8f8" borderRadius={1}>
          <Typography variant="subtitle2" color="textPrimary">
            Dynamic PDF Template
          </Typography>
          <TextField
            select
            variant="outlined"
            size="small"
            defaultValue=""
            sx={{ minWidth: 200 }}
            value={props.pdfTemplateId || ""}
            onChange={async (e) => {
              const filteredSelectedTemplate = pdfTemplates.filter((item) => {
                return item.templateId === Number(e.target.value);
              });

              const { templateId } = filteredSelectedTemplate[0];

              props.setPdfTemplateId(templateId);
            }}
          >
            {pdfTemplates.length > 0 &&
              pdfTemplates.map((item, itemInd: number) => (
                <MenuItem key={itemInd} value={item.templateId}>
                  {item.templateName}
                </MenuItem>
              ))}
            <MenuItem value="">Select Template</MenuItem>
          </TextField>
          <Button
            variant="text"
            startIcon={<Add />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              // Open CreatePDFTemplate
              props.setOpenPDFTemplate(true);
            }}
          >
            NEW TEMPLATE
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

export type OptionKeys = typeof BalanceConfirmationOptions[number]["key"];
export type OptionValues = "yes" | "no" | "optional";

export type OptionKeysLR = typeof LedgerReqOptions[number]["key"];

export const BalanceConfirmationOptions = [
  {
    key: "pdf",
    name: "PDF",
    description:
      "A confirmation document that has to be signed and stamped by the partner to validate their closing balance",
  },
  {
    key: "ledger",
    name: "Ledger",
    description: "Full Ledger of the partner till the closing balance date which is needed for reconciliation",
  },
  {
    key: "openItem",
    name: "Open Items",
    description: "List of Invoices that are not paid yet or Payment is not cleared",
  },
] as const;

export const NotConfirmingBCOptions = [
  {
    key: "closingBalance",
    name: "Closing Balance",
    description: "Closing Balance of the partner on the specified date according to their ledger",
    required: true,
  },
  ...BalanceConfirmationOptions,
] as const;

export const LedgerReqOptions = [
  {
    key: "ledger",
    name: "Ledger",
    description: "Full Ledger of the partner till the closing balance date which is needed for reconciliation",
  },
  {
    key: "partnerBalance",
    name: "Ask Partner Balance",
    description: "Closing Balance of the partner on the specified date according to their ledger",
  },
  {
    key: "openItem",
    name: "Open Items",
    description: "List of Invoices that are not paid yet or Payment is not cleared",
  },
] as const;
