import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button, Chip, Dialog, DialogActions, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";

interface CloseAndResetTaskContentCardProps {
  title: string;
  subTitle: string;
  description: string;
  severity: "info" | "error" | "warning" | "success";
  chipText: string;
  chipColor: "error" | "warning" | "info" | "success" | "primary" | "secondary";
}

const CloseAndResetTaskContentCard = ({
  title,
  subTitle,
  description,
  severity,
  chipText,
  chipColor,
}: CloseAndResetTaskContentCardProps) => {
  return (
    <Box sx={{ border: "1px solid #E0E0E0", borderRadius: "4px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 16px",
        }}
      >
        <Box>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.60)", fontSize: 14 }}>
            {subTitle}
          </Typography>
        </Box>
        <Chip label={chipText} color={chipColor} sx={{ "& .MuiChip-label": { fontSize: 13 } }} />
      </Box>
      <Alert severity={severity} sx={{ "& .MuiAlert-message": { fontSize: "14px" } }}>
        {description}
      </Alert>
    </Box>
  );
};

export default function ReviewCloseAndResetTasksModal({
  open,
  setOpen,
  selectedTasks,
  closeAndResetTasks,
  companyId,
  branchCode,
  setClearRows,
}) {
  const [isClosingAndResettingTasks, setIsClosingAndResettingTasks] = useState<boolean>(false);

  let balanceConfirmedAndApprovedCount = 0;
  let ledgerReceivedAndApprovedCount = 0;
  let responsePendingCount = 0;
  let ledgerReceivedAndNotApprovedCount = 0;
  let balanceConfirmedAndNotApprovedCount = 0;
  let mailNotSentCount = 0;
  const taskIdsToCloseAndReset = [];

  selectedTasks.forEach((task) => {
    if (task.statusOverview === "Confirmed" && task.isApproved) {
      balanceConfirmedAndApprovedCount++;
      taskIdsToCloseAndReset.push(task.ticketId);
    }
    if ((task.statusOverview === "Not Confirmed" || task.statusOverview === "Ledger Submitted") && task.isApproved) {
      ledgerReceivedAndApprovedCount++;
      taskIdsToCloseAndReset.push(task.ticketId);
    }
    if (task.statusOverview === "Pending") {
      responsePendingCount++;
      taskIdsToCloseAndReset.push(task.ticketId);
    }
    if (
      (task.statusOverview === "Not Confirmed" || task.statusOverview === "Ledger Submitted") &&
      task.isApproved === false
    )
      ledgerReceivedAndNotApprovedCount++;
    if (task.statusOverview === "Confirmed" && task.isApproved === false) balanceConfirmedAndNotApprovedCount++;
    if (task.statusOverview === "-") mailNotSentCount++;
  });

  const tasksToCloseAndResetCount =
    balanceConfirmedAndApprovedCount + ledgerReceivedAndApprovedCount + responsePendingCount;

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "868px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
        <Typography variant="h6">Review Tasks</Typography>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Stack sx={{ padding: 2, gap: 2, height: "1100px", overflow: "auto" }}>
        <Typography variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
          {`${tasksToCloseAndResetCount} Tasks to be close and reset`}
        </Typography>
        {balanceConfirmedAndApprovedCount > 0 && (
          <CloseAndResetTaskContentCard
            title="Balance confirmed and Response Approved"
            subTitle={`${balanceConfirmedAndApprovedCount} out of ${selectedTasks.length || 0} selected Tasks`}
            chipText="Tasks will close and reset"
            chipColor="primary"
            description="Response is received, balance is confirmed by the partner and response is approved"
            severity="info"
          />
        )}
        {ledgerReceivedAndApprovedCount > 0 && (
          <CloseAndResetTaskContentCard
            title="Ledger received and Response approved"
            subTitle={`${ledgerReceivedAndApprovedCount} out of ${selectedTasks.length} selected Tasks`}
            chipText="Tasks will close and reset"
            chipColor="warning"
            description="Response is received and balance is not confirmed by the partner. Make sure you still want to close and reset, as the response will be reset and can’t be edited back."
            severity="warning"
          />
        )}
        {responsePendingCount > 0 && (
          <CloseAndResetTaskContentCard
            title="Response not Received from Partner(s)"
            subTitle={`${responsePendingCount} out of ${selectedTasks.length} selected Tasks`}
            chipText="Tasks will close and reset"
            chipColor="warning"
            description="Response not received. Make sure you still want to close and reset, as the response will be reset and can’t be edited back."
            severity="warning"
          />
        )}
        {ledgerReceivedAndNotApprovedCount > 0 && (
          <CloseAndResetTaskContentCard
            title="Ledger received but response is not approved"
            subTitle={`${ledgerReceivedAndNotApprovedCount} out of ${selectedTasks.length} selected Tasks`}
            chipText="Tasks will not close and reset"
            chipColor="error"
            description="Response of the partner is not approved."
            severity="error"
          />
        )}
        {balanceConfirmedAndNotApprovedCount > 0 && (
          <CloseAndResetTaskContentCard
            title="Balance confirmed but Response not Approved"
            subTitle={`${balanceConfirmedAndNotApprovedCount} out of ${selectedTasks.length} selected Tasks`}
            chipText="Tasks will not close and reset"
            chipColor="error"
            description="Response of the partner is not approved and balance is confirmed by the partner."
            severity="error"
          />
        )}
        {mailNotSentCount > 0 && (
          <CloseAndResetTaskContentCard
            title="Mail not sent to Partner(s)"
            subTitle={`${mailNotSentCount} out of ${selectedTasks.length} selected Tasks`}
            chipText="Tasks will not close and reset"
            chipColor="error"
            description="No mail sent to these partners"
            severity="error"
          />
        )}
      </Stack>

      <DialogActions sx={{ padding: 2, background: "#F1F1F1" }}>
        <Button
          disabled={tasksToCloseAndResetCount === 0 || isClosingAndResettingTasks}
          variant="contained"
          sx={{
            background: "#27B27C",
            padding: "8px 22px",
            color: "#FFF",
            borderRadius: "4px !important",
            fontWeight: 500,
            fontSize: "15px",
            "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
          }}
          onClick={async () => {
            uiLogger(
              uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKCLOSEANDRESET_CLOSEANDRESET_CLICK.functionName,
              companyId,
              branchCode,
              {
                message:
                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKCLOSEANDRESET_CLOSEANDRESET_CLICK.message,
              }
            );
            setIsClosingAndResettingTasks(true);
            await closeAndResetTasks(taskIdsToCloseAndReset);
            setIsClosingAndResettingTasks(false);
            setOpen(false);
            setClearRows(true);
          }}
        >
          <LoadingIcon loading={isClosingAndResettingTasks} />
          Close and Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
}
