/* eslint-disable no-useless-escape */
import { ClearAll, Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedTextFieldProps,
  Skeleton,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { createContext, FormEvent, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllActors } from "src/slices/admin/userListSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { ClientType, UserRole, UserRoleUserTypeMap, UserTypes } from "../../entity/models/FrontendActor";
import LoadingIcon from "../Common/LoadingIcon";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import useFetch from "../Common/useFetch";
import TabPanel from "../Recon360/NewBusinessPartner/TabPanel";
import { RegisterResponse } from "./AdminInterfaces";
import "./Register.scss";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const emailValidationRegEx = /^[\w._%+-]+@[\w.-]+\.[\w]{2,5}$/i;
export const gstNumberValidationRegEx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i;
export const phoneNumberValidationRegEx = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/i;
export const personNameValidationRegEx = /^([\w\. \(\),\[\]&#\:\-\/']){3,100}$/;
export const userNameValidationRegEx = /^([a-zA-Z. ]){3,100}$/;
export const pincodeValidationRegEx = /^[1-9][0-9]{5}$/i;

export const RegisterContext = createContext(null);

const Register = () => {
  const [value, setValue] = useState<number>(0);
  const [clientList, setClientList] = useState([]);
  const listAllActors = useSelector((state: any) => state.userList.list);
  const dispatch = useDispatch();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    listAllClients();
  }, []);

  useEffect(() => {
    dispatch(fetchAllActors() as any); // Dispatch the fetchAllActors action creator to fetch the list of actors
  }, [dispatch]);

  const listAllClients = () => {
    useFetch(API_ENDPOINTS.LIST_ALL_CLIENTS.url, "POST", {
      failureMessage: API_ENDPOINTS.LIST_ALL_CLIENTS.failureMessage,
      thenCallBack: (response) => {
        setClientList(response.data.data);
      },
    });
  };

  const RegisterContextValue = {
    clientList,
    listAllActors,
    listAllClients,
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Register")}>
      <RegisterContext.Provider value={RegisterContextValue}>
        <div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Account Registration" {...a11yProps(0)} sx={{ textTransform: "none" }} />
                <Tab label="User Registration" {...a11yProps(1)} sx={{ textTransform: "none" }} />
                <Tab label="Client Registration" {...a11yProps(2)} sx={{ textTransform: "none" }} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <AccountRegistration />
            </TabPanel>
            <TabPanel value={value} index={1} key={2}>
              <UserRegistration />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ClientRegistration />
            </TabPanel>
          </Box>
        </div>
      </RegisterContext.Provider>
    </LoggedInSkeleton>
  );
};

interface CustomTextFieldProps extends OutlinedTextFieldProps {
  regex: RegExp;
  errorMsg: string;
}
const CustomTextField = (props: CustomTextFieldProps) => {
  const [validationMsg, setValidationMsg] = useState("");

  const InputVerifyRegEx = (element: HTMLInputElement, message: string) => {
    if (element instanceof HTMLInputElement) {
      if (!element.validity.valid) setValidationMsg(message);
      else setValidationMsg("");
    }
  };

  return (
    <TextField
      inputRef={(r) => {
        if (r) (r as HTMLInputElement).pattern = `${props.regex.source}`;
      }}
      error={!!validationMsg.length}
      {...props}
      helperText={validationMsg}
      onBlur={(e) => InputVerifyRegEx(e.target as HTMLInputElement, props.errorMsg)}
    />
  );
};

const clearData = () => {
  const allInputs = document.forms[0].querySelectorAll("input");
  allInputs.forEach((input) => (input.value = null));
};

const AccountRegistration = () => {
  const { clientList } = useContext(RegisterContext);
  const [validationMsgs, setValidationMsgs] = useState({
    password: "",
    confirmPassword: "",
  });
  const passInput = useRef<HTMLInputElement>(null);
  const cPassInput = useRef<HTMLInputElement>(null);
  const [integration, setIntegration] = useState(false);
  const [registeredEmails, setRegisteredEmails] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [clientType, setClientType] = useState<string>(ClientType.Internal);
  const [selectedClient, setSelectedClient] = useState(null);

  const setValidationMsg = (key: keyof typeof validationMsgs, message: string) => {
    setValidationMsgs({ ...validationMsgs, [key]: message });
  };

  const PasswordVerify = (type: "length" | "confirm") => {
    if (type === "length") {
      if (passInput.current.value.length < 8) {
        setValidationMsg("password", "Password length must be at least 8 characters");
      } else setValidationMsg("password", "");
    }
    if (type === "confirm") {
      if (passInput.current.value !== cPassInput.current.value) {
        setValidationMsg("confirmPassword", "Confirm Password didn't match Password");
      } else setValidationMsg("confirmPassword", "");
    }
  };

  const InputObjectsRequired = [
    {
      name: "accountName",
      label: "Account Name",
      pattern: /.{3,200}/,
      jsPattern: true,
      errorMsg: "Account Name should be in between 3 and 200 Characters",
      sx: { gridColumn: "1/-1", width: "100%" },
    },
    {
      name: "adminName",
      label: "Admin Username",
      pattern: userNameValidationRegEx,
      errorMsg: "Invalid Admin UserName",
    },
    {
      name: "adminEmail",
      label: "Admin Email",
      type: "email",
      pattern: emailValidationRegEx,
      errorMsg: "Invalid Email",
    },
  ];

  const InputObjectsNR = [
    {
      name: "gstNumber",
      label: "GSTIN",
      pattern: gstNumberValidationRegEx,
      errorMsg: "Invalid GSTIN",
    },
    {
      name: "userPhoneNumber",
      label: "Phone Number",
      pattern: phoneNumberValidationRegEx,
      errorMsg: "Invalid Phone Number",
    },
    {
      name: "pincode",
      label: "Pincode",
      pattern: pincodeValidationRegEx,
      errorMsg: "Invalid Pincode",
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    if (form.checkValidity()) SubmitAccountRegistration(formData);
  };

  const SubmitAccountRegistration = (formData: FormData) => {
    useFetch<RegisterResponse>(API_ENDPOINTS.REGISTER.url, "POST", {
      failureMessage: API_ENDPOINTS.REGISTER.failureMessage,
      showSuccessToast: true,
      data: {
        adminEmail: formData.get("adminEmail"),
        adminName: formData.get("adminName"),
        accountName: formData.get("accountName"),
        accountNickname: formData.get("accountNickname"),
        password: formData.get("password"),
        confirmPassword: formData.get("confirmPassword"),
        clientType: formData.get("clientType"),
        actorInfo: {
          gstNumber: formData.get("gstNumber"),
          address: formData.get("address"),
          userPhoneNumber: formData.get("userPhoneNumber"),
          accountingSoftware: formData.get("accountingSoftware"),
        },
        pincode: formData.get("pincode"),
        integration: Boolean(formData.get("integration")),
        location: formData.get("location"),
        accountCode: formData.get("accountCode"),
        clientId: selectedClient?.clientId,
      },
      thenCallBack: (response) => {
        setRegisteredEmails((response.data.registeredEmails as string[]).join("\n"));
      },
    });
  };

  return (
    <Box sx={{ animation: "fade-in 0.5s ease-out", position: "relative" }}>
      <Card sx={{ m: "auto", width: "min-content" }}>
        <Box component="form" sx={{ m: 1 }} autoComplete="off" id="AccountRegistration" onSubmit={handleSubmit}>
          <FormControl
            sx={{
              gap: 1.5,
              display: "grid",
              gridTemplateColumns: "30ch 30ch",
              "& > :not(style)": { minWidth: "30ch" },
              "& fieldset span": {
                fontSize: "11px",
              },
            }}
          >
            {InputObjectsRequired.map((inputObj) => (
              <CustomTextField
                key={inputObj.name}
                name={inputObj.name}
                label={inputObj.label}
                required={true}
                sx={inputObj.sx ? inputObj.sx : {}}
                type={inputObj.type || "text"}
                variant="outlined"
                regex={inputObj.pattern}
                errorMsg={inputObj.errorMsg}
              />
            ))}
            <TextField
              inputRef={(r) => {
                if (r) passInput.current = r as HTMLInputElement;
              }}
              error={!!validationMsgs.password.length}
              name="password"
              label="Password"
              required={true}
              type={showPassword ? "text" : "password"}
              helperText={validationMsgs.password}
              onBlur={() => PasswordVerify("length")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{
                        marginRight: "-6px",
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              inputRef={(r) => {
                if (r) cPassInput.current = r as HTMLInputElement;
              }}
              error={!!validationMsgs.confirmPassword.length}
              name="confirmPassword"
              label="Confirm Password"
              required={true}
              type="password"
              helperText={validationMsgs.confirmPassword}
              onBlur={() => PasswordVerify("confirm")}
            />
            <CustomTextField
              regex={/^.{0,16}$/}
              errorMsg="Maximum 16 chars are allowed"
              variant="outlined"
              sx={{ gridColumn: "1/-1", width: "100%" }}
              name="accountNickname"
              label="Account Nickname"
            />

            <Autocomplete
              id="country-select-demo"
              sx={{ minWidth: 280 }}
              options={clientList}
              autoHighlight
              value={selectedClient}
              getOptionLabel={(option: any) => `${option.clientName} (Client ID: ${option.clientId})`}
              onChange={(_event, newValue) => {
                setSelectedClient(newValue);
                if (newValue?.clientType) {
                  setClientType(newValue.clientType);
                } else {
                  setClientType(ClientType.Internal);
                }
              }}
              renderOption={(props, option) => (
                <Box
                  key={`${option.clientId}-${option.clientName}`}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  style={{ display: "block" }}
                >
                  <div>{option.clientName}</div>
                  <div>
                    <span style={{ color: "#666666", fontSize: "12px" }}>Client ID: {option.clientId}</span>
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client ID"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
            <TextField
              required={true}
              name="clientType"
              select={true}
              label="Client Type"
              value={clientType}
              onChange={(e) => setClientType(e.target.value)}
              disabled={selectedClient !== null}
            >
              {Object.keys(ClientType).map((type) => (
                <MenuItem key={type} value={type}>
                  {ClientType[type]}
                </MenuItem>
              ))}
            </TextField>
            <div />
            <div />
            {InputObjectsNR.map((inputObj) => (
              <CustomTextField
                key={inputObj.name}
                name={inputObj.name}
                label={inputObj.label}
                variant="outlined"
                regex={inputObj.pattern}
                errorMsg={inputObj.errorMsg}
              />
            ))}
            <TextField name="address" label="Address" />
            <TextField name="accountCode" label="Account Code" />
            <TextField name="location" label="Location" />
            <TextField
              name="accountingSoftware"
              disabled={!integration}
              select={true}
              label="Accounting Software"
              required={true}
            >
              <MenuItem value={"Tally"}>Tally</MenuItem>
              <MenuItem value={"SAP"}>SAP</MenuItem>
              <MenuItem value={"PULL_INTEGRATION"}>Pull Integration</MenuItem>
            </TextField>
            <FormControlLabel
              name="integration"
              control={<Checkbox value={integration} onChange={(_e, c) => setIntegration(c)} />}
              label="Integration"
              value={integration}
              sx={{ justifyContent: "center" }}
            />
          </FormControl>
        </Box>
      </Card>
      <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
        <Button type="submit" form="AccountRegistration" className="theme_btn" variant="outlined" size="large">
          Register
        </Button>
      </div>
      <Button
        variant="outlined"
        onClick={clearData}
        startIcon={<ClearAll />}
        sx={{ position: "absolute", top: 2, right: 2 }}
      >
        Clear
      </Button>
      <TextField
        label="Registered Emails"
        disabled={true}
        value={registeredEmails}
        multiline={true}
        maxRows={2}
        sx={{
          position: "absolute",
          top: 120,
          right: 2,
          width: "210px",
          background: "#fff",
          "& fieldset span": {
            fontSize: "11px",
          },
          "& label:not([data-shrink='true'])": {
            top: 8,
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setRegisteredEmails("")}>
              <ClearAll />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

const UserRegistration = () => {
  const { listAllActors } = useContext(RegisterContext);
  const [validationMsgs, setValidationMsgs] = useState({
    email: "",
    password: "",
    username: "",
  });
  const passInput = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState(false);

  const [selectedUserType, setSelectedUserType] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [showUserRole, setShowUserRole] = useState(false);
  const [selectedActor, setSelectedActor] = useState(null);

  const setValidationMsg = (key: keyof typeof validationMsgs, message: string) => {
    setValidationMsgs({ ...validationMsgs, [key]: message });
  };

  const PasswordVerify = (type: "length" | "confirm") => {
    if (type === "length") {
      if (passInput.current.value.length < 8) {
        setValidationMsg("password", "Password length must be at least 8 characters");
      } else setValidationMsg("password", "");
    }
  };

  const InputObjectsRequired = [
    {
      name: "name",
      label: "User Name",
      pattern: userNameValidationRegEx,
      errorMsg: "Invalid Username",
      sx: { gridColumn: "1/-1", width: "100%" },
    },
    {
      name: "email",
      label: "User Email",
      type: "email",
      pattern: emailValidationRegEx,
      errorMsg: "Invalid Email",
    },
  ];

  const handleUserTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const userType = event.target.value as UserTypes;
    setSelectedUserType(userType);
    setShowUserRole(true);
    const roles = Object.entries(UserRoleUserTypeMap).filter(([_, type]) => type === userType);
    if (roles.length === 1) {
      setSelectedUserRole(roles[0][0]);
    } else {
      setSelectedUserRole("");
    }
  };

  const handleUserRoleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setSelectedUserRole(event.target.value as UserRole);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    if (form.checkValidity()) SubmitUserRegistration(formData);
  };

  const SubmitUserRegistration = (formData: FormData) => {
    useFetch(API_ENDPOINTS.ADD_ACTOR_USER_MAPPING.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_ACTOR_USER_MAPPING.failureMessage,
      showSuccessToast: true,
      data: {
        name: formData.get("name"),
        actorId: selectedActor?.actorId,
        email: formData.get("email"),
        password: formData.get("password"),
        userType: selectedUserType,
        userRole: selectedUserRole,
      },
    });
  };

  return (
    <Box sx={{ animation: "fade-in 0.5s ease-out", position: "relative" }}>
      <Card sx={{ m: "auto", width: "min-content" }}>
        <Box component="form" sx={{ m: 1 }} autoComplete="off" id="UserRegistration" onSubmit={handleSubmit}>
          <FormControl
            sx={{
              gap: 1.5,
              display: "grid",
              gridTemplateColumns: "30ch 30ch",
              "& > :not(style)": { minWidth: "30ch" },
              "& fieldset span": {
                fontSize: "11px",
              },
            }}
          >
            {InputObjectsRequired.map((inputObj) => (
              <CustomTextField
                key={inputObj.name}
                name={inputObj.name}
                label={inputObj.label}
                required={true}
                sx={inputObj.sx ? inputObj.sx : {}}
                type={inputObj.type || "text"}
                variant="outlined"
                regex={inputObj.pattern}
                errorMsg={inputObj.errorMsg}
              />
            ))}
            <TextField
              inputRef={(r) => {
                if (r) passInput.current = r as HTMLInputElement;
              }}
              error={!!validationMsgs.password.length}
              name="password"
              label="Password"
              required={true}
              type={showPassword ? "text" : "password"}
              helperText={validationMsgs.password}
              onBlur={() => PasswordVerify("length")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{
                        marginRight: "-6px",
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              select
              label="User Type"
              value={selectedUserType}
              onChange={handleUserTypeChange}
              defaultValue={UserTypes.User}
            >
              {Object.keys(UserTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  {UserTypes[type]}
                </MenuItem>
              ))}
            </TextField>
            {showUserRole ? (
              <TextField select label="User Role" value={selectedUserRole} onChange={handleUserRoleChange}>
                {Object.keys(UserRole)
                  .filter((role) => UserRoleUserTypeMap[role] === selectedUserType)
                  .map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
            ) : (
              <br />
            )}

            <Autocomplete
              id="country-select-demo"
              sx={{ minWidth: 280 }}
              options={listAllActors}
              autoHighlight
              value={selectedActor}
              getOptionLabel={(option: any) => `${option.actorName} (Actor ID: ${option.actorId})`}
              onChange={(_event, newValue) => {
                setSelectedActor(newValue); // Store the selected option in state
              }}
              renderOption={(props, option) => (
                <Box
                  key={`${option.actorId}-${option.actorName}`}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  style={{ display: "block" }}
                >
                  <div>{option.actorName}</div>
                  <div>
                    <span style={{ color: "#666666", fontSize: "12px" }}>Actor ID: {option.actorId}</span>
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Actor IDs"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </Box>
      </Card>
      <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
        <Button type="submit" form="UserRegistration" className="theme_btn" variant="outlined" size="large">
          Register
        </Button>
      </div>
      <Button
        variant="outlined"
        onClick={clearData}
        startIcon={<ClearAll />}
        sx={{ position: "absolute", top: 2, right: 2 }}
      >
        Clear
      </Button>
    </Box>
  );
};

const ClientRegistration = () => {
  const { clientList, listAllActors, listAllClients } = useContext(RegisterContext);
  const columnsClientList = ["Client ID", "Client Name", "CRM ID", "Client Type", "Tracking on Zapscale", "Actions"];
  const columnsClientActorList = [
    "Actor ID",
    "Account Name",
    "Integration",
    "Account Nickname",
    "Accounting Software",
    "Actions",
  ];
  const [openCreateNewClientModal, setOpenCreateNewClientModal] = useState(false);
  const [createNewClientModalLoader, setCreateNewClientModalLoader] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientType, setClientType] = useState("");
  const [crmId, setCrmId] = useState(null);
  const [selectedActors, setSelectedActors] = useState([]);
  const [enableZapscaleTracking, setEnableZapscaleTracking] = useState(true);
  const [showErrorCreateClient, setShowErrorCreateClient] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isEditClient, setIsEditClient] = useState(false);
  const [editClientId, setEditClientId] = useState(null);
  const [openViewClientModal, setOpenViewClientModal] = useState(false);
  const [clientActorList, setClientActorList] = useState([]);
  const [addNewAccountForClientModal, setAddNewAccountForClientModal] = useState(false);
  const [linkNewAccountToClientLoader, setLinkNewAccountToClientLoader] = useState(false);
  const [deleteAccountForClientModal, setDeleteAccountForClientModal] = useState(false);
  const [deleteAccountForClientModalLoader, setDeleteAccountForClientModalLoader] = useState(false);
  const [selectedActorIdOfAccountForClient, setSelectedActorIdOfAccountForClient] = useState(null);
  const [listAllActorsForClientLoader, setListAllActorsForClientLoader] = useState(false);

  const handleCloseCreateNewClientModal = () => {
    setOpenCreateNewClientModal(false);
    setClientName("");
    setClientType("");
    setCrmId(null);
    setSelectedActors([]);
    setEnableZapscaleTracking(true);
  };

  const addNewClient = () => {
    const actorIds = selectedActors.map((actor) => actor.actorId);
    setCreateNewClientModalLoader(true);
    useFetch(API_ENDPOINTS.ADD_NEW_CLIENT.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_NEW_CLIENT.failureMessage,
      showSuccessToast: true,
      data: {
        clientName: clientName,
        clientType: clientType,
        crmId: crmId,
        actors: actorIds,
        zsMonitorAccount: enableZapscaleTracking,
      },
      thenCallBack: () => {
        listAllClients();
        handleCloseCreateNewClientModal();
        setCreateNewClientModalLoader(false);
      },
      catchCallBack: (error) => {
        if (error.response.data?.alreadyMappedActorsList.length > 0) {
          setShowErrorCreateClient(true);
          setErrorList(error.response.data?.alreadyMappedActorsList);
        }
        setCreateNewClientModalLoader(false);
      },
    });
  };

  const UpdateClientDetails = () => {
    setCreateNewClientModalLoader(true);
    useFetch(API_ENDPOINTS.UPDATE_CLIENT_DETAILS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_CLIENT_DETAILS.failureMessage,
      showSuccessToast: true,
      data: {
        clientId: editClientId,
        clientName: clientName,
        clientType: clientType,
        crmId: crmId,
        zsMonitorAccount: enableZapscaleTracking,
      },
      thenCallBack: () => {
        listAllClients();
        handleCloseCreateNewClientModal();
        setCreateNewClientModalLoader(false);
      },
      catchCallBack: () => {
        setCreateNewClientModalLoader(false);
      },
    });
  };

  const listAllActorsForClient = (clientId) => {
    setListAllActorsForClientLoader(true);
    useFetch(API_ENDPOINTS.LIST_ALL_ACTORS_FOR_CLIENT.url, "POST", {
      failureMessage: API_ENDPOINTS.LIST_ALL_ACTORS_FOR_CLIENT.failureMessage,
      data: {
        clientId: clientId,
      },
      thenCallBack: (response) => {
        setClientActorList(response.data.data);
        setListAllActorsForClientLoader(false);
      },
      catchCallBack: () => {
        setListAllActorsForClientLoader(false);
      },
    });
  };

  const linkNewAccountToClient = () => {
    const actorIds = selectedActors.map((actor) => actor.actorId);
    setLinkNewAccountToClientLoader(true);
    useFetch(API_ENDPOINTS.LINK_NEW_ACCOUNT_TO_CLIENT.url, "POST", {
      failureMessage: API_ENDPOINTS.LINK_NEW_ACCOUNT_TO_CLIENT.failureMessage,
      showSuccessToast: true,
      data: {
        clientId: editClientId,
        clientType: clientType,
        actors: actorIds,
      },
      thenCallBack: () => {
        listAllActorsForClient(editClientId);
        setAddNewAccountForClientModal(false);
        setLinkNewAccountToClientLoader(false);
      },
      catchCallBack: (error) => {
        if (error.response.data?.alreadyMappedActorsList.length > 0) {
          setShowErrorCreateClient(true);
          setErrorList(error.response.data?.alreadyMappedActorsList);
        }
        setLinkNewAccountToClientLoader(false);
      },
    });
  };

  const removeAccountToClient = () => {
    setDeleteAccountForClientModalLoader(true);
    useFetch(API_ENDPOINTS.REMOVE_ACTOR_FROM_CLIENT.url, "POST", {
      failureMessage: API_ENDPOINTS.REMOVE_ACTOR_FROM_CLIENT.failureMessage,
      showSuccessToast: true,
      data: {
        actorId: selectedActorIdOfAccountForClient,
      },
      thenCallBack: () => {
        listAllActorsForClient(editClientId);
        setDeleteAccountForClientModal(false);
        setDeleteAccountForClientModalLoader(false);
      },
      catchCallBack: () => {
        setDeleteAccountForClientModalLoader(false);
      },
    });
  };

  return (
    <div>
      <div className="space_between">
        <div className="client-table-heading">Client Table</div>
        <div>
          <Button
            className="purple_btn"
            onClick={() => {
              setOpenCreateNewClientModal(true);
            }}
          >
            Create New Client
          </Button>
        </div>
      </div>
      <div className="client-table mt_16">
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tr>
            {columnsClientList.map((column, index) => (
              <th key={index} style={{ backgroundColor: "#D0D0D0" }}>
                {column}
              </th>
            ))}
          </tr>
          {clientList.map((client, index) => (
            <tr key={index}>
              <td>{client.clientId}</td>
              <td>
                <span
                  onClick={() => {
                    listAllActorsForClient(client.clientId);
                    setClientType(client.clientType);
                    setClientName(client.clientName);
                    setEditClientId(client.clientId);
                    setOpenViewClientModal(true);
                    // setViewClientId(client.clientId);
                  }}
                  style={{ cursor: "pointer", color: "#1976D2" }}
                >
                  {client.clientName}
                </span>
              </td>
              <td>{client.crmId}</td>
              <td>{client.clientType}</td>
              <td>{client.zsMonitorAccount ? "Yes" : "No"}</td>

              <td>
                <Button
                  variant="text"
                  style={{ padding: 0 }}
                  onClick={() => {
                    listAllActorsForClient(client.clientId);
                    setClientType(client.clientType);
                    setClientName(client.clientName);
                    setEditClientId(client.clientId);
                    setOpenViewClientModal(true);
                  }}
                >
                  View
                </Button>
                <Button
                  variant="text"
                  style={{ padding: 0 }}
                  onClick={() => {
                    setIsEditClient(true);
                    setOpenCreateNewClientModal(true);
                    setEditClientId(client.clientId);
                    setClientName(client.clientName);
                    setClientType(client.clientType);
                    setCrmId(client.crmId);
                    setEnableZapscaleTracking(client.zsMonitorAccount);
                  }}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openCreateNewClientModal}
        maxWidth="md"
        fullWidth
        sx={{ width: "598px", margin: "auto" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isEditClient ? "Edit Client" : "Create New Client"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseCreateNewClientModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: "16px 24px" }}>
          <Grid sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="create-client-label">Client Name*</label>
                <TextField
                  fullWidth
                  className="update-custom-border-radius"
                  variant="outlined"
                  placeholder="Enter Name"
                  value={clientName}
                  onChange={(e) => {
                    setClientName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="create-client-label">Client Type*</label>
                <TextField
                  fullWidth
                  className="update-custom-border-radius"
                  name="clientType"
                  select={true}
                  value={clientType}
                  onChange={(e) => setClientType(e.target.value)}
                  label="Select from dropdown"
                >
                  {Object.keys(ClientType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {ClientType[type]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <label className="create-client-label">CRM ID</label>
                <TextField
                  fullWidth
                  className="update-custom-border-radius"
                  variant="outlined"
                  placeholder="Enter Number"
                  value={crmId}
                  onChange={(e) => setCrmId(e.target.value)}
                />
              </Grid>
              {!isEditClient && (
                <Grid item xs={12}>
                  <label className="create-client-label">Add Accounts</label>
                  <Autocomplete
                    className="update-custom-border-radius"
                    id="country-select-demo"
                    multiple
                    sx={{ minWidth: 280 }}
                    options={listAllActors}
                    autoHighlight
                    value={selectedActors}
                    getOptionLabel={(option: any) => `${option.actorName} (Actor ID: ${option.actorId})`}
                    onChange={(_event, newValue) => {
                      setSelectedActors(newValue); // Store the selected option in state
                    }}
                    renderOption={(props, option) => (
                      <Box
                        key={`${option.actorId}-${option.actorName}`}
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                        style={{ display: "block" }}
                      >
                        <div>{option.actorName}</div>
                        <div>
                          <span style={{ color: "#666666", fontSize: "12px" }}>Actor ID: {option.actorId}</span>
                        </div>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search with Actor ID/Account Name"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableZapscaleTracking}
                      onChange={(e) => setEnableZapscaleTracking(e.target.checked)}
                    />
                  }
                  label="Enable tracking on Zapscale"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }} className="bg_footer">
          <Button
            className={clientName.trim() === "" || clientType.trim() === "" ? "grey_btn" : "purple_btn"}
            disabled={createNewClientModalLoader || clientName.trim() === "" || clientType.trim() === ""}
            style={{ width: "143px" }}
            startIcon={<LoadingIcon loading={createNewClientModalLoader} />}
            onClick={() => {
              isEditClient ? UpdateClientDetails() : addNewClient();
            }}
          >
            {isEditClient ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showErrorCreateClient}
        maxWidth="md"
        fullWidth
        sx={{ width: "598px", margin: "auto" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add account
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowErrorCreateClient(false);
            setErrorList([]);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: "16px 24px" }}>
          <Alert severity="error">
            <AlertTitle>
              The following account(s) cannot be added as it is already added under a different client. Remove the
              account(s) and try again.
            </AlertTitle>
            <ul style={{ margin: 0, paddingLeft: "20px", listStyleType: "disc" }}>
              {errorList.map((error, index) => (
                <li key={index}>
                  {error.actorName} linked with {error.clientName}
                </li>
              ))}
            </ul>
          </Alert>
        </DialogContent>
        <DialogActions sx={{ p: 2 }} className="bg_footer">
          <Button
            className="purple_btn"
            style={{ width: "143px" }}
            onClick={() => {
              setShowErrorCreateClient(false);
              setErrorList([]);
            }}
          >
            dismiss
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openViewClientModal}
        maxWidth="md"
        fullWidth
        sx={{ width: "1051px", margin: "auto" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          View Client
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenViewClientModal(false);
            setClientActorList([]);
            handleCloseCreateNewClientModal();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: "16px 24px" }}>
          <Grid sx={{ mb: 2 }}>
            <div className="space_between">
              <div className="client-table-heading">Client Name - {clientName}</div>
              <div>
                <Button
                  className="purple_btn"
                  onClick={() => {
                    setAddNewAccountForClientModal(true);
                  }}
                >
                  Add new account
                </Button>
              </div>
            </div>

            <div className="client-table mt_16">
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr>
                  {columnsClientActorList.map((column, index) => (
                    <th key={index} style={{ backgroundColor: "#D0D0D0" }}>
                      {column}
                    </th>
                  ))}
                </tr>

                {listAllActorsForClientLoader
                  ? // Show Skeleton rows when loading
                    Array.from({ length: 2 }).map((_, index) => (
                      <tr key={`skeleton-${index}`}>
                        {columnsClientActorList.map((_, colIndex) => (
                          <td key={`skeleton-col-${colIndex}`} style={{ padding: "10px" }}>
                            <div>
                              <Skeleton variant="text" animation="wave" />
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))
                  : // Show the actual data rows when not loading
                    clientActorList?.map((actor, index) => (
                      <tr key={index}>
                        <td>{actor.actorId}</td>
                        <td>{actor.actorName}</td>
                        <td>{actor.integration ? "Yes" : "No"}</td>
                        <td>{actor.actorNickname}</td>
                        <td>{actor.accountingSoftware}</td>
                        <td>
                          <Button
                            variant="text"
                            color="error"
                            style={{ padding: 0 }}
                            onClick={() => {
                              setSelectedActorIdOfAccountForClient(actor.actorId);
                              setDeleteAccountForClientModal(true);
                            }}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
              </table>
            </div>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={addNewAccountForClientModal}
        maxWidth="md"
        fullWidth
        sx={{ width: "598px", margin: "auto" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add account
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setAddNewAccountForClientModal(false);
            setSelectedActors([]);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: "16px 24px" }}>
          <Grid>
            <Alert severity="warning">
              When adding a new account, verify and adjust its client type to match the existing client.
            </Alert>
          </Grid>
          <Grid sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <label className="create-client-label">Account Name</label>
              <Autocomplete
                className="update-custom-border-radius"
                id="country-select-demo"
                multiple
                sx={{ minWidth: 280 }}
                options={listAllActors}
                autoHighlight
                value={selectedActors}
                getOptionLabel={(option: any) => `${option.actorName} (Actor ID: ${option.actorId})`}
                onChange={(_event, newValue) => {
                  setSelectedActors(newValue); // Store the selected option in state
                }}
                renderOption={(props, option) => (
                  <Box
                    key={`${option.actorId}-${option.actorName}`}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    style={{ display: "block" }}
                  >
                    <div>{option.actorName}</div>
                    <div>
                      <span style={{ color: "#666666", fontSize: "12px" }}>Actor ID: {option.actorId}</span>
                    </div>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search with Actor ID/Account Name"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }} className="bg_footer">
          <Button
            className={selectedActors.length < 1 ? "grey_btn" : "purple_btn"}
            disabled={selectedActors.length < 1 || linkNewAccountToClientLoader}
            startIcon={<LoadingIcon loading={linkNewAccountToClientLoader} />}
            style={{ width: "100px" }}
            onClick={() => {
              linkNewAccountToClient();
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={deleteAccountForClientModal}
        maxWidth="md"
        fullWidth
        sx={{ width: "598px", margin: "auto" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Are you sure?
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDeleteAccountForClientModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: "16px 24px" }}>
          <Alert severity="error">This account will be deleted and will not be recovered.</Alert>
        </DialogContent>
        <DialogActions sx={{ p: 2 }} className="bg_footer">
          <Button
            variant="text"
            onClick={() => {
              setDeleteAccountForClientModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="theme_btn theme_btn_warning"
            startIcon={<LoadingIcon loading={deleteAccountForClientModalLoader} />}
            disabled={deleteAccountForClientModalLoader}
            style={{ width: "143px" }}
            onClick={() => {
              removeAccountToClient();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Register;
