import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { Currency } from "dinero.js";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useLocation } from "react-router-dom";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import FormatDate from "src/Utils/DateUtils";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { uiLoggerBcPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import LendingTopBanner from "../Common/LendingTopBanner";
import PortalAccessDialog from "../Common/PortalAccessDialog";
import RaiseIssueSection from "../Common/RaiseIssueSection";
import { MailBox } from "../LedgerRequestPortal/LedgerRequestPortalCommon";
import { NdAsyncButton } from "../MsmePartnerPortal/CommonComponents";
import ErrorPage from "../MsmePartnerPortal/ErrorPage";
import { Header, LoadingPage, NavBar } from "../MsmePartnerPortal/PartnerPortal.common";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";
import {
  BcCustomisationDetails,
  BCPortalCBDetails,
  BcPortalDetails,
  CommunicationDetails,
  MailBoxPropsBc,
  StateDispatch,
} from "./BalanceConfirmationPortalCommon";
import { BcPortalContext } from "./BalanceConfirmationPortalContext";
import { ConfirmBalanceDialog } from "./BalanceConfirmDetailsDialog";
import DetailsPage from "./DetailsPage";
import FinalPage, { NameAndLogo, RaiseRequestDialog } from "./FinalPage";

export type PageStatus = "NotFilled" | "DetailsFilled" | "FinalPage";

const BalanceConfirmationPortal = () => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");
  const initialRaiseIssueDialogState = params.get("raiseIssue") === "1";

  const {
    confirmStatus,
    setConfirmStatus,
    pageStatus,
    setPageStatus,
    customization,
    setCustomization,
    setUploadedFiles,
    nameAndLogo,
    setNameAndLogo,
  } = useContext(BcPortalContext);

  // const [pageStatus, setPageStatus] = useState<PageStatus>("NotFilled");
  // const [confirmStatus, setConfirmStatus] = useState<boolean>(null);
  const [closingBalanceDate, setClosingBalanceDate] = useState<string>(null);
  const [closingBalance, setClosingBalance] = useState<string>(null);
  const [currency, setCurrency] = useState<string>("INR");

  const [emailData, setEmailData] = useState<MailBoxPropsBc>(null);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState(initialRaiseIssueDialogState);
  const [openPortalAccessDialog] = useState(false);

  const GetBcPortalStatus = () => {
    return useFetch<{ status: PageStatus; isBalanceConfirmed: boolean }>(
      API_ENDPOINTS.GET_PORTAL_DETAILS_STATUS.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.GET_PORTAL_DETAILS_STATUS.failureMessage,
        config: {
          params: {
            data: encryptedData,
          },
        },
        thenCallBack: (res) => {
          const { status, isBalanceConfirmed } = res.data;

          setPageStatus(status || "NotFilled");
          if (typeof isBalanceConfirmed === "boolean") {
            if (isBalanceConfirmed) setConfirmStatus(true);
            else setConfirmStatus(false);
          }
        },
      }
    );
  };

  const getEmailDetails = () => {
    return useFetch<{ emailDetails: MailBoxPropsBc }>(API_ENDPOINTS.GET_EMAIL_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_EMAIL_DETAILS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        setEmailData(res.data.emailDetails);
      },
    });
  };

  // For Customisation Details
  const GetCustomization = () =>
    useFetch<CommunicationDetails>(API_ENDPOINTS.GET_COMMUNICATION_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_COMMUNICATION_DETAILS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        console.log({ data: res.data });

        const details = res.data;
        const defaultCustomisation: BcCustomisationDetails = {
          isPdfRequired: null,
          isPdfOptional: null,
          isLedgerRequired: null,
          isOpenItemRequired: null,
          isPdfHidden: null,
          isLedgerHidden: null,
          isOpenItemHidden: null,
          isPartnerBalanceRequired: null,
          isPartnerBalanceHidden: null,
        };

        let customisationIfBalanceConfirmed: BcCustomisationDetails;
        let customisationIfBalanceNotConfirmed: BcCustomisationDetails;
        let customisationIfLedgerRequest: BcCustomisationDetails;

        if (details?.customisationIfConfirmed?.ledger) {
          customisationIfBalanceConfirmed = {
            isPdfRequired: details?.customisationIfConfirmed?.pdf === "yes",
            isPdfOptional: details?.customisationIfConfirmed?.pdf === "optional",
            isLedgerRequired: details?.customisationIfConfirmed?.ledger === "yes",
            isOpenItemRequired: details?.customisationIfConfirmed?.openItem === "yes",
            isPdfHidden: details?.customisationIfConfirmed?.pdf === "no",
            isLedgerHidden: details?.customisationIfConfirmed?.ledger === "no",
            isOpenItemHidden: details?.customisationIfConfirmed?.openItem === "no",
            isPartnerBalanceRequired: details?.customisationIfLedgerRequest?.partnerBalance === "yes",
            isPartnerBalanceHidden: details?.customisationIfLedgerRequest?.partnerBalance === "no",
          };
        }

        if (details?.customisationIfNotConfirmed?.ledger) {
          customisationIfBalanceNotConfirmed = {
            isPdfRequired: details?.customisationIfNotConfirmed?.pdf === "yes",
            isPdfOptional: details?.customisationIfNotConfirmed?.pdf === "optional",
            isLedgerRequired: details?.customisationIfNotConfirmed?.ledger === "yes",
            isOpenItemRequired: details?.customisationIfNotConfirmed?.openItem === "yes",
            isPdfHidden: details?.customisationIfNotConfirmed?.pdf === "no",
            isLedgerHidden: details?.customisationIfNotConfirmed?.ledger === "no",
            isOpenItemHidden: details?.customisationIfNotConfirmed?.openItem === "no",
            isPartnerBalanceRequired: details?.customisationIfLedgerRequest?.partnerBalance === "yes",
            isPartnerBalanceHidden: details?.customisationIfLedgerRequest?.partnerBalance === "no",
          };
        }

        if (details?.customisationIfLedgerRequest?.ledger) {
          customisationIfLedgerRequest = {
            isPdfRequired: false,
            isPdfOptional: false,
            isLedgerRequired: details?.customisationIfLedgerRequest?.ledger === "yes",
            isOpenItemRequired: details?.customisationIfLedgerRequest?.openItem === "yes",
            isPdfHidden: true,
            isLedgerHidden: details?.customisationIfLedgerRequest?.ledger === "no",
            isOpenItemHidden: details?.customisationIfLedgerRequest?.openItem === "no",
            isPartnerBalanceRequired: details?.customisationIfLedgerRequest?.partnerBalance === "yes",
            isPartnerBalanceHidden: details?.customisationIfLedgerRequest?.partnerBalance === "no",
          };
        }

        setCustomization({
          balanceConfirmed: customisationIfBalanceConfirmed || defaultCustomisation,
          balanceNotConfirmed: customisationIfBalanceNotConfirmed || defaultCustomisation,
          ledgerRequest: customisationIfLedgerRequest || defaultCustomisation,
        });
      },
    });

  const GetUploadedLedgers = () =>
    useFetch<{ details: BcPortalDetails }>(API_ENDPOINTS.GET_DETAILS_FOR_UPLOADING_PAGE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DETAILS_FOR_UPLOADING_PAGE.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const { details } = res.data;
        const { ledgers, confirmationDocument, openItems } = details;

        setUploadedFiles({
          ledgers: ledgers,
          confirmationDocument,
          openItems,
        });
      },
    });

  const getClosingBalanceDetails = () =>
    useFetch<{ closingBalanceDetails: BCPortalCBDetails }>(API_ENDPOINTS.GET_ClOSING_BALANCE_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ClOSING_BALANCE_DETAILS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const { closingBalanceDate, ownClosingBalance, currency } = res.data.closingBalanceDetails;

        if (Number.isFinite(ownClosingBalance)) setClosingBalance(ownClosingBalance.toString());
        if (closingBalanceDate) setClosingBalanceDate(closingBalanceDate.toString());
        if (currency) setCurrency(currency);
      },
    });

  const GetPartnerNameAndLogo = () =>
    useFetch<NameAndLogo>(API_ENDPOINTS.GET_PARTNER_NAME_AND_LOGO.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_PARTNER_NAME_AND_LOGO.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        setNameAndLogo(res.data);
      },
    });

  useEffect(() => {
    const clarityProjectIDs = {
      production: "inqdavk56u",
      staging: "inqcy754wj",
      test: "inq7p42w47",
    };
    clarity.init(clarityProjectIDs[import.meta.env.VITE_APP_NAKAD_ENV]);
  }, []);

  useEffect(() => {
    // GetCustomisationDetails();
    GetBcPortalStatus();
    getEmailDetails();
    getClosingBalanceDetails();
    GetUploadedLedgers();
    GetCustomization();
    GetPartnerNameAndLogo();
  }, []);

  useEffect(() => {
    const { name, logo, businessPartnerName, companyName, branchName } = nameAndLogo;

    if (name && emailData) setDataLoaded(true);

    if (name || logo || businessPartnerName || companyName || branchName) {
      setNameAndLogo({ name, logo, businessPartnerName, companyName, branchName });
      clarity.setTag("RU", `${name}`);
      clarity.setTag("RU-BP", `${name}-${businessPartnerName}`);
    }

    if (!dataLoaded) setPageStatus(pageStatus || "NotFilled");

    if (Number.isFinite(closingBalance)) setClosingBalance(closingBalance.toString());
    if (closingBalanceDate) setClosingBalanceDate(closingBalanceDate.toString());
    if (currency) setCurrency(currency);
  }, [pageStatus, emailData, closingBalance, closingBalanceDate, currency]);

  const headerTextBuilder = [nameAndLogo.name, nameAndLogo?.companyName || "", nameAndLogo?.branchName || ""];
  const headerTextCompanyInfo = headerTextBuilder.filter((x) => x).join(", ");

  const openDetailsDialog =
    pageStatus === "DetailsFilled" &&
    (confirmStatus === true
      ? customization.balanceConfirmed?.isPdfHidden === true
      : customization.balanceNotConfirmed?.isPdfHidden === true);

  console.log({ openDetailsDialog, customization, emailData });

  return (
    <>
      <ErrorPage>
        <Suspense fallback={<LoadingPage />}>
          {pageStatus === "FinalPage" && <LendingTopBanner />}
          <NavBar companyName={nameAndLogo.name} companyLogo={nameAndLogo.logo} />
          <Divider className={$.borderColor} />
          <Header
            headerText={`Confirmation of balance as on ${FormatDate(closingBalanceDate)} for ${headerTextCompanyInfo}`}
          />
          <Divider className={$.borderColor} />
          {/* <BalanceConfirmationPortalMain /> */}
          {(pageStatus === "NotFilled" && emailData) ||
          (pageStatus !== "FinalPage" && openDetailsDialog && emailData) ? (
            <InitialPage
              emailData={emailData}
              closingBalance={closingBalance}
              currency={currency}
              closingBalanceDate={closingBalanceDate}
              bpName={nameAndLogo.businessPartnerName}
              ruName={nameAndLogo.name}
              cbDate={closingBalanceDate}
              ruNameAndCompany={headerTextCompanyInfo}
              customization={customization}
              openDetailsDialog={openDetailsDialog}
              openRaiseIssueDialog={openDialog}
              setOpenRaiseIssueDialog={setOpenDialog}
              openPortalAccessDialog={openPortalAccessDialog}
              setOpenPortalAccessDialog={(_v) => null}
            />
          ) : pageStatus === "DetailsFilled" && !openDetailsDialog ? (
            <DetailsPage
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              bpName={nameAndLogo.businessPartnerName}
              ruName={nameAndLogo.name}
              customization={confirmStatus ? customization.balanceConfirmed : customization.balanceNotConfirmed}
              openPortalAccessDialog={openPortalAccessDialog}
              setOpenPortalAccessDialog={(_v) => null}
            />
          ) : pageStatus === "FinalPage" ? (
            <FinalPage
              ruName={nameAndLogo.name}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              customization={confirmStatus ? customization.balanceConfirmed : customization.balanceNotConfirmed}
            />
          ) : null}
        </Suspense>
      </ErrorPage>
    </>
  );
};

interface InitialPageProps {
  emailData: MailBoxPropsBc;
  closingBalance: string;
  closingBalanceDate: string;
  currency: string;
  bpName: string;
  ruName: string;
  cbDate: string;
  ruNameAndCompany: string;
  customization: { balanceConfirmed: BcCustomisationDetails; balanceNotConfirmed: BcCustomisationDetails };
  openDetailsDialog?: boolean;
  openRaiseIssueDialog: boolean;
  setOpenRaiseIssueDialog: StateDispatch<boolean>;
  openPortalAccessDialog: boolean;
  setOpenPortalAccessDialog: StateDispatch<boolean>;
}

const InitialPage = ({
  emailData,
  closingBalance,
  closingBalanceDate,
  currency,
  bpName,
  ruName,
  cbDate,
  ruNameAndCompany,
  customization,
  openDetailsDialog,
  openRaiseIssueDialog,
  setOpenRaiseIssueDialog,
  openPortalAccessDialog,
  setOpenPortalAccessDialog,
}: InitialPageProps) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");
  const [openDialog, setOpenDialog] = useState<boolean>(openDetailsDialog || false);
  const [updatingBCStatus, setUpdatingBCStatus] = useState(false);

  const { confirmStatus, setConfirmStatus, setPageStatus } = useContext(BcPortalContext);

  const UpdateBalanceConfirmationStatus = (confirmed: boolean) =>
    new Promise<boolean>((resolve, reject) => {
      useFetch(API_ENDPOINTS.UPDATE_BALANCE_CONFIRMATION_STATUS_PORTAL.url, "POST", {
        failureMessage: API_ENDPOINTS.UPDATE_BALANCE_CONFIRMATION_STATUS_PORTAL.failureMessage,
        data: {
          data: encryptedData,
          isBalanceConfirmed: confirmed,
        },
        thenCallBack: (_res) => {
          setUpdatingBCStatus(false);
          setConfirmStatus(confirmed);
          resolve(confirmed);
        },
        catchCallBack: () => {
          setUpdatingBCStatus(false);
          reject();
        },
        errorCallback: reject,
      });
    });

  return (
    <>
      <Box className="main_container" p={3}>
        <Stack
          className={$.BR_fix + " fade_in first_page_box"}
          p={3}
          maxWidth={900}
          mx={"auto"}
          gap={5}
          border={"1px solid #d7d7d7"}
          sx={{ overflow: "auto", maxHeight: "63vh" }}
        >
          <Stack className="cb_box" gap={2}>
            <Typography variant="h6">
              Closing Balance for {bpName} in {ruName}’s ledger
            </Typography>
            <Box className="d_flex" gap={2}>
              <Box
                className={$.BR_fix + " vertical_center_align cb_date_card"}
                bgcolor="#eee8ed"
                px={"12px"}
                gap={3}
                py={1}
              >
                <Box>
                  <Typography variant="overline">Closing Balance</Typography>
                  <Box className="vertical_center_align" gap={1}>
                    <Chip
                      label={Number(closingBalance) > 0 ? "Receivable" : "Payable"}
                      color="default"
                      size="small"
                      variant="filled"
                      sx={{ "& > *": { fontFamily: "inherit" } }}
                    />
                    <Typography variant="subtitle1" fontWeight={600}>
                      {closingBalance === null
                        ? null
                        : formatMoney(ToDineroObj(Number(closingBalance), currency as Currency))}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="overline">as on</Typography>
                <Box>
                  <Typography variant="overline">Closing Balance Date</Typography>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {FormatDate(closingBalanceDate).toString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
          <Stack className="mail_box" gap={2}>
            <Typography variant="h6" fontWeight={600}>
              Reference of the email sent to you by {emailData?.from}
            </Typography>
            <MailBox subject={emailData.subject} body={emailData.body} from={emailData.from} time={emailData.time} />
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.08)",
          background: "#fff",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "900px",
            marginX: "auto",
            padding: "24px",
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <NdAsyncButton
              variant="contained"
              size="large"
              sx={{ minWidth: 145 }}
              onClick={async () => {
                await UpdateBalanceConfirmationStatus(true);

                if (customization?.balanceConfirmed?.isPdfRequired || customization?.balanceConfirmed?.isPdfOptional)
                  setPageStatus("DetailsFilled");
                else setOpenDialog(true);
                uiLoggerPortal(uiLoggerBcPortal.ui_PpBcConfirmClick, encryptedData);
              }}
              disabled={updatingBCStatus}
            >
              Confirm Balance
            </NdAsyncButton>
            <NdAsyncButton
              variant="contained"
              size="large"
              sx={{ minWidth: 145 }}
              onClick={async () => {
                await UpdateBalanceConfirmationStatus(false);
                if (
                  customization?.balanceNotConfirmed?.isPdfRequired ||
                  customization?.balanceNotConfirmed?.isPdfOptional
                )
                  setPageStatus("DetailsFilled");
                else setOpenDialog(true);
                uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNotConfirmClick, encryptedData);
              }}
              disabled={updatingBCStatus}
            >
              <LoadingIcon loading={updatingBCStatus} />
              Not Confirm Balance
            </NdAsyncButton>
          </Stack>
          <RaiseIssueSection setOpenDialog={setOpenRaiseIssueDialog} />
        </Stack>
      </Box>
      <ConfirmBalanceDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        cbDate={cbDate}
        ruNameAndCompany={ruNameAndCompany}
        ruName={ruName}
        bpName={bpName}
        customization={confirmStatus ? customization.balanceConfirmed : customization.balanceNotConfirmed}
        UpdateBalanceConfirmationStatus={UpdateBalanceConfirmationStatus}
      />
      <RaiseRequestDialog openDialog={openRaiseIssueDialog} setOpenDialog={setOpenRaiseIssueDialog} />
      <PortalAccessDialog
        openPortalAccessDialog={openPortalAccessDialog}
        setOpenPortalAccessDialog={setOpenPortalAccessDialog}
      />
    </>
  );
};

export default BalanceConfirmationPortal;
