import LayersIcon from "@mui/icons-material/Layers";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

export default function TabsHeader({ tabValue, setTabValue }) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1 }}>
      <Tabs
        value={tabValue}
        onChange={(e, val) => {
          e.preventDefault();
          setTabValue(val);
        }}
        sx={{
          height: "60px",
          ".MuiTabs-indicator": {
            backgroundColor: "#531c4c",
          },
        }}
      >
        <Tab
          label="Funnel Reports"
          icon={<LayersIcon />}
          iconPosition="start"
          sx={{
            py: 0,
            "&.Mui-selected": {
              color: "#531c4c",
            },
          }}
        />
        <Tab
          label="Status Reports"
          icon={<TableRowsIcon />}
          iconPosition="start"
          sx={{
            py: 0,
            "&.Mui-selected": {
              color: "#531c4c",
            },
          }}
        />
      </Tabs>
    </Box>
  );
}
