import { Box, Skeleton } from "@mui/material";
import React from "react";

const AddFunnelReportLoading: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 2,
        width: "90vw",
        height: "75vh",
        bgcolor: "background.paper",
        borderRadius: 1,
        marginTop: 2,
      }}
    >
      <Box sx={{ padding: 2, width: "80%" }}>
        <Skeleton variant="text" width="80%" height={70} />
        <Skeleton variant="text" width="60%" height={40} sx={{ my: 2 }} />
        <Skeleton variant="text" width="40%" height={60} sx={{ my: 2 }} />
        <Skeleton variant="text" width="70%" height={60} sx={{ mt: 4 }} />
      </Box>
      <Box sx={{ padding: 2, width: "20%" }}>
        <Skeleton variant="text" width="100%" height={70} sx={{ mb: 2 }} />
        <Skeleton variant="text" width="50%" height={60} sx={{ mb: 2 }} />
        <Skeleton variant="text" width="60%" height={60} sx={{ mb: 2 }} />
        <Skeleton variant="text" width="80%" height={50} sx={{ mb: 2 }} />
        <Skeleton variant="text" width="50%" height={70} sx={{ mb: 2 }} />
        <Skeleton variant="text" width="90%" height={60} sx={{ mt: 4 }} />
      </Box>
    </Box>
  );
};

export default AddFunnelReportLoading;
