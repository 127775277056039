import { Close, Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CellProps } from "react-table";
import useFetch from "src/Components/Common/useFetch";
import { UserRole, UserTypes } from "src/entity/models/FrontendActor";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import LoadingIcon from "../Common/LoadingIcon";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import { ColumnFilter } from "../ReactTable/ColumnFilter";
import Table, { ColumnDefs } from "../ReactTable/Table";
import { ListAllActorData, ListAllUserData } from "./AdminInterfaces";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const getBlankData = (count = 20) => {
  const dataArray: Partial<ListAllActorData>[] = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      actorId: " ",
      userName: " ",
      email: " ",
      actorType: " ",
      actorInfo: {
        name: " ",
      },
      data: {},
      integration: true,
      tallyCompanies: [""],
    } as Partial<ListAllActorData>);
  }
  return dataArray;
};
const getBlankUserData = (count = 20) => {
  const dataArray: Partial<ListAllUserData>[] = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      id: " ",
      name: " ",
      email: " ",
      type: " ",
      accountingSoftware: " ",
      mfaEnabled: false,
      integration: true,
    } as Partial<ListAllUserData>);
  }
  return dataArray;
};

const Userlist = () => {
  const [loader, setLoader] = useState(true);
  const [rowsData, setRowsData] = useState(getBlankData());
  const [selectedRow, setSelectedRow] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [openUserListOfAccount, setOpenUserListOfAccount] = useState<boolean>(false);
  const [clickedAccountDetails, setClickedAccountDetails] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [loaderUsersData, setLoaderUsersData] = useState(true);
  const [openRemoveUserModal, setOpenRemoveUserModal] = useState(false);
  const [removeUserLoader, setRemoveUserLoader] = useState(false);
  const [clickedUserDetails, setClickedUserDetails] = useState(null);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [editUserDetailsLoader, setEditUserDetailsLoader] = useState(false);

  const handleClose = () => setDeleteOpen(false);

  const changeHandleToggle = (checked: boolean, _props: CellProps<any>) => {
    return checked
      ? activateMFA(_props.row.original.actorId, _props.row.original)
      : deactivateMFA(_props.row.original.actorId, _props.row.original);
  };

  const columnDefinition = React.useMemo(
    (): ColumnDefs[] => [
      {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: (props) => (
          <React.Fragment key={props?.columns[0]?.id}>{/* Select All Button Commented */}</React.Fragment>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: (props) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />
          </div>
        ),
        width: 50,
        Sort: false,
      },
      {
        Header: "Account Id",
        accessor: "actorId",
        Filter: ColumnFilter,
        Sort: true,
        width: 120,
      },
      {
        Header: "Account Name",
        accessor: "actorName",
        Filter: ColumnFilter,
        sticky: "left",
        Sort: true,
        width: 200,
        Cell: ({ row }: any) => (
          <div>
            <a
              onClick={() => {
                getListAllUsers(row.original.actorId);
                setClickedAccountDetails(row.original);
                setOpenUserListOfAccount(true);
              }}
            >
              {row.original.actorName}
            </a>
          </div>
        ),
      },
      {
        Header: "Client Id",
        accessor: "clientId",
        Filter: ColumnFilter,
        Sort: true,
        width: 120,
      },
      {
        Header: "User Email",
        accessor: "userEmail",
        Filter: ColumnFilter,
        sticky: "left",
        Sort: true,
        width: 250,
      },
      {
        Header: "User Name",
        accessor: "userName",
        Filter: ColumnFilter,
        Sort: true,
        width: 250,
      },
      {
        Header: "MFA",
        id: "mfa",
        accessor: "mfaEnabled",
        Cell: (_props: CellProps<ListAllActorData>) => (
          <div>
            {_props.row.original.userType === "Admin" && (
              <Switch
                size="small"
                checked={_props.row.original.mfaEnabled}
                value={_props.row.original.mfaEnabled}
                onChange={(_e) => changeHandleToggle(_e.target.checked, _props)}
              />
            )}
          </div>
        ),
        Sort: false,
        width: 70,
      },
      {
        Header: "Re-Trigger MFA",
        id: "reTriggerMfa",
        accessor: "username",
        Cell: (_props: CellProps<ListAllActorData>) => (
          <div>
            <Button
              size="small"
              className="theme_btn"
              sx={{ fontSize: "12px !important" }}
              onClick={() => ReAuthenticateUserMFA(_props.row.original.userId)}
            >
              Re-Authenticate
            </Button>
          </div>
        ),
        Sort: false,
        width: 150,
      },
      {
        Header: "Integration",
        accessor: "integration",
        Sort: true,
        width: 150,
        Cell: ({ cell: { value } }: any) => <span>{value.toString()}</span>,
      },
      {
        Header: "Accounting Software",
        accessor: "accountingSoftware",
        Filter: ColumnFilter,
        Sort: true,
        width: 200,
      },
      {
        Header: "Tally Companies",
        accessor: "tallyCompanies",
        Filter: ColumnFilter,
        Sort: true,
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        Cell: ({ cell: { value } }: any) => <span>{value.toString()}</span>,
      },
    ],
    []
  );

  const columnDefinitionUserData = React.useMemo(
    (): ColumnDefs[] => [
      {
        Header: "User Id",
        accessor: "id",
        Filter: ColumnFilter,
        Sort: true,
        width: 120,
      },
      {
        Header: "User Name",
        accessor: "name",
        Filter: ColumnFilter,
        sticky: "left",
        Sort: true,
        width: 200,
      },
      {
        Header: "User Email",
        accessor: "email",
        Filter: ColumnFilter,
        sticky: "left",
        Sort: true,
        width: 250,
      },

      {
        Header: "User Type",
        accessor: "type",
        Filter: ColumnFilter,
        Sort: true,
        width: 200,
      },
      {
        Header: "User Role",
        accessor: "role",
        Filter: ColumnFilter,
        Sort: true,
        width: 200,
      },
      {
        Header: "Integration",
        accessor: "integration",
        Sort: true,
        width: 150,
        Cell: ({ cell: { value } }: any) => <span>{value.toString()}</span>,
      },
      {
        Header: "Accounting Software",
        accessor: "accountingSoftware",
        Filter: ColumnFilter,
        Sort: true,
        width: 200,
      },
      {
        Header: "Action",
        accessor: "action",
        Sort: true,
        width: 150,
        Cell: ({ row }: any) => (
          <span>
            <EditIcon
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                setClickedUserDetails(row.original);
                setUserName(row.original.name);
                setUserType(row.original.type);
                setUserRole(row.original.role);

                setOpenEditUserModal(true);
              }}
            />{" "}
            <DeleteIcon
              color="error"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setClickedUserDetails(row.original);
                setOpenRemoveUserModal(true);
              }}
            />
          </span>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getAllActorsList();
  }, []);

  const getAllActorsList = async () => {
    await useFetch<{ data: ListAllActorData[] }>(API_ENDPOINTS.LIST_ALL_ACTORS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_ACTORS.failureMessage,
      thenCallBack: (response) => {
        setRowsData(response.data.data);
        setLoader(false);
      },
    });
  };
  const getListAllUsers = async (actorId) => {
    setLoaderUsersData(true);
    setUsersData(getBlankUserData());
    await useFetch<{ data: ListAllUserData[] }>(API_ENDPOINTS.LIST_ALL_USERS.url, "POST", {
      failureMessage: API_ENDPOINTS.LIST_ALL_USERS.failureMessage,
      data: { actorId },
      thenCallBack: (response) => {
        setUsersData(response.data.data);
        setLoaderUsersData(false);
      },
    });
  };

  const deleteSelectedRow = async () => {
    await useFetch(API_ENDPOINTS.DELETE_ACTORS.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_ACTORS.failureMessage,
      showSuccessToast: true,
      config: {
        data: {
          actors: selectedRow,
        },
      },
      thenCallBack: () => {
        getAllActorsList();
      },
    });
  };

  const activateMFA = (_actorId: number, prop: { mfaEnabled: boolean }) => {
    useFetch<{ data: { mfaEnabled: boolean } }>(API_ENDPOINTS.CREATE_MFA_MAPPING_FOR_ACTOR.url, "POST", {
      failureMessage: API_ENDPOINTS.CREATE_MFA_MAPPING_FOR_ACTOR.failureMessage,
      showSuccessToast: true,
      data: {
        actorId: _actorId,
      },
      thenCallBack: (res) => {
        prop.mfaEnabled = res.data.data.mfaEnabled;
      },
    });
  };

  const deactivateMFA = (_actorId: number, prop: { mfaEnabled: boolean }) => {
    useFetch<{ data: { mfaEnabled: boolean } }>(API_ENDPOINTS.DELETE_MFA_MAPPING_FOR_ACTOR.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_MFA_MAPPING_FOR_ACTOR.failureMessage,
      showSuccessToast: true,
      data: {
        actorId: _actorId,
      },
      thenCallBack: (res) => {
        prop.mfaEnabled = res.data.data.mfaEnabled;
      },
    });
  };

  const ReAuthenticateUserMFA = (_userId: number) => {
    useFetch<unknown>(API_ENDPOINTS.REAUTHENTICATE_USER_2FA.url, "POST", {
      failureMessage: API_ENDPOINTS.REAUTHENTICATE_USER_2FA.failureMessage,
      showSuccessToast: true,
      data: {
        userId: _userId,
      },
      thenCallBack: (_res) => {},
    });
  };

  const deleteActorUserMapping = () => {
    setRemoveUserLoader(true);
    useFetch(API_ENDPOINTS.DELETE_ACTOR_USER_MAPPING.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_ACTOR_USER_MAPPING.failureMessage,
      showSuccessToast: true,
      config: {
        data: {
          actorId: clickedAccountDetails.actorId,
          userId: clickedUserDetails.id,
        },
      },
      thenCallBack: (_response) => {
        getListAllUsers(clickedAccountDetails.actorId);
        setOpenRemoveUserModal(false);
        setRemoveUserLoader(false);
      },
      catchCallBack: () => {
        setRemoveUserLoader(false);
      },
    });
  };

  const editUserDetails = () => {
    setEditUserDetailsLoader(true);
    useFetch(API_ENDPOINTS.EDIT_USER_DETAILS.url, "POST", {
      failureMessage: API_ENDPOINTS.EDIT_USER_DETAILS.failureMessage,
      showSuccessToast: true,
      data: {
        actorId: clickedAccountDetails.actorId,
        userId: clickedUserDetails.id,
        userName: userName,
        userType: userType,
        userRole: userRole,
      },
      thenCallBack: (_response) => {
        getListAllUsers(clickedAccountDetails.actorId);
        setOpenEditUserModal(false);
        setEditUserDetailsLoader(false);
      },
      catchCallBack: () => {
        setEditUserDetailsLoader(false);
      },
    });
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Userlist")}>
      {columnDefinition && (
        <Grid className="table_list" sx={{ pb: 0 }}>
          <Grid className="userlisttable" sx={{ height: "78vh" }}>
            <Table<Partial<ListAllActorData>>
              columns={columnDefinition}
              rows={rowsData}
              sortEnable={true}
              setSelectedRow={setSelectedRow}
              actorType="admin"
              showPagination={true}
              loading={loader}
            />
          </Grid>
          <Grid className="center_align">
            {import.meta.env.VITE_APP_NAKAD_ENV !== "production" && (
              <Button
                variant="contained"
                startIcon={<Delete />}
                style={{
                  marginTop: "10px",
                }}
                onClick={() => setDeleteOpen(true)}
                color="warning"
              >
                Delete
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={deleteOpen}
        handleClose={handleClose}
        selectedRows={selectedRow}
        deleteRows={deleteSelectedRow}
      />
      {openUserListOfAccount && (
        <Dialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openUserListOfAccount}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {clickedAccountDetails.actorName}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenUserListOfAccount(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {" "}
            <CloseIcon />
          </IconButton>
          <DialogContent dividers sx={{ p: 0 }}>
            <Grid className="userlisttable" sx={{ height: "78vh" }}>
              <Table<Partial<ListAllActorData>>
                columns={columnDefinitionUserData}
                rows={usersData}
                sortEnable={true}
                // setSelectedRow={setSelectedRow}
                // actorType="admin"
                showPagination={true}
                loading={loaderUsersData}
              />
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {openRemoveUserModal && (
        <Dialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openRemoveUserModal}
          maxWidth="md"
          fullWidth
          sx={{ width: "598px", margin: "auto" }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenRemoveUserModal(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {" "}
            <CloseIcon />
          </IconButton>
          <DialogContent dividers sx={{ p: "16px 24px" }}>
            <Alert severity="error">
              This user will be removed from the account {clickedAccountDetails?.actorName} and will not be recovered.
            </Alert>
          </DialogContent>
          <DialogActions sx={{ p: 2 }} className="bg_footer">
            <Button
              variant="text"
              onClick={() => {
                setOpenRemoveUserModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="theme_btn theme_btn_warning"
              startIcon={<LoadingIcon loading={removeUserLoader} />}
              disabled={removeUserLoader}
              style={{ width: "143px" }}
              onClick={() => {
                deleteActorUserMapping();
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openEditUserModal && (
        <Dialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openEditUserModal}
          maxWidth="md"
          fullWidth
          sx={{ width: "598px", margin: "auto" }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Edit details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenEditUserModal(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {" "}
            <CloseIcon />
          </IconButton>
          <DialogContent dividers sx={{ p: "16px 24px" }}>
            <Grid sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label className="create-client-label">User Name</label>
                  <TextField
                    fullWidth
                    className="update-custom-border-radius"
                    variant="outlined"
                    placeholder="Enter Name"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value.trim());
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <label className="create-client-label">User Type</label>
                  <TextField
                    fullWidth
                    className="update-custom-border-radius"
                    name="userType"
                    select={true}
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                    label="Select from dropdown"
                  >
                    {Object.keys(UserTypes).map((type) => (
                      <MenuItem key={type} value={type}>
                        {UserTypes[type]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <label className="create-client-label">User Role</label>
                  <TextField
                    fullWidth
                    className="update-custom-border-radius"
                    name="userRole"
                    select={true}
                    value={userRole}
                    onChange={(e) => setUserRole(e.target.value)}
                    label="Select from dropdown"
                  >
                    {Object.keys(UserRole).map((type) => (
                      <MenuItem key={type} value={type}>
                        {UserRole[type]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2 }} className="bg_footer">
            <Button
              className={userName.trim() === "" || userType === null || userRole === null ? "grey_btn" : "purple_btn"}
              disabled={editUserDetailsLoader || userName.trim() === "" || userType === null || userRole === null}
              style={{ width: "143px" }}
              startIcon={<LoadingIcon loading={editUserDetailsLoader} />}
              onClick={() => {
                editUserDetails();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </LoggedInSkeleton>
  );
};

interface BasicDialogProp {
  open: boolean;
  handleClose: () => void;
}
interface DialogProp extends BasicDialogProp {
  selectedRows: ListAllActorData[];
  deleteRows: () => void;
}

const DeleteDialog = ({ open, handleClose, selectedRows, deleteRows }: DialogProp) => {
  const [queVals, setQueVals] = useState([0]);
  const [ansVals, setAnsVals] = useState([{ correct: false, value: 0 }]);
  const [answer, setAnswer] = useState(0);
  const [option, setOption] = useState(0);

  const RandomNumber = (start = 0, end = 10) => Math.floor(Math.random() * end + start);
  const genQnA = () => {
    const queVal1 = RandomNumber(1);
    const queVal2 = RandomNumber(2);
    const correctAns = queVal1 * queVal2;
    const incorrectAns1 = RandomNumber(0) * queVal2 + 1;
    const incorrectAns2 = RandomNumber(1) * queVal2 + 2;

    setAnswer(correctAns);
    setQueVals([queVal1, queVal2]);
    setAnsVals([
      { correct: true, value: correctAns },
      { correct: false, value: incorrectAns1 },
      { correct: false, value: incorrectAns2 },
    ]);
  };

  React.useEffect(() => {
    if (open) genQnA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDelete = () => {
    deleteRows();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle sx={{ px: 1 }} className="dialog_header">
        <Typography color={"orangered"} variant="h6">
          Delete The Following Actors?{" "}
        </Typography>
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ position: "relative", pt: "32px !important" }}>
        <Typography color={"orange"} variant="caption" sx={{ position: "absolute", top: 4, right: 8 }}>
          Note: This Action Can't be Undone!
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <FormLabel>The Following {selectedRows.length ? selectedRows.length : ""} Actors will be deleted:</FormLabel>
          {selectedRows.length ? (
            <Typography variant="body2">
              {selectedRows.map((row, _i) =>
                row?.userName?.toLowerCase().includes("admin") ? (
                  <React.Fragment key={row.userName + _i}>
                    <span className="bold">{row.userName}</span>
                    <>, </>
                  </React.Fragment>
                ) : (
                  row?.userName + ", "
                )
              )}
            </Typography>
          ) : (
            <Typography variant="caption" fontStyle="italic">
              No Actors Selected
            </Typography>
          )}
          <Box>
            <FormControl sx={{ flexDirection: "row", gap: 2, alignItems: "center" }}>
              <FormLabel id="select-answer">
                What's {queVals[0]} x {queVals[1]} ?
              </FormLabel>
              <RadioGroup row={true} aria-labelledby="select-answer" name="captcha">
                {ansVals
                  .sort((a, b) => a.value - b.value)
                  .map((val) => (
                    <FormControlLabel
                      key={val.value}
                      value={val.value}
                      control={<Radio size="small" />}
                      label={val.value}
                      onChange={() => setOption(val.value)}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error" disabled={option !== answer || selectedRows?.length < 1}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Userlist;
