export const dynamicContentOptions = [
  "{BP NAME}",
  // "{BP USER NAME}",
  "{CLOSING BALANCE}",
  "{CLOSING BALANCE DATE}",
  "{CURRENT DATE}",
  "{OWN LEDGER RECON START DATE}",
  "{OWN LEDGER RECON END DATE}",
  "{OWN LEDGER OLDEST ENTRY}",
  "{OWN LEDGER LATEST ENTRY}",
  "{BP LEDGER RECON START DATE}",
  "{BP LEDGER RECON END DATE}",
  "{BP LEDGER OLDEST ENTRY}",
  "{BP LEDGER LATEST ENTRY}",
  "{RECON AMOUNT MISMATCH}",
  "{OWN LEDGER EXTRA/UNMATCHED ENTRIES}",
  "{ENTRIES UNACCOUNTED IN OWN LEDGER}",
  "{STARTING OF THE CURRENT FY}",
  "{PREVIOUS MONTH'S LAST DATE}",
  "{UNPAID INVOICES}",
  "{LOCATION}",
  "{PAYMENT DETAILS}",
  "{PARTNER USER NAME}",
  "{VENDOR CODE}",
];

export const dynamicContentOptionsForMsme = [`{BP NAME}`, `{LOCATION}`, `{VENDOR CODE}`];

export const dynamicContentOptionsBeta = [
  "{BP NAME}",
  // "{BP USER NAME}",
  "{CLOSING BALANCE}",
  "{CLOSING BALANCE DATE}",
  "{CURRENT DATE}",
  // "{OWN LEDGER RECON START DATE}",
  // "{OWN LEDGER RECON END DATE}",
  // "{OWN LEDGER OLDEST ENTRY}",
  // "{OWN LEDGER LATEST ENTRY}",
  // "{BP LEDGER RECON START DATE}",
  // "{BP LEDGER RECON END DATE}",
  // "{BP LEDGER OLDEST ENTRY}",
  // "{BP LEDGER LATEST ENTRY}",
  // "{RECON AMOUNT MISMATCH}",
  // "{OWN LEDGER EXTRA/UNMATCHED ENTRIES}",
  // "{ENTRIES UNACCOUNTED IN OWN LEDGER}",
  "{STARTING OF THE CURRENT FY}",
  "{PREVIOUS MONTH'S LAST DATE}",
  "{UNPAID INVOICES}",
  "{LOCATION}",
  "{PAYMENT DETAILS}",
  "{PARTNER USER NAME}",
  "{VENDOR CODE}",
  "{PORTAL LINK}",
  "{REPLY BACK}",
  "{EDIT LINK}",
];

export const dynamicContentOptionsDR = [
  "{BP NAME}",
  "{CLOSING BALANCE}",
  "{CLOSING BALANCE DATE}",
  "{CURRENT DATE}",
  "{STARTING OF THE CURRENT FY}",
  "{PREVIOUS MONTH'S LAST DATE}",
  "{UNPAID INVOICES}",
  "{LOCATION}",
  "{PAYMENT DETAILS}",
  "{PARTNER USER NAME}",
  "{VENDOR CODE}",
  "{REPLY BACK}",
];

export enum LedgerRequestStatuses {
  RequestLedger = "Request ledger",
  LedgerRequested = "Ledger requested",
  CheckPartnerReply = "Check partner reply",
  ReminderSent = "Reminder sent",
  SendReminder = "Send Reminder",
  ConfigUnderway = "Configuration Underway",
  ReconShared = "Recon shared with Partner",
  DownloadLedger = "Download ledger",
}

export enum BalanceConfirmationStatuses {
  RequestBalanceConfirmation = "Request balance confirmation",
  BalanceConfirmationRequested = "Balance confirmation requested",
  CheckPartnerReply = "Check partner reply",
  ReminderSent = "Reminder sent",
  SendReminder = "Send Reminder",
  ConfigUnderway = "Configuration Underway",
  ReconShared = "Recon shared with Partner",
  DownloadLedger = "Download ledger",
  BalanceConfirmed = "Balance confirmed",
}

export enum MsmeConfirmationStatuses {
  RequestMsmeConfirmation = "Email Not Sent",
  MsmeConfirmationRequested = "Email Sent",
  MsmeDocPending = "Pending Submission",
  MsmeDocFurnished = "MSME - Yes",
  NotMsme = "MSME - No",
  IssueRaised = "Issue Raised",
}

type colorMap = { color?: string; bgColor?: string };
type OtherFormat = string & NonNullable<unknown>;

export const MsmeColorMap: Record<MsmeConfirmationStatuses | OtherFormat, colorMap> = {
  [MsmeConfirmationStatuses.RequestMsmeConfirmation]: {
    color: "#000",
    bgColor: "#D6D7D6",
  },
  [MsmeConfirmationStatuses.MsmeConfirmationRequested]: {
    color: "#000",
    bgColor: "#E5A0DB",
  },
  [MsmeConfirmationStatuses.MsmeDocPending]: {
    color: "#000",
    bgColor: "#DFAE39",
  },
  [MsmeConfirmationStatuses.MsmeDocFurnished]: {
    color: "#000",
    bgColor: "#10C880",
  },
  [MsmeConfirmationStatuses.NotMsme]: {
    color: "#000",
    bgColor: "#FE8863",
  },
  [MsmeConfirmationStatuses.IssueRaised]: {
    color: "#000",
    bgColor: "#6CEFFF",
  },
};

export enum uploadFileType {
  signedPdf = "signedPdf",
  udyamCertificate = "udyamCertificate",
}

export enum BalanceConfirmationBetaStatuses {
  RequestBalanceConfirmation = "Email Not Sent",
  BalanceConfirmationRequested = "Email Sent",
  ConfirmedDocPending = "Pending Submission",
  ConfirmedDocSubmitted = "Balance Confirmed",
  NotConfirmDocPending = "Pending Submission",
  NotConfirmDocSubmitted = "Balance Not Confirmed",
  ReminderSent = "Reminder Sent",
  IssueRaised = "Issue Raised",
  // Ledger Statuses
  LedgerReceived = "Ledger Received",
}

export const BalanceConfirmationBetaColorMap: Record<BalanceConfirmationBetaStatuses | OtherFormat, colorMap> = {
  [BalanceConfirmationBetaStatuses.RequestBalanceConfirmation]: {
    color: "#000",
    bgColor: "#D6D7D6",
  },
  [BalanceConfirmationBetaStatuses.BalanceConfirmationRequested]: {
    color: "#000",
    bgColor: "#E5A0DB",
  },
  [BalanceConfirmationBetaStatuses.ConfirmedDocPending]: {
    color: "#000",
    bgColor: "#DFAE39",
  },
  [BalanceConfirmationBetaStatuses.ConfirmedDocSubmitted]: {
    color: "#000",
    bgColor: "#11C881",
  },
  [BalanceConfirmationBetaStatuses.NotConfirmDocSubmitted]: {
    color: "#000",
    bgColor: "#FF8862",
  },
  [BalanceConfirmationBetaStatuses.ReminderSent]: {
    color: "#000",
    bgColor: "#2096F2",
  },
  [BalanceConfirmationBetaStatuses.IssueRaised]: {
    color: "#000",
    bgColor: "#6CEFFF",
  },
  // Ledger Statuses
  [BalanceConfirmationBetaStatuses.LedgerReceived]: {
    color: "#000",
    bgColor: "#11C881",
  },
};

export enum LedgerRequestBetaStatuses {
  RequestLedgerConfirmation = "Email Not Sent",
  LedgerRequested = "Email Sent",
  ReminderSent = "Reminder Sent",
  LedgerReceived = "Ledger Received",
  IssueRaised = "Issue Raised",
}

export const LedgerRequestBetaColorMap: Record<LedgerRequestBetaStatuses | OtherFormat, colorMap> = {
  [LedgerRequestBetaStatuses.RequestLedgerConfirmation]: {
    color: "#000",
    bgColor: "#D7D7D7",
  },
  [LedgerRequestBetaStatuses.LedgerRequested]: {
    color: "#000",
    bgColor: "#E5A0DB",
  },
  [LedgerRequestBetaStatuses.ReminderSent]: {
    color: "#000",
    bgColor: "#9BC1FF",
  },
  [LedgerRequestBetaStatuses.LedgerReceived]: {
    color: "#000",
    bgColor: "#11C881",
  },
  [LedgerRequestBetaStatuses.IssueRaised]: {
    color: "#000",
    bgColor: "#6CEFFF",
  },
};
