import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { TicketDetails } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import delete_icon_grey from "../../../Graphics/AutomatedRecon/delete_icon_grey.svg";
import delete_icon_red from "../../../Graphics/AutomatedRecon/delete_icon_red.svg";
import { Recon360Context, StateDispatch } from "../Recon360";
import AddBulkTasksModal from "./AddBulkTasksModal";
import ClearBulkTasksConfirmationModal from "./ClearBulkTasksConfirmationModal";
import DeselectInvalidTasksModal from "./Comman/DeselectInvalidTasksModal";
import DiscardBulkTasksConfirmationModal from "./DiscardBulkTasksConfirmationModal";
import SaveBulkTasksConfirmationModal from "./SaveBulkTasksConfirmationModal";
import UpdateBulkTasksModal from "./UpdateBulkTasksModal";
import { TicketMetaStatuses } from "./ViewTickets";

interface bulkTasksModalProps {
  showBulkTasksModal: boolean;
  setShowBulkTasksModal: StateDispatch<boolean>;
  selectedTasks: TicketDetails[];
  allUsers: { name: string; id: number }[];
  allStatuses: string[];
  fetchTickets: any;
  setClearRows: StateDispatch<boolean>;
}
const deselectTasksModalTitleType = ["Save", "Discard", "Clear", "Update"];
const deselectTasksModalInvalidTaskType = ["Saved", "Saved", "in-progress", "Saved"];

export default function BulkTasksModal({
  showBulkTasksModal,
  setShowBulkTasksModal,
  selectedTasks,
  allUsers,
  allStatuses,
  fetchTickets,
  setClearRows,
}: bulkTasksModalProps) {
  const { getAllTaskLabels } = useContext(Recon360Context);
  const [showAddBulkTasksModal, setShowAddBulkTasksModal] = useState(false);
  const [showDeselectTasksModal, setShowDeselectTasksModal] = useState(false);
  const [showUpdateBulkTasksModal, setShowUpdateBulkTasksModal] = useState(false);

  const taskActionTypeIndex = useRef(0);
  const invalidTasksCount = useRef(0);

  const [showSaveBulkTasksConfirmationModal, setShowSaveBulkTasksConfirmationModal] = useState(false);
  const [showDiscardBulkTasksConfirmationModal, setShowDiscardBulkTasksConfirmationModal] = useState(false);
  const [showClearBulkTasksConfirmationModal, setShowClearBulkTasksConfirmationModal] = useState(false);

  const [isUpdatingBulkTasks, setIsUpdatingBulkTasks] = useState(false);

  const savedTasks = selectedTasks.filter((task) => task.metaStatus === TicketMetaStatuses.Saved);
  const inProgressTasks = selectedTasks.filter((task) => task.metaStatus === TicketMetaStatuses.InProgress);
  const selectedTasksIds = selectedTasks.map((task) => task.id);

  const updateMetaStatusForBulkTasks = (metaStatus: string) => {
    setIsUpdatingBulkTasks(true);
    useFetch(API_ENDPOINTS.UPDATE_TASK_STATUS_IN_BULK.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TASK_STATUS_IN_BULK.failureMessage,
      showSuccessToast: true,
      data: {
        ticketIds: selectedTasksIds,
        status: metaStatus,
      },
      thenCallBack: () => {
        setIsUpdatingBulkTasks(false);
        setShowBulkTasksModal(false);
        setShowSaveBulkTasksConfirmationModal(false);
        setShowDiscardBulkTasksConfirmationModal(false);
        setShowClearBulkTasksConfirmationModal(false);
        setClearRows(true);
        fetchTickets();
        getAllTaskLabels();
      },
      catchCallBack: () => {
        setShowBulkTasksModal(false);
        setIsUpdatingBulkTasks(false);
        setShowSaveBulkTasksConfirmationModal(false);
        setShowDiscardBulkTasksConfirmationModal(false);
        setShowClearBulkTasksConfirmationModal(false);
        getAllTaskLabels();
      },
    });
  };
  return (
    <Dialog open={showBulkTasksModal} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
        <Typography variant="h6">Bulk Tasks</Typography>
        <IconButton onClick={() => setShowBulkTasksModal(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ padding: "16px" }}>
        {selectedTasks.length > 0 && (
          <Typography variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)", marginBottom: 1 }}>
            {selectedTasks.length} Tasks selected
          </Typography>
        )}
        <ListItem disablePadding>
          <ListItemButton disabled={selectedTasks.length > 0} onClick={() => setShowAddBulkTasksModal(true)}>
            <ListItemIcon>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText
              primary="Add Tasks"
              secondary={selectedTasks.length > 0 && "De-select tasks to add new tasks in bulk"}
            />
            <ListItemIcon sx={{ position: "relative", left: "30px" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled={selectedTasks.length === 0}
            onClick={() => {
              if (savedTasks.length === 0) setShowUpdateBulkTasksModal(true);
              else {
                invalidTasksCount.current = savedTasks.length;
                taskActionTypeIndex.current = 3;
                setShowDeselectTasksModal(true);
              }
            }}
          >
            <ListItemIcon>
              <UpdateIcon />
            </ListItemIcon>
            <ListItemText
              primary="Update Tasks"
              secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
            />
            <ListItemIcon sx={{ position: "relative", left: "30px" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            disabled={selectedTasks.length === 0 || isUpdatingBulkTasks}
            onClick={() => {
              if (savedTasks.length > 0) {
                invalidTasksCount.current = savedTasks.length;
                taskActionTypeIndex.current = 0;
                setShowDeselectTasksModal(true);
              } else {
                setShowSaveBulkTasksConfirmationModal(true);
              }
            }}
          >
            <ListItemIcon>
              <SaveIcon color={selectedTasks.length > 0 ? "success" : "disabled"} />
            </ListItemIcon>
            <ListItemText
              primary="Save Tasks"
              primaryTypographyProps={{
                color: selectedTasks.length > 0 && "#1E4620",
              }}
              secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled={selectedTasks.length === 0 || isUpdatingBulkTasks}
            onClick={() => {
              if (savedTasks.length > 0) {
                taskActionTypeIndex.current = 1;
                invalidTasksCount.current = savedTasks.length;
                setShowDeselectTasksModal(true);
              } else {
                setShowDiscardBulkTasksConfirmationModal(true);
              }
            }}
          >
            <ListItemIcon>
              <img src={selectedTasks.length > 0 ? delete_icon_red : delete_icon_grey} alt="delete" />
            </ListItemIcon>
            <ListItemText
              primary="Discard Tasks"
              primaryTypographyProps={{
                color: selectedTasks.length > 0 && "#5F2120",
              }}
              secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled={selectedTasks.length === 0 || isUpdatingBulkTasks}
            onClick={() => {
              if (inProgressTasks.length > 0) {
                taskActionTypeIndex.current = 2;
                invalidTasksCount.current = inProgressTasks.length;
                setShowDeselectTasksModal(true);
              } else {
                setShowClearBulkTasksConfirmationModal(true);
              }
            }}
          >
            <ListItemIcon>
              <CheckCircleOutlineIcon color={selectedTasks.length > 0 ? "primary" : "disabled"} />
            </ListItemIcon>
            <ListItemText
              primary="Clear Tasks"
              primaryTypographyProps={{
                color: selectedTasks.length > 0 && "#014361",
              }}
              secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
            />
          </ListItemButton>
        </ListItem>
      </List>
      {showUpdateBulkTasksModal && (
        <UpdateBulkTasksModal
          showUpdateBulkTasksModal={showUpdateBulkTasksModal}
          setShowUpdateBulkTasksModal={setShowUpdateBulkTasksModal}
          selectedTasks={selectedTasks}
          allUsers={allUsers}
          allStatuses={allStatuses}
          fetchTickets={fetchTickets}
          setShowBulkTasksModal={setShowBulkTasksModal}
          setClearRows={setClearRows}
        />
      )}
      <DeselectInvalidTasksModal
        open={showDeselectTasksModal}
        setOpen={setShowDeselectTasksModal}
        invalidTasksCount={invalidTasksCount.current}
        totalTasksCount={selectedTasks.length}
        deselectTasksModalTitle={deselectTasksModalTitleType[taskActionTypeIndex.current]}
        deselectTasksModalInvalidTaskType={deselectTasksModalInvalidTaskType[taskActionTypeIndex.current]}
      />
      <AddBulkTasksModal
        open={showAddBulkTasksModal}
        setOpen={setShowAddBulkTasksModal}
        fetchTickets={fetchTickets}
        setShowBulkTasksModal={setShowBulkTasksModal}
      />
      <SaveBulkTasksConfirmationModal
        open={showSaveBulkTasksConfirmationModal}
        setOpen={setShowSaveBulkTasksConfirmationModal}
        tasksCount={inProgressTasks.length}
        updateMetaStatusForBulkTasks={updateMetaStatusForBulkTasks}
        isLoading={isUpdatingBulkTasks}
      />
      <DiscardBulkTasksConfirmationModal
        open={showDiscardBulkTasksConfirmationModal}
        setOpen={setShowDiscardBulkTasksConfirmationModal}
        tasksCount={inProgressTasks.length}
        updateMetaStatusForBulkTasks={updateMetaStatusForBulkTasks}
        isLoading={isUpdatingBulkTasks}
      />
      <ClearBulkTasksConfirmationModal
        open={showClearBulkTasksConfirmationModal}
        setOpen={setShowClearBulkTasksConfirmationModal}
        tasksCount={savedTasks.length}
        updateMetaStatusForBulkTasks={updateMetaStatusForBulkTasks}
        isLoading={isUpdatingBulkTasks}
      />
      <DeselectInvalidTasksModal
        open={showDeselectTasksModal}
        setOpen={setShowDeselectTasksModal}
        invalidTasksCount={invalidTasksCount.current}
        totalTasksCount={selectedTasks.length}
        deselectTasksModalTitle={deselectTasksModalTitleType[taskActionTypeIndex.current]}
        deselectTasksModalInvalidTaskType={deselectTasksModalInvalidTaskType[taskActionTypeIndex.current]}
      />
    </Dialog>
  );
}
