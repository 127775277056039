import { ChevronLeft, ChevronRight, Download } from "@mui/icons-material";
import { Alert, Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { FormControl, FormControlLabel, Stack, Step, StepLabel, Stepper } from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import useFetch from "src/Components/Common/useFetch";
import { uiLoggerBcPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import PortalAccessDialog from "../Common/PortalAccessDialog";
import RaiseIssueSection from "../Common/RaiseIssueSection";
import { NdAsyncButton, NdCheckbox, NdSecondaryButton } from "../MsmePartnerPortal/CommonComponents";
import { CustomStepIcon } from "../MsmePartnerPortal/DetailsPage2";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";

import { clarity } from "react-microsoft-clarity";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import SubmitContactDetails, { ContactDetailsData } from "../Common/SubmitContactDetailsDialog";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import {
  BcCustomisationDetails,
  BCPortalCBDetails,
  BcPortalDetails,
  CommonUploaderBox,
  ConfirmationRequiredDialog,
  StateDispatch,
  UploadFileType,
} from "./BalanceConfirmationPortalCommon";
import { BcPortalContext } from "./BalanceConfirmationPortalContext";
import { RaiseRequestDialog } from "./FinalPage";

const DetailsPage = ({
  customization,
  openDialog,
  setOpenDialog,
  bpName,
  ruName,
  openPortalAccessDialog,
  setOpenPortalAccessDialog,
}: {
  customization: BcCustomisationDetails;
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
  bpName: string;
  ruName: string;
  openPortalAccessDialog: boolean;
  setOpenPortalAccessDialog: StateDispatch<boolean>;
}) => {
  const location = useLocation<any>();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const { confirmStatus, setConfirmStatus, setPageStatus } = useContext(BcPortalContext);

  const [step1Completed, setStep1Completed] = useState<0 | 1>(0);
  const [step2Completed, setStep2Completed] = useState<0 | 1>(0);
  const [finalStepCompleted] = useState<0 | 1>(0);
  const [completed, setCompleted] = useState<{ [k in UploadFileType]: boolean }>({
    signedPdf: !customization?.isPdfRequired,
    ledgerFile: !customization?.isLedgerRequired,
    openItem: !customization?.isOpenItemRequired,
  });
  const [bcPortalDetails, setBcPortalDetails] = useState<BcPortalDetails>({} as BcPortalDetails);
  const [closingBalanceFromData, setClosingBalanceFromData] = useState<number>(null);
  const [closingBalance, setClosingBalance] = useState<number>(null);
  const [currency, setCurrency] = useState<string>("INR");
  const [closingBalanceRu, setClosingBalanceRu] = useState<number>(null);
  const [accountingSign, setAccountingSign] = useState<"R" | "P">(null);
  const [balance, setBalance] = useState<number>((closingBalance as number) || 0);
  const setClosingBalanceRef = useRef<number>(null);
  const [cbError, setCbError] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>("");

  const [step3Checked, setStep3Checked] = useState(false);

  const [formDetails, setFormDetails] = useState<ContactDetailsData>({} as ContactDetailsData);
  const [openSubmitDetailsDialog, setOpenSubmitDetailsDialog] = useState<boolean>(false);
  const [openConfirmationRequiredDialog, setOpenConfirmationRequiredDialog] = useState<boolean>(false);
  const [issues, setIssues] = useState<RaisedIssueBc[]>([]);
  const [openRaiseRequestDialog, setOpenRaiseRequestDialog] = useState<boolean>(false);

  const [submittingReport, setSubmittingReport] = useState(false);

  useEffect(() => {
    let bal = Number(closingBalance) || 0;

    bal = bal >= 0 ? bal : -1 * bal;

    if (closingBalance === null) setBalance(null);
    else setBalance(bal);

    handleClosingBalanceChange(closingBalanceFromData, closingBalance);
  }, [closingBalance]);

  useEffect(() => {
    if (closingBalance !== null) setAccountingSign(closingBalance > 0 ? "R" : "P");
    else setAccountingSign(closingBalanceRu >= 0 ? "P" : "R");
  }, [closingBalance, closingBalanceRu]);

  const UpdateBalanceConfirmationStatus = (confirmed: boolean) =>
    new Promise<boolean>((resolve, reject) => {
      useFetch(API_ENDPOINTS.UPDATE_BALANCE_CONFIRMATION_STATUS.url, "POST", {
        failureMessage: API_ENDPOINTS.UPDATE_BALANCE_CONFIRMATION_STATUS.failureMessage,
        data: {
          data: encryptedData,
          isBalanceConfirmed: confirmed,
        },
        thenCallBack: (_res) => {
          setConfirmStatus(confirmed);
          resolve(confirmed);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });

  const getDetailsForUploadingPage = () =>
    useFetch<{ details: BcPortalDetails }>(API_ENDPOINTS.GET_DETAILS_FOR_UPLOADING_PAGE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DETAILS_FOR_UPLOADING_PAGE.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const { details } = res.data;

        if (res.data?.details) {
          setBcPortalDetails(res.data?.details);
        } else return;

        if (details?.isPdfDownloaded) {
          setStep1Completed(1);
        }

        {
          setRemarks(details.remarks);
        }

        if (typeof details?.isBalanceConfirmed === "boolean") {
          if (details.isBalanceConfirmed) setConfirmStatus(true);
          else setConfirmStatus(false);
        }
      },
    });

  const getClosingBalanceDetails = () =>
    useFetch<{ closingBalanceDetails: BCPortalCBDetails }>(API_ENDPOINTS.GET_ClOSING_BALANCE_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ClOSING_BALANCE_DETAILS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const { closingBalanceDetails } = res.data;
        setClosingBalance(
          typeof closingBalanceDetails.businessPartnerClosingBalance === "number" &&
            (confirmStatus || bcPortalDetails?.isBalanceConfirmed)
            ? closingBalanceDetails.businessPartnerClosingBalance
            : null
        );
        setCurrency(closingBalanceDetails.currency ? closingBalanceDetails.currency : "INR");
        setClosingBalanceFromData(closingBalanceDetails.businessPartnerClosingBalance);
        setClosingBalanceRu(
          typeof closingBalanceDetails.ownClosingBalance === "number" ? closingBalanceDetails.ownClosingBalance : null
        );
      },
    });

  useEffect(() => {
    const isSignedPdfCompleted = completed.signedPdf || !customization?.isPdfRequired;
    const isLedgerCompleted = completed.ledgerFile || !customization?.isLedgerRequired;
    const isOpenItemCompleted = completed.openItem || !customization?.isOpenItemRequired;

    console.log({ completed, customization, isSignedPdfCompleted });
    if (isSignedPdfCompleted && isLedgerCompleted && isOpenItemCompleted) {
      setStep2Completed(1);
    } else setStep2Completed(0);
  }, [completed, customization]);

  const downloadFile = async () => {
    useFetch<{ base64string: string }>(API_ENDPOINTS.GET_PDF_TO_DOWNLOAD.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_PDF_TO_DOWNLOAD.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const Data = Buffer.from(res.data.base64string, "base64");
        const blob = new Blob([Data], { type: "application/pdf" });
        saveAs(blob, "ConfirmationDocument.pdf");
      },
    });
    setStep1Completed(1);
  };

  const handleBack = () => {
    setPageStatus("NotFilled");
  };

  const afterSubmitDetails = async (_formDetails: ContactDetailsData) => {
    setFormDetails(_formDetails);
    await handleFinalSubmit(_formDetails || formDetails);
  };

  const handleFinalSubmit = (_formDetails: ContactDetailsData) => {
    setSubmittingReport(true);
    return useFetch(API_ENDPOINTS.CONFIRM_FINAL_SUBMISSION.url, "POST", {
      failureMessage: API_ENDPOINTS.CONFIRM_FINAL_SUBMISSION.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        isBalanceConfirmed: confirmStatus,
        personName: _formDetails.personName,
        designation: _formDetails.designation,
        email: _formDetails.email,
        phoneNumber: _formDetails.phoneNumber,
      },
      thenCallBack: () => {
        setSubmittingReport(false);
        setPageStatus("FinalPage");
        clarity.setTag("BalanceConfirmationPortal", `End`);
        window.clarity("event", "BalanceConfirmationPortal-End");
        if (confirmStatus) {
          window.clarity("event", "BalanceConfirmationPortal-Confirm-Submit");
        } else {
          window.clarity("event", "BalanceConfirmationPortal-NotConfirm-Submit");
        }

        // clear edit response param if first time submitting
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("editResponse");

        // useEffect(() => {
        history.push({
          pathname: pathname,
          search: searchParams.toString(),
        });
        // }, []);
      },
      catchCallBack: () => {
        setSubmittingReport(false);
      },
    });
  };

  const handleClosingBalanceChange = (oldCbValue: number, newCbValue: number) => {
    if (oldCbValue === null && newCbValue === null) return;

    setCbError(!Number.isFinite(newCbValue));

    if (!Number.isFinite(newCbValue)) return;
    if (oldCbValue === newCbValue) return;

    useFetch(API_ENDPOINTS.UPDATE_BUSINESS_PARTNER_CLOSING_BALANCE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_BUSINESS_PARTNER_CLOSING_BALANCE.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        closingBalance: newCbValue,
      },
      thenCallBack: () => {
        setClosingBalance(newCbValue);
        setClosingBalanceFromData(newCbValue);
      },
      catchCallBack: () => {
        setClosingBalance(oldCbValue);
      },
      errorCallback: () => {
        setClosingBalance(oldCbValue);
      },
    });
  };

  const getAllPreviouslyRaisedIssues = async () => {
    return new Promise<RaisedIssueBc[]>((resolve, reject) => {
      useFetch<{ previousRequest: RaisedIssueBc[] }>(API_ENDPOINTS.GET_PREVIOUSLY_RAISED_ISSUE.url, "GET", {
        failureMessage: API_ENDPOINTS.GET_PREVIOUSLY_RAISED_ISSUE.failureMessage,
        config: {
          params: {
            data: encryptedData,
          },
        },
        thenCallBack: (response) => {
          const { previousRequest } = response.data;
          if (previousRequest) {
            setIssues(previousRequest);
            resolve(previousRequest);
          }
          resolve([]);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  useEffect(() => {
    getDetailsForUploadingPage();
    getClosingBalanceDetails();
  }, []);

  const SaveRemarks = (remarks: string) => {
    return useFetch(API_ENDPOINTS.SAVE_REMARKS.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_REMARKS.failureMessage,
      data: {
        data: encryptedData,
        remarks,
      },
    });
  };

  return (
    <>
      <Stack className="fade_in details_box" py={3} gap={3} maxWidth={900} mx={"auto"}>
        {/* <NdTextField
          placeholder="Enter Closing Balance"
          label="Enter Closing Balance"
          type="number"
          disabled={confirmStatus}
          size="small"
          focused={!confirmStatus}
          value={typeof closingBalance === "number" ? closingBalance : ""}
          onChange={(_e) => setClosingBalance(parseFloat(_e.target.value))}
          onBlur={(_e) => {
            handleClosingBalanceChange(closingBalanceFromData, parseFloat(_e.target.value));
          }}
          autoFocus={!confirmStatus}
          inputRef={(input) => input && input.focus()}
          error={cbError}
          helperText={cbError ? "Please enter Closing Balance" : ""}
        /> */}

        <Box className="d_flex" gap={2}>
          <TextField
            className={$.BR_fix}
            sx={{
              width: 186,
              "& *.MuiInputBase-root": {
                borderRadius: "4px !important",
              },
            }}
            disabled={true}
          />
          <MonetaryInput
            fullWidth
            key={"closingBalance"}
            className={$.BR_fix}
            placeholder="Enter Closing Balance"
            label="Enter Closing Balance"
            returnNull={true}
            value={balance}
            allowNegative={false}
            setValue={(value: number) => {
              if (value === null) setClosingBalance(null);
              else setClosingBalance(accountingSign === "R" ? value : -1 * value);
              setClosingBalanceRef.current = accountingSign === "R" ? value : -1 * value;
            }}
            // onBlur={(_e, value) => {
            //   handleClosingBalanceChange(closingBalanceFromData, value);
            // }}
            disabled={confirmStatus}
            focused={false}
            autoFocus={true}
            // inputRef={(input) => input && input.focus()}
            error={cbError}
            helperText={cbError ? "Please enter Closing Balance" : ""}
            currency={"INR"}
            sx={{
              "input::placeholder": {
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px !important",
              },
            }}
            InputProps={{
              startAdornment: (
                <>
                  <TextField
                    select
                    className={$.BR_fix}
                    value={accountingSign}
                    onChange={(e) => {
                      const validBalance = closingBalance;

                      setAccountingSign(e.target.value as any);

                      if (e.target.value === "R") {
                        setClosingBalance(validBalance >= 0 ? validBalance : -1 * validBalance);
                        setClosingBalanceRef.current = validBalance >= 0 ? validBalance : -1 * validBalance;
                      } else {
                        setClosingBalance(validBalance <= 0 ? validBalance : -1 * validBalance);
                        setClosingBalanceRef.current = validBalance <= 0 ? validBalance : -1 * validBalance;
                      }
                    }}
                    sx={{
                      minWidth: 150,
                      position: "absolute",
                      left: -166,
                      "& *.MuiInputBase-root": {
                        borderRadius: "4px !important",
                      },
                    }}
                    disabled={confirmStatus}
                  >
                    <MenuItem value={"R"}>Receivable</MenuItem>
                    <MenuItem value={"P"}>Payable</MenuItem>
                  </TextField>
                  <Typography sx={{ marginRight: 2 }}>{currency}</Typography>
                </>
              ),
            }}
          />
        </Box>
        {(Number(closingBalanceRu) > 0 && accountingSign === "R") ||
        (Number(closingBalanceRu) < 0 && accountingSign === "P") ? (
          <Alert className={$.BR_fix} severity="warning" sx={{ "& *": { fontFamily: "inherit" } }}>
            You have changed the sign of Closing Balance amount, please make sure if it is correct
          </Alert>
        ) : (
          <></>
        )}
        {!confirmStatus && Math.abs(Math.abs(closingBalance) - Math.abs(closingBalanceRu)) < 5 && (
          <Alert
            className={$.BR_fix}
            severity="error"
            sx={{ "& *": { fontFamily: "inherit" } }}
            action={
              <Button
                className={$.BR_fix}
                variant="outlined"
                color="error"
                sx={{ minWidth: 175 }}
                onClick={() => {
                  UpdateBalanceConfirmationStatus(true);
                  if (customization?.isPdfRequired) setPageStatus("DetailsFilled");
                  else setOpenDialog(true);
                }}
              >
                CONFIRM BALANCE
              </Button>
            }
          >
            You have entered the exact same Closing Balance. Please click <b>‘Confirm Balance’</b> button if your
            Closing Balance is matching.
          </Alert>
        )}
        <FormControl sx={{ gap: 3 }}>
          <Box className="step_1">
            <Stepper activeStep={step1Completed} sx={{ py: 2 }}>
              <Step sx={{ ".MuiStepIcon-root.Mui-active": { color: "#531c4c" } }}>
                <StepLabel
                  StepIconComponent={CustomStepIcon.bind(null, {
                    icon: 1,
                    completed: Boolean(step1Completed),
                    active: Boolean(!step1Completed),
                  })}
                >
                  Download and Print
                </StepLabel>
              </Step>
              <Step sx={{ p: 0 }}></Step>
            </Stepper>
            <Stack className="step_content" py={2} pl={4} gap={2}>
              <Stack className={$.BR_fix} border={"1px solid #d7d7d7"}>
                <Box className="first vertical_center_align" p={2}>
                  <Typography variant="subtitle1" fontWeight={700} color={"#000"} mr={"auto"}>
                    Balance Confirmation Document
                  </Typography>
                  <NdSecondaryButton
                    variant="contained"
                    startIcon={<Download />}
                    onClick={() => {
                      downloadFile();
                      if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfDownloadClick, encryptedData);
                      else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfDownloadClick, encryptedData);
                    }}
                  >
                    Download
                  </NdSecondaryButton>
                </Box>
                <Box sx={{ borderTop: "1px solid #D7D7D7", background: "#F3F3F3" }} p={2}>
                  <Typography variant="body2" component={"ol"} pl={2.5} sx={{ li: { font: "inherit" } }}>
                    <li>Download and print the document</li>
                    <li>Get the signature of Authorized Signatory and Seal of the Firm</li>
                    <li>Upload the scanned copy of the same in below section</li>
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Box className="step_2">
            <Stepper activeStep={step2Completed} sx={{ py: 2 }}>
              <Step>
                <StepLabel
                  StepIconComponent={CustomStepIcon.bind(null, {
                    icon: 2,
                    completed: Boolean(step2Completed),
                    active: Boolean(step1Completed),
                  })}
                >
                  Upload
                </StepLabel>
              </Step>
              <Step sx={{ p: 0 }}></Step>
            </Stepper>
            <Stack className="step_content" py={2} pl={4} gap={2}>
              <CommonUploaderBox
                fileType="signedPdf"
                disabled={!step1Completed}
                setCompleted={setCompleted}
                customization={customization}
              />
              {!customization?.isLedgerHidden && (
                <CommonUploaderBox
                  fileType="ledgerFile"
                  disabled={!step1Completed}
                  setCompleted={setCompleted}
                  customization={customization}
                />
              )}
              {!customization?.isOpenItemHidden && (
                <CommonUploaderBox
                  fileType="openItem"
                  disabled={!step1Completed}
                  setCompleted={setCompleted}
                  customization={customization}
                />
              )}
            </Stack>
          </Box>
          <Box className="step_3">
            <Stepper activeStep={finalStepCompleted} sx={{ py: 2 }}>
              <Step>
                <StepLabel
                  StepIconComponent={CustomStepIcon.bind(null, {
                    icon: 3,
                    completed: Boolean(finalStepCompleted),
                    active: Boolean(step2Completed),
                  })}
                >
                  Review and Submit
                </StepLabel>
              </Step>
              <Step sx={{ p: 0 }}></Step>
            </Stepper>
            <Stack className="step_content" py={2} pl={4} gap={1}>
              <Typography variant="subtitle1" fontWeight={700}>
                Confirm Company/Firm/Enterprise details and above uploaded files
              </Typography>
              <Alert className={$.BR_fix} severity="warning">
                <Typography variant="body2">Note: You cannot change your response once submitted</Typography>
              </Alert>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Any remarks (Optional)"
                  sx={{ "*": { borderRadius: 1 } }}
                  value={remarks}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                  onBlur={(e) => {
                    SaveRemarks(e.target.value);
                  }}
                />
              </Box>
              <FormControlLabel
                control={
                  <NdCheckbox
                    checked={step3Checked && Boolean(step2Completed) && Number.isFinite(closingBalance)}
                    onChange={() => setStep3Checked(!step3Checked)}
                    disabled={Boolean(!step2Completed) || !Number.isFinite(closingBalance)}
                  />
                }
                label={`I confirm that mentioned Closing Balance of ${ruName} as on Closing Balance Date matches as per ${bpName} books and the uploaded ledgers/files are correct and complete`}
              />
            </Stack>
          </Box>
        </FormControl>
        {/* end of main steps flow */}
      </Stack>
      <Box className="fade_in d_flex" gap={2} pb={5} maxWidth={900} mx={"auto"}>
        <Button
          className={$.BR_fix}
          startIcon={<ChevronLeft />}
          variant="text"
          color="inherit"
          onClick={() => {
            handleBack();
            if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfBackClick, encryptedData);
            else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfBackClick, encryptedData);
          }}
          disabled={false}
        >
          Back
        </Button>
        <NdAsyncButton
          endIcon={<ChevronRight />}
          variant="contained"
          onClick={async () => {
            if (confirmStatus) {
              const _issues = (await getAllPreviouslyRaisedIssues()) || issues;
              if (_issues?.length > 0) setOpenConfirmationRequiredDialog(true);
              else setOpenSubmitDetailsDialog(true);
            } else setOpenSubmitDetailsDialog(true);
            if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfSubmitClick, encryptedData);
            else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfSubmitClick, encryptedData);
          }}
          disabled={Boolean(!step3Checked) || !Number.isFinite(closingBalance) || submittingReport}
        >
          <LoadingIcon loading={submittingReport} />
          Submit
        </NdAsyncButton>
        <RaiseIssueSection setOpenDialog={setOpenDialog} />
      </Box>
      <RaiseRequestDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      <PortalAccessDialog
        openPortalAccessDialog={openPortalAccessDialog}
        setOpenPortalAccessDialog={setOpenPortalAccessDialog}
      />
      <SubmitContactDetails
        openDialog={openSubmitDetailsDialog}
        setOpenDialog={setOpenSubmitDetailsDialog}
        afterSubmitAction={afterSubmitDetails}
      />
      <ConfirmationRequiredDialog
        openDialog={openConfirmationRequiredDialog}
        setOpenDialog={setOpenConfirmationRequiredDialog}
        nextStepAction={() => setOpenSubmitDetailsDialog(true)}
        viewQueryAction={async () => setOpenRaiseRequestDialog(true)}
      />
      <RaiseRequestDialog openDialog={openRaiseRequestDialog} setOpenDialog={setOpenRaiseRequestDialog} />
    </>
  );
};

export default DetailsPage;
