import { Box, Skeleton } from "@mui/material";
import React from "react";

export default function ReportPreviewLoading() {
  return (
    <Box sx={{ padding: 3, width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
      <Skeleton variant="text" width="80%" height={70} />
      <Skeleton variant="text" width="70%" height={40} />
      <Skeleton variant="text" width="90%" height={60} />
      <Skeleton variant="text" width="100%" height={60} />
      <Skeleton variant="text" width="80%" height={40} />
      <Skeleton variant="text" width="90%" height={60} />
      <Skeleton variant="text" width="100%" height={60} />
    </Box>
  );
}
