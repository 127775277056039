import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Avatar, Box, Paper, Stack } from "@mui/material";
import React from "react";

export default function NoAutomatedMailsIcon() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        backgroundColor: "#f3e5f5",
        padding: "16px",
        borderRadius: "20px",
        width: "200px",
      }}
    >
      {[...Array(3)].map((_, index) => (
        <Paper
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "8px",
            borderRadius: "8px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            position: "relative",
            right: "50px",
          }}
        >
          <Avatar sx={{ bgcolor: "#9c27b0", marginRight: "8px" }}>
            <AutorenewIcon />
          </Avatar>
          <Stack gap={1}>
            <Box
              sx={{
                width: "30px",
                height: "8px",
                backgroundColor: "#e1bee7",

                borderRadius: "4px",
              }}
            />
            <Box
              sx={{
                width: "50px",
                height: "8px",
                backgroundColor: "#F3E5F5",
                borderRadius: "4px",
              }}
            />
          </Stack>
        </Paper>
      ))}
    </Box>
  );
}
