import { ActorInfo, ActorTypeForReconStrings } from "../recon-entity/ReconInterfaces";
/**
 * Utility interface for front end user.
 */

export enum ActorTypes {
  Admin = "Admin",
  User = "User",
}

export enum UserTypes {
  Admin = "Admin",
  User = "User",
  CustomerSuccess = "CustomerSuccess",
  Sales = "Sales",
}

export enum UserRole {
  Admin = "Admin",
  CustomerSuccessDefault = "CustomerSuccessDefault",
  RS = "RS",
  CSM = "CSM",
  KAM = "KAM",
  Client = "Client",
  Sales = "Sales",
}
export enum ClientType {
  Product = "Product",
  ProductEnterprise = "ProductEnterprise",
  Service1 = "Service1",
  Service2 = "Service2",
  Service3 = "Service3",
  POC = "POC",
  Dropped = "Dropped",
  Internal = "Internal",
  None = "None",
}

export type ClientTypeStrings = keyof typeof ClientType;

export const UserRoleUserTypeMap = {
  [UserRole.Admin]: UserTypes.Admin,
  [UserRole.CustomerSuccessDefault]: UserTypes.CustomerSuccess,
  [UserRole.RS]: UserTypes.CustomerSuccess,
  [UserRole.CSM]: UserTypes.CustomerSuccess,
  [UserRole.KAM]: UserTypes.CustomerSuccess,
  [UserRole.Client]: UserTypes.User,
  [UserRole.Sales]: UserTypes.Sales,
};

export interface FrontendActor {
  id: any;
  name: string;
  actorId?: number;
  acceptedTNC: boolean;
  actorType: ActorTypes;
  integration: boolean;
  userId: number;
  actorInfo: ActorInfo;
  replyToEmail?: string;
  userType?: UserTypes;
  dynamicContents: string[];
  branchLevelReconcilation?: boolean;
  mfaEnabled?: boolean;
  mfaVerified?: boolean;
  actorName?: string;
  userName?: string;
  userEmail?: string;
  workflowFeatureFlag?: boolean;
  zsMonitorAccount?: boolean;
  clientId?: number;
  crmId?: number;
  clientType?: ClientTypeStrings;
  accountType?: ActorTypeForReconStrings;
  userRole?: UserRole;
  userAgeInDays?: number;
  actorAgeInDays?: number;
  isFirstLogin?: boolean;
}

export enum PathType {
  "admin" = "/admin/userlist",
  "recon" = "/:accountName/recon360/Summary/Ledger",
  "bank" = "/bank/disbursement",
}
