import { Box, Skeleton } from "@mui/material";
import React from "react";

export default function LoadingSetupMail() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, margin: "auto", maxWidth: "1000px" }}>
      <Skeleton variant="rounded" width="100%" height={"30vh"} sx={{ margin: 0 }} />
      <Skeleton variant="rounded" width="100%" height={"40vh"} />
      <Skeleton variant="rounded" width="100%" height={"10vh"} />
    </Box>
  );
}
