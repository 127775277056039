import AutorenewIcon from "@mui/icons-material/Autorenew";
import DescriptionIcon from "@mui/icons-material/Description";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Box,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesReportsMailAutomation } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import AutomatedMailsListLoading from "./AutomatedMailsListLoading";
import { AUTO_MAIL_TEMPLATE_STATUS, generateEmailSchedule, MIS_MAIL_TYPES, TemplateDetails } from "./Interfaces";
import MailMISReportsActions from "./MailMISReportsActions";
import NoAutomatedMailsUI from "./NoAutomatedMailsUI";
import SetupMail from "./SetupMail";

export default function MailMISReports() {
  const { actor } = useContext(userContext);
  const location = useLocation<any>();
  const [currentMailSetupType, setCurrentMailSetupType] = useState<string>(null);
  const [isEditingMailTemplateFlag, setIsEditingMailTemplateFlag] = useState<boolean>(false);
  const [currentEditingTemplateDetails, setCurrentEditingTemplateDetails] = useState<any>(null);
  const [isLoadingAutomatedMails, setIsLoadingAutomatedMails] = useState(false);
  const [automatedMailTemplatesList, setAutomatedMailTemplatesList] = useState<TemplateDetails[]>([]);

  useEffect(() => {
    listAllAutomatedMailTemplates();
  }, []);

  const listAllAutomatedMailTemplates = () => {
    setIsLoadingAutomatedMails(true);
    useFetch<{ templates: TemplateDetails[] }>(API_ENDPOINTS.MAIL_REPORTS_GET_AUTOMATED_MAILS_LIST.url, "GET", {
      failureMessage: API_ENDPOINTS.MAIL_REPORTS_GET_AUTOMATED_MAILS_LIST.failureMessage,
      thenCallBack: (response) => {
        setAutomatedMailTemplatesList(response.data.templates || []);
        setIsLoadingAutomatedMails(false);
      },
      catchCallBack: () => {
        setIsLoadingAutomatedMails(false);
      },
    });
  };
  const handlePauseMailToggle = (templateId, isActive) => {
    setAutomatedMailTemplatesList((prevTemplates) =>
      prevTemplates.map((template) => {
        if (template.id === templateId) {
          template.isActive = !isActive;
          template.status = isActive ? AUTO_MAIL_TEMPLATE_STATUS.Paused : AUTO_MAIL_TEMPLATE_STATUS.Active;
        }
        return template;
      })
    );
    useFetch(API_ENDPOINTS.MAIL_REPORTS_UPDATE_AUTOMATED_MAIL(templateId).url, "PUT", {
      failureMessage: API_ENDPOINTS.MAIL_REPORTS_UPDATE_AUTOMATED_MAIL(templateId).failureMessage,
      showSuccessToast: true,
      data: { isActive: !isActive },
      catchCallBack: () => {
        listAllAutomatedMailTemplates();
      },
    });
  };

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      <Box sx={{ marginTop: 2 }}>
        {currentMailSetupType ? (
          <SetupMail
            setCurrentMailSetupType={setCurrentMailSetupType}
            currentMailSetupType={currentMailSetupType}
            isEditingMailTemplateFlag={isEditingMailTemplateFlag}
            currentEditingTemplateDetails={currentEditingTemplateDetails}
            listAllAutomatedMailTemplates={listAllAutomatedMailTemplates}
          />
        ) : (
          <>
            <MailMISReportsActions
              setCurrentMailSetupType={setCurrentMailSetupType}
              setIsEditingMailTemplateFlag={setIsEditingMailTemplateFlag}
            />
            <Stack sx={{ width: "100%", marginTop: 6, gap: 2 }}>
              <Typography variant="h6">Automated Email</Typography>

              {isLoadingAutomatedMails ? (
                <AutomatedMailsListLoading />
              ) : automatedMailTemplatesList.length > 0 ? (
                automatedMailTemplatesList.map((item) => (
                  <Paper
                    key={item.id}
                    sx={{
                      padding: "8px 16px",
                      borderRadius: "4px",
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      uiLogger(
                        uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_VIEWAUTOMATEDEMAIL_CLICK.functionName,
                        DEFAULT_COMPANY_ID,
                        DEFAULT_BRANCH_CODE,
                        {
                          message: uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_VIEWAUTOMATEDEMAIL_CLICK.message,
                        }
                      );
                      setCurrentEditingTemplateDetails(item);
                      setIsEditingMailTemplateFlag(true);
                      setCurrentMailSetupType(MIS_MAIL_TYPES.automatedEmail);
                    }}
                  >
                    <ListItem sx={{ padding: 0 }}>
                      <ListItemIcon sx={{ minWidth: 2, marginRight: 2 }}>
                        <MailOutlineIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <Typography variant="h6" className="fs_16 fw_400" color="#1976D2">
                              {item.name}
                            </Typography>
                            {item.status !== AUTO_MAIL_TEMPLATE_STATUS.Active && (
                              <Tooltip
                                title={
                                  item.status === AUTO_MAIL_TEMPLATE_STATUS.Error
                                    ? "All reports added in this mail are deleted and mail cannot be sent"
                                    : null
                                }
                              >
                                <Chip
                                  label={item.status}
                                  color={
                                    item.status === AUTO_MAIL_TEMPLATE_STATUS.Error
                                      ? "error"
                                      : item.status === AUTO_MAIL_TEMPLATE_STATUS.Completed
                                      ? "success"
                                      : "warning"
                                  }
                                  sx={{
                                    "& .MuiChip-label": { fontSize: "13px", fontWeight: 400 },
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Box>
                        }
                      />
                      {item.status !== AUTO_MAIL_TEMPLATE_STATUS.Completed && (
                        <Tooltip title={item.isActive ? "Click to Pause Email" : "Click to Resume Email"}>
                          <Switch
                            checked={item.isActive}
                            onClick={(e) => {
                              e.stopPropagation();

                              let uiLoggerFunctionName;
                              let uiLoggerMessage;
                              if (item.isActive) {
                                uiLoggerFunctionName =
                                  uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_PAUSEAUTOMATEDEMAIL_OFF_CLICK
                                    .functionName;
                                uiLoggerMessage =
                                  uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_PAUSEAUTOMATEDEMAIL_OFF_CLICK.message;
                              } else {
                                uiLoggerFunctionName =
                                  uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_PAUSEAUTOMATEDEMAIL_ON_CLICK
                                    .functionName;
                                uiLoggerMessage =
                                  uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_PAUSEAUTOMATEDEMAIL_ON_CLICK.message;
                              }

                              uiLogger(uiLoggerFunctionName, DEFAULT_COMPANY_ID, DEFAULT_BRANCH_CODE, {
                                message: uiLoggerMessage,
                              });
                              handlePauseMailToggle(item.id, item.isActive);
                            }}
                            disabled={item.status === AUTO_MAIL_TEMPLATE_STATUS.Error}
                          />
                        </Tooltip>
                      )}
                    </ListItem>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      <Box sx={{ marginLeft: 4 }}>
                        {item.attachedReports.map((report) => (
                          <Chip
                            key={report.id}
                            icon={<DescriptionIcon sx={{ color: "#0000008f" }} />}
                            label={report.name}
                            sx={{
                              backgroundColor: "#00000014",
                              borderRadius: "100px",
                              padding: "4px",
                              margin: "4px",
                            }}
                          />
                        ))}
                      </Box>

                      {(item.status === AUTO_MAIL_TEMPLATE_STATUS.Active ||
                        item.status === AUTO_MAIL_TEMPLATE_STATUS.Paused) && (
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, flexShrink: 0 }}>
                          <AutorenewIcon sx={{ color: "#0000008f" }} />
                          <Typography
                            variant="subtitle2"
                            color={item.isActive ? "rgba(0, 0, 0, 0.60)" : "rgba(0, 0, 0, 0.38)"}
                            className="fw_500 fs_14"
                          >
                            {generateEmailSchedule(item.startDate, item.endDate, item.frequency)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Paper>
                ))
              ) : (
                <NoAutomatedMailsUI
                  setCurrentMailSetupType={setCurrentMailSetupType}
                  setIsEditingMailTemplateFlag={setIsEditingMailTemplateFlag}
                />
              )}
            </Stack>
          </>
        )}
      </Box>
    </LoggedInSkeleton>
  );
}
