import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesPartnerRiskManagement } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";

export default function ConfirmDeleteReportModal({ open, setOpen, deleteReportDetails, listAllReports }) {
  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const handleDeleteReport = () => {
    setIsDeletingReport(true);
    useFetch(API_ENDPOINTS.DELETE_MIS_REPORT_PARTNER_RISK_MANAGEMENT.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_MIS_REPORT_PARTNER_RISK_MANAGEMENT.failureMessage,
      showSuccessToast: true,
      config: {
        data: {
          id: deleteReportDetails.id,
        },
      },
      thenCallBack: () => {
        setIsDeletingReport(false);
        setOpen(false);
        listAllReports();
      },
      catchCallBack: () => {
        setIsDeletingReport(false);
        setOpen(false);
      },
    });
  };
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Are you sure you want to delete {deleteReportDetails.reportName}?</DialogTitle>
      {deleteReportDetails.templateNames?.length > 0 && (
        <DialogContent>
          <Alert severity="warning">
            This report is currently attached to the following automated email(s):
            {deleteReportDetails.templateNames.map((item, idx) => {
              return <p key={item.id}>{`${idx + 1}. ${item.name}`}</p>;
            })}
            <Typography sx={{ fontWeight: 700, marginTop: 2 }}>
              Deleting it will remove it from these emails.
            </Typography>
          </Alert>
        </DialogContent>
      )}
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDeletingReport}
          variant="contained"
          color="error"
          sx={{ borderRadius: "4px !important" }}
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_DELETEREPORT_DELETE_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_DELETEREPORT_DELETE_CLICK.message }
            );
            handleDeleteReport();
          }}
        >
          {isDeletingReport && <LoadingIcon loading={true} />}
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
