import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Button, Dialog, Grid } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { DateRange } from "src/entity/recon-entity/ReconInterfaces";
import FormatDate from "src/Utils/DateUtils";
import { uiLoggerNamesRecon } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import "../../Common/Common.scss";
import LoadingIcon from "../../Common/LoadingIcon";
import { Recon360Context } from "../Recon360";
import "../Recon360.scss";
import styles from "./UseDateFilter.module.scss";
interface UseDateFilterPropsInterface {
  showUseDateFilter: boolean;
  setShowUseDateFilter: Dispatch<SetStateAction<boolean>>;
  startDate: Date;
  endDate: Date;
  setStartDate: Dispatch<SetStateAction<any>>;
  setEndDate: Dispatch<SetStateAction<any>>;
  dateRanges: DateRange[];
}

const UseDateFilter = (props: UseDateFilterPropsInterface) => {
  const [startDateVal, setStartDateVal] = useState(props.startDate);
  const [endDateVal, setEndDateVal] = useState(props.endDate);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);

  const { loadingReconSavePeriod, postReconPeriod, businessPartnerSelectedRef, companyId, branchCode } =
    useContext(Recon360Context);

  return (
    <Dialog open={props.showUseDateFilter} onClose={() => props.setShowUseDateFilter(true)}>
      <div className={styles.modal_container}>
        <header className={styles.modal_title}>
          <p className="fw_400 fs_24">Reconciliation Period</p>
          <ClearRoundedIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setStartDateVal(null);
              setEndDateVal(null);
              props.setShowUseDateFilter(false);
            }}
          />
        </header>
        <hr className="solid_divider" />
        <section className={styles.modal_body}>
          <p className="fw_400 fs_14">Set your custom period or select from the below options</p>
          <div className={styles.date_container}>
            <Grid xs={12} md={12} style={{ marginBottom: "20px" }} className="center_align">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                // style={{ minWidth: "300px" }}
                // className="closingblc_input"
              >
                <DesktopDatePicker
                  disableFuture
                  format="dd/MM/yyyy"
                  label="Start Date"
                  onError={(error) => {
                    if (error) setStartDateError(true);
                    else setStartDateError(false);
                  }}
                  value={startDateVal}
                  onChange={(date: Date) => {
                    setStartDateVal(date);
                  }}
                  slotProps={{
                    textField: {
                      helperText: startDateError ? "Invalid Date" : "",
                    },
                  }}
                  maxDate={new Date()}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={12} md={12} style={{ marginBottom: "20px" }} className="center_align">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                // style={{ minWidth: "300px" }}
                // className="closingblc_input"
              >
                <DesktopDatePicker
                  format="dd/MM/yyyy"
                  label="End Date"
                  onError={(error) => {
                    if (error) setEndDateError(true);
                    else setEndDateError(false);
                  }}
                  value={endDateVal}
                  onChange={(date: Date) => {
                    setEndDateVal(date);
                  }}
                  slotProps={{
                    textField: {
                      helperText: endDateError ? "Invalid Date" : "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </div>
          {moment(startDateVal).format("YYYY-MM-DD").valueOf() > moment(endDateVal).format("YYYY-MM-DD").valueOf() ? (
            <Grid className="date_error_text fw_400 fs_14">The Start Date must be earlier than the End Date.</Grid>
          ) : null}
          <div className={styles.modal_chips}>
            {props.dateRanges.map((range, index) => (
              <div
                className={`${styles.modal_chip} ${
                  range.startDate === startDateVal && range.endDate === endDateVal && styles.selected_chip
                }`}
                key={index}
                onClick={() => {
                  setStartDateVal(range.startDate);
                  setEndDateVal(range.endDate);
                }}
              >
                <span className="fw_600 fs_13">{`${FormatDate(range.startDate)} - ${FormatDate(range.endDate)}`}</span>
              </div>
            ))}
          </div>
        </section>
        <hr className="solid_divider" />
        <footer className={styles.modal_actions}>
          <Button
            className="fw_700 fs_16"
            variant="contained"
            sx={{
              width: "100%",
              padding: "8px 22px",
              background: "#541C4C",
              borderRadius: "10px !important",
              color: "#fff",
              "&:hover, &.Mui-focusVisible": { background: "#541C4C" },
            }}
            startIcon={<LoadingIcon loading={loadingReconSavePeriod} />}
            disabled={
              loadingReconSavePeriod ||
              moment(startDateVal).format("YYYY-MM-DD").valueOf() > moment(endDateVal).format("YYYY-MM-DD").valueOf() ||
              startDateVal === null ||
              endDateVal === null ||
              endDateError ||
              startDateError
            }
            onClick={() => {
              postReconPeriod(moment(startDateVal)?.format("YYYY-MM-DD"), moment(endDateVal)?.format("YYYY-MM-DD"));
              uiLogger(
                uiLoggerNamesRecon.UI_RL_SET_RECON_PERIOD_CONFIRM_CLICK.functionName,
                companyId.current,
                branchCode.current,
                {
                  message: uiLoggerNamesRecon.UI_RL_SET_RECON_PERIOD_CONFIRM_CLICK.message,
                  businessPartnerId: businessPartnerSelectedRef.current,
                }
              );
            }}
          >
            CONFIRM
          </Button>
        </footer>
      </div>
    </Dialog>
  );
};

export default UseDateFilter;
