import { Skeleton, Stack } from "@mui/material";
import React from "react";

export default function AutomatedMailsListLoading() {
  return (
    <Stack gap={0}>
      <Skeleton variant="text" width="100%" height={150} />
      <Skeleton variant="text" width="100%" height={150} />
      <Skeleton variant="text" width="100%" height={150} />
      <Skeleton variant="text" width="100%" height={150} />
    </Stack>
  );
}
