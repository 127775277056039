import { Box, Typography } from "@mui/material";
import React from "react";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { MIS_MAIL_TYPES } from "./Interfaces";
import NoAutomatedMailsIcon from "./NoAutomatedMailsIcon";

export default function NoAutomatedMailsUI({ setCurrentMailSetupType, setIsEditingMailTemplateFlag }) {
  return (
    <Box
      sx={{
        padding: 4,
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        gap: 2,
      }}
    >
      <NoAutomatedMailsIcon />
      <Box textAlign="center">
        <Typography className="fw_500 fs_20">No Automated Email</Typography>
        <Typography className="fw_400 fs_14" color="rgba(0, 0, 0, 0.60)">
          Send automated email to show them here
        </Typography>
      </Box>
      <NdButton
        variant="contained"
        onClick={() => {
          setCurrentMailSetupType(MIS_MAIL_TYPES.automatedEmail);
          setIsEditingMailTemplateFlag(false);
        }}
      >
        Send Automated email
      </NdButton>
      <Typography textTransform="uppercase" className="fw_500 fs_13" color="#9C27B0">
        How to automate email?
      </Typography>
    </Box>
  );
}
