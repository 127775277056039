/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Box, FormControlLabel, MenuItem, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useHistory, useLocation } from "react-router";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import FormatDate from "src/Utils/DateUtils";
import { uiLoggerLrPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import {
  BCPortalCBDetails,
  BcPortalDetails,
  CommonUploaderBox,
  MailBoxPropsBc,
  UploadFileType,
} from "../BalanceConfirmationPortal/BalanceConfirmationPortalCommon";
import { BcPortalContext } from "../BalanceConfirmationPortal/BalanceConfirmationPortalContext";
import PortalAccessDialog from "../Common/PortalAccessDialog";
import RaiseIssueSection from "../Common/RaiseIssueSection";
import SubmitContactDetails, { ContactDetailsData } from "../Common/SubmitContactDetailsDialog";
import { NdButton, NdCheckbox } from "../MsmePartnerPortal/CommonComponents";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";
import { StateDispatch } from "./CommonComponents";
import { RaiseRequestDialog } from "./FinalPage";
import { MailBox } from "./LedgerRequestPortalCommon";

interface InitialPageProps {
  emailData: MailBoxPropsBc;
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
  openPortalAccessDialog: boolean;
  setOpenPortalAccessDialog: StateDispatch<boolean>;
}

const InitialPage = ({
  emailData,
  openDialog,
  setOpenDialog,
  openPortalAccessDialog,
  setOpenPortalAccessDialog,
}: InitialPageProps) => {
  const location = useLocation<any>();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const {
    customization: { ledgerRequest: customization },
    setUploadedFiles,
    nameAndLogo,
    setPageStatus,
  } = useContext(BcPortalContext);

  const [closingBalanceFromData, setClosingBalanceFromData] = useState<number>(null);
  const [closingBalance, setClosingBalance] = useState<number>(null);
  const [currency, setCurrency] = useState<string>("INR");
  const [closingBalanceDate, setClosingBalanceDate] = useState<string>(null);
  const [accountingSign, setAccountingSign] = useState<"R" | "P">(null);
  const [balance, setBalance] = useState<number>(closingBalance as number);
  const setClosingBalanceRef = useRef<number>(null);

  const [completed, setCompleted] = useState<{ [k in UploadFileType]: boolean }>({
    signedPdf: true,
    ledgerFile: Boolean(customization?.isLedgerRequired),
    openItem: Boolean(customization?.isOpenItemRequired),
  });

  const [step1Completed, setStep1Completed] = useState(false);
  const [step2Checked, setStep2Checked] = useState(false);

  const [formDetails, setFormDetails] = useState<ContactDetailsData>({} as ContactDetailsData);
  const [openSubmitDetailsDialog, setOpenSubmitDetailsDialog] = useState<boolean>(false);

  const [submittingRequest, setSubmittingRequest] = useState(false);

  useEffect(() => {
    const isPartnerBalanceCompleted = Number.isFinite(closingBalance) || !customization?.isPartnerBalanceRequired;
    const isLedgerCompleted = completed.ledgerFile || !customization?.isLedgerRequired;
    const isOpenItemCompleted = completed.openItem || !customization?.isOpenItemRequired;

    if (isPartnerBalanceCompleted && isLedgerCompleted && isOpenItemCompleted) {
      setStep1Completed(true);
    } else setStep1Completed(false);
  }, [completed, closingBalance, customization]);

  const GetUploadedLedgers = () =>
    useFetch<{ details: BcPortalDetails }>(API_ENDPOINTS.GET_DETAILS_FOR_UPLOADING_PAGE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DETAILS_FOR_UPLOADING_PAGE.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const UploadedFiles = res.data.details;
        const { ledgers, confirmationDocument, openItems } = UploadedFiles;

        setUploadedFiles({
          ledgers: ledgers,
          confirmationDocument,
          openItems,
        });
      },
    });

  const getClosingBalanceDetails = () =>
    useFetch<{ closingBalanceDetails: BCPortalCBDetails }>(API_ENDPOINTS.GET_ClOSING_BALANCE_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ClOSING_BALANCE_DETAILS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const { closingBalanceDetails } = res.data;
        setCurrency(closingBalanceDetails.currency ? closingBalanceDetails.currency : "INR");
        setClosingBalance(closingBalanceDetails.businessPartnerClosingBalance);
        setClosingBalanceFromData(closingBalanceDetails.businessPartnerClosingBalance);
        setAccountingSign(closingBalanceDetails.businessPartnerClosingBalance > 0 ? "R" : "P");
        setClosingBalanceDate(FormatDate(closingBalanceDetails.closingBalanceDate, "DD-MM-YYYY").toString());
      },
    });

  useEffect(() => {
    GetUploadedLedgers();
    getClosingBalanceDetails();
  }, []);

  const submitLedgerRequest = async (_formDetails: ContactDetailsData) => {
    setSubmittingRequest(true);
    return useFetch<{ signedUrl: string }>(API_ENDPOINTS.CONFIRM_FINAL_SUBMISSION.url, "POST", {
      failureMessage: API_ENDPOINTS.CONFIRM_FINAL_SUBMISSION.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        personName: _formDetails.personName,
        designation: _formDetails.designation,
        email: _formDetails.email,
        phoneNumber: _formDetails.phoneNumber,
        isLedgerRequest: true,
        isPartnerBalanceAsked: customization?.isPartnerBalanceHidden === false,
      },
      thenCallBack: (_res) => {
        setSubmittingRequest(false);
        setPageStatus("FinalPage");
        clarity.setTag("LedgerRequestPortal", `End`);
        window.clarity("event", "LedgerRequestPortal-End");

        // clear edit response param if first time submitting
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("editResponse");

        history.push({
          pathname: pathname,
          search: searchParams.toString(),
        });
      },
      catchCallBack: () => {
        setSubmittingRequest(false);
      },
    });
  };

  const afterSubmitDetails = async (_formDetails: ContactDetailsData) => {
    setFormDetails(_formDetails);
    await submitLedgerRequest(_formDetails || formDetails);
  };

  useEffect(() => {
    let bal = Number(closingBalance) || 0;

    bal = bal >= 0 ? bal : -1 * bal;

    if (closingBalance === null) setBalance(null);
    else setBalance(bal);

    if (closingBalance !== null) setAccountingSign(closingBalance > 0 ? "R" : "P");

    // handleClosingBalanceChange(closingBalanceFromData, closingBalance);
  }, [closingBalance]);

  const handleClosingBalanceChange = (oldCbValue: number, newCbValue: number) => {
    if (oldCbValue === null && newCbValue === null) return;

    // setCbError(!Number.isFinite(newCbValue));

    if (!Number.isFinite(newCbValue)) return;
    if (oldCbValue === newCbValue) return;

    useFetch(API_ENDPOINTS.UPDATE_BUSINESS_PARTNER_CLOSING_BALANCE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_BUSINESS_PARTNER_CLOSING_BALANCE.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        closingBalance: newCbValue,
      },
      thenCallBack: () => {
        setClosingBalance(newCbValue);
        setClosingBalanceFromData(newCbValue);
      },
      catchCallBack: () => {
        setClosingBalance(oldCbValue);
      },
      errorCallback: () => {
        setClosingBalance(oldCbValue);
      },
    });
  };

  const SaveRemarks = (remarks: string) => {
    return useFetch(API_ENDPOINTS.SAVE_REMARKS.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_REMARKS.failureMessage,
      data: {
        data: encryptedData,
        remarks,
      },
    });
  };

  return (
    <>
      <Box className="main_container">
        <Stack className="fade_in details_box" py={6} gap={5} maxWidth={900} mx={"auto"}>
          <MailBox subject={emailData.subject} body={emailData.body} from={emailData.from} time={emailData.time} />

          {!customization?.isPartnerBalanceHidden && (
            <Stack className="cb_box" gap={2}>
              <Typography py={2}>
                Closing Balance in <b>{nameAndLogo?.businessPartnerName}</b> ledger, as on <b>{closingBalanceDate}</b>{" "}
                for <b>{nameAndLogo.name}</b>
              </Typography>
              <Box className="d_flex" gap={2}>
                <TextField
                  className={$.BR_fix}
                  sx={{
                    width: 186,
                    "& *.MuiInputBase-root": {
                      borderRadius: "4px !important",
                    },
                  }}
                  disabled={true}
                />

                <MonetaryInput
                  fullWidth
                  key={"closingBalance"}
                  className={$.BR_fix}
                  placeholder="Enter Closing Balance"
                  label="Enter Closing Balance"
                  returnNull={true}
                  value={balance}
                  allowNegative={false}
                  setValue={(value: number) => {
                    if (value === null) setClosingBalance(null);
                    else setClosingBalance(accountingSign === "R" ? value : -1 * value);
                    setClosingBalanceRef.current = accountingSign === "R" ? value : -1 * value;
                  }}
                  onBlur={(_e, value) => {
                    handleClosingBalanceChange(closingBalanceFromData, value);
                  }}
                  focused={false}
                  autoFocus={true}
                  // inputRef={(input) => input && input.focus()}
                  // error={cbError}
                  // helperText={cbError ? "Please enter Closing Balance" : ""}
                  currency={"INR"}
                  sx={{
                    "input::placeholder": {
                      fontSize: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "4px !important",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <>
                        <TextField
                          select
                          className={$.BR_fix}
                          value={accountingSign}
                          onChange={(e) => {
                            const validBalance = closingBalance;

                            setAccountingSign(e.target.value as any);

                            if (e.target.value === "R") {
                              setClosingBalance(validBalance >= 0 ? validBalance : -1 * validBalance);
                              setClosingBalanceRef.current = validBalance >= 0 ? validBalance : -1 * validBalance;
                            } else {
                              setClosingBalance(validBalance <= 0 ? validBalance : -1 * validBalance);
                              setClosingBalanceRef.current = validBalance <= 0 ? validBalance : -1 * validBalance;
                            }
                          }}
                          sx={{
                            minWidth: 150,
                            position: "absolute",
                            left: -167,
                            "& *.MuiInputBase-root": {
                              borderRadius: "4px !important",
                            },
                          }}
                        >
                          <MenuItem value={"R"}>Receivable</MenuItem>
                          <MenuItem value={"P"}>Payable</MenuItem>
                        </TextField>
                        <Typography sx={{ marginRight: 2 }}>{currency}</Typography>
                      </>
                    ),
                  }}
                />
              </Box>
            </Stack>
          )}
          <Stack className="uploader_box" gap={2}>
            {!customization?.isLedgerHidden && (
              <CommonUploaderBox fileType="ledgerFile" setCompleted={setCompleted} customization={customization} />
            )}
            {!customization?.isOpenItemHidden && (
              <CommonUploaderBox fileType="openItem" setCompleted={setCompleted} customization={customization} />
            )}
          </Stack>
          {/* <CommonUploaderBox setFileNames={setFileNames} /> */}

          <Stack className="checkbox_area" gap={2} pt={2}>
            <Alert className={$.BR_fix} severity="warning">
              <Typography variant="body2">Note: You cannot change your response once submitted</Typography>
            </Alert>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                label="Any remarks (Optional)"
                sx={{ "*": { borderRadius: "4px !important" } }}
                defaultValue={""}
                onBlur={(e) => {
                  SaveRemarks(e.target.value);
                }}
              />
            </Box>
            <FormControlLabel
              control={
                <NdCheckbox
                  checked={step1Completed && step2Checked}
                  onChange={() => setStep2Checked(!step2Checked)}
                  disabled={Boolean(!step1Completed)}
                />
              }
              label={`I confirm that the above information is correct`}
              sx={{ ml: 0, userSelect: "none" }}
            />
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NdButton
              className={$.BR_fix}
              variant="contained"
              disabled={!(step1Completed && step2Checked) || submittingRequest}
              onClick={() => {
                setOpenSubmitDetailsDialog(true);
                uiLoggerPortal(uiLoggerLrPortal.ui_PpLrSubmitClick, encryptedData);
              }}
            >
              <LoadingIcon loading={submittingRequest} />
              submit
            </NdButton>
            <RaiseIssueSection setOpenDialog={setOpenDialog} />
          </Box>
        </Stack>
      </Box>
      <RaiseRequestDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />

      <SubmitContactDetails
        openDialog={openSubmitDetailsDialog}
        setOpenDialog={setOpenSubmitDetailsDialog}
        afterSubmitAction={afterSubmitDetails}
      />
      <PortalAccessDialog
        openPortalAccessDialog={openPortalAccessDialog}
        setOpenPortalAccessDialog={setOpenPortalAccessDialog}
      />
    </>
  );
};

export default InitialPage;
