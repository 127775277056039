import { FilterAlt, FilterAltOff, Settings, Sync } from "@mui/icons-material";
import { Cached, CloudDownload, Download, MailOutline, Visibility } from "@mui/icons-material";
import { Autocomplete, Avatar, Button, Chip, CircularProgress, createFilterOptions, Stack } from "@mui/material";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import CustomToast from "src/Components/CustomToast";
import LoadingGif from "src/Graphics/loading.gif";
import {
  updateEmailResponseMsgLR,
  updateIsSendingBulkMailsLR,
  updateMailFailedUsersLR,
  updateMailFailedWorkbookLR,
  updateOpenBulkMailReportDialogLR,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { DateDifference } from "src/Utils/DateUtils";
import { LedgerRequestStatuses } from "src/Utils/PartnerCommunication";
import uiLogger from "src/Utils/UiLogger";
import {
  BulkMailStatusResponse,
  BusinessPartnersListForLedgerRequest,
  BusinessPartnersListForLedgerRequestRes,
  EmailSendCount,
  EmailTemplate,
  ListOwnTallyCompanies,
  ListOwnTallyCompaniesRes,
  ListPDFTemplatesResponse,
  ListUserEmailSettingRes,
  ListUserEmailTemplateResponse,
  MailThread,
  PartnerCommunicationSelected,
  S3Attachment,
  UseCaseType,
  ViewEmailThreadResponse,
} from "../../../../entity/recon-entity/ReconInterfaces";
import { uiLoggerName } from "../../../../Utils/Recon/UiLogger/Constants";
import IndeterminateCheckbox from "../../../Admin/IndeterminateCheckbox";
import { LoggedInSkeleton } from "../../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../../Common/TopNavBar";
import useFetch from "../../../Common/useFetch";
import { userContext } from "../../../Contexts/userContext";
import { Dialog } from "../../../Dialog/Dialog";
import BpDashboardTable from "../../../ReactTable/BpDashboardTable";
import ListBusinessPartnerUsers from "../../PartnerMaster/ListBusinessPartnerUsers";
import BulkMailReportDialog from "../CommonLegacy/BulkMailReportDialog";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  IntegratedDropDown,
  ListBranchesRes,
  ListCompaniesRes,
} from "../CommonLegacy/CommonComponents";
import MailThreadDialog from "../CommonLegacy/MailThreadDialog";
import StatusRefreshDialog from "../CommonLegacy/StatusRefreshDialog";
import CreateEmailTemplate from "../Communication/CreateEmailTemplate";
import SelectEmailTemplate from "../Communication/SelectEmailTemplate";
import SendEmailTemplate from "../Communication/SendEmailTemplate";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import LedgerRequestFilter from "./LedgerRequestFilter";
import LedgerRequestSettings from "./LedgerRequestSettings";

import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { PageSelectModeToggle } from "../Common/CommonComponents";
import "../Styles/PartnerCommunication.scss";

let intervalId: ReturnType<typeof setTimeout>;

interface ILedgerRequestContext {
  rowsDataLedgerRequest: BusinessPartnersListForLedgerRequest[];
  storeRowsDataLedgerRequest: BusinessPartnersListForLedgerRequest[];
  setRowsDataLedgerRequest: StateDispatch<BusinessPartnersListForLedgerRequest[]>;
  setStoreRowsDataLedgerRequest: StateDispatch<BusinessPartnersListForLedgerRequest[]>;
  listAllBusinessPartnersWSR: () => Promise<void>;
}

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export const LedgerRequestContext = createContext<ILedgerRequestContext>(null);
const filter = createFilterOptions<any>();

type ColDef<T = any> = ColumnDef<T> & {
  sticky?: "left" | "right";
};

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: " ",
      businessPartnerId: 0,
      allCategories: [],
      category: [],
      dateOfLastLedgerRequestStatusChange: null,
      ledgerRequestStatus: " ",
      latestLedgerReceivedOn: null,
      ledgerRequestStatusWithReminderCount: " ",
    } as BusinessPartnersListForLedgerRequest);
  }
  return dataArray;
};

const LedgerRequest = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<BusinessPartnersListForLedgerRequest[]>([]);
  const [rowsDataLedgerRequest, setRowsDataLedgerRequest] = useState<BusinessPartnersListForLedgerRequest[]>(
    getBlankData()
  );
  const [storeRowsDataLedgerRequest, setStoreRowsDataLedgerRequest] = useState<BusinessPartnersListForLedgerRequest[]>(
    []
  );
  const [openLedgerRequestSettings, setOpenLedgerRequestSettings] = useState<boolean>(false);
  const [openLedgerRequestFilter, setOpenLedgerRequestFilter] = useState<boolean>(false);
  const storeAllCategories = useRef<any[]>([]);
  const storeLedgerRequestData = useRef<any[]>([]);
  const [isFilterAppliedLedgerRequest, setIsFilterAppliedLedgerRequest] = useState<boolean>(false);
  const [statusDataReady, setStatusDataReady] = useState(false);
  const [statusDataFetching, setStatusDataFetching] = useState(false);
  const [statusButtonClicked, setStatusButtonClicked] = useState(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<string>("Never");
  const [openStatusRefreshDialog, setOpenStatusRefreshDialog] = useState(false);
  const [differenceTime, setDifferenceTime] = useState<number>(null);
  const lastInterval = useRef(null);
  // ----//

  const [showResetButton, setShowResetButton] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [file, setFile] = useState<any>(undefined);
  const [signature, setSignature] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [lastSelectedTemplateID, SetLastSelectedTemplateID] = useState(0);
  const [bpName, setBpName] = useState("");
  const [openMailThread, setOpenMailThread] = useState(false);
  const [mailThreads, setMailThreads] = useState<MailThread[]>([]);
  const [s3Attachments, setS3Attachments] = useState<S3Attachment[]>([]);
  const [mailThreadSubject, setMailThreadSubject] = useState<string>("");
  const [mailLoading, setMailLoading] = useState(false);
  const openSelectMailTempOnFinish = useRef(false);
  const [pageSelectMode, setPageSelectMode] = useState(false);

  // Email States BEGIN
  const {
    setOpenSelectEmailTemplate,
    setOpenSendEmailTemplate,
    hideSendEmailTemplate,
    setHideSendEmailTemplate,
    setEmailTemplates,
    setEmailTemplateContent,
    setEmailBusinessPartnerId,
    setEmailDisplayName,
    setFromEmailAddress,
    ResetContext,
    setPdfTemplates,
    setAllowSubjectChange,
  } = useContext(PartnerCommsContext);

  const dispatch = useDispatch();
  const {
    isSendingBulkMailsLR,
    openBulkMailReportDialogLR,
    emailResponseMsgLR,
    mailFailedUsersLR,
    mailFailedWorkbookLR,
  } = useSelector((state: any) => state.bulkMailsStatus);

  const [sendSoleOrBulkMail, setSendSoleOrBulkMail] = useState<EmailSendCount>(null);
  // Email States END

  const buttonRef = useRef(null);

  const storeAllBusinessPartnerIds = useRef<number[]>(null);
  const storeAllBusinessPartnerCheck = useRef<{ [k: number]: boolean }>(null);

  const [lastCompany, setLastCompany] = useState<CompanyInfo>(null);
  const companyId = useRef<string>(null);

  const [lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(null);

  // ListBusinessPartnerUsers
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<any>(null);

  // reset status
  const [downloadLedgerRequestReportBase64, setDownloadLedgerRequestReportBase64] = useState<string>(null);

  // delete email template
  const { setShowDeleteLoadingIcon, handleCloseDeleteModal } = useContext(PartnerCommsContext);

  // frequency
  const [optionsLedgerRequestFrequency, setOptionsLedgerRequestFrequency] = useState<string[]>([]);
  const [frequencyTableSettings, setFrequencyTableSettings] = useState<any>(null);

  const columnDefinitionLedgerRequest = useMemo(
    (): ColDef<BusinessPartnersListForLedgerRequest>[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div>
            <IndeterminateCheckbox
              checked={pageSelectMode ? table.getIsAllPageRowsSelected() : table.getIsAllRowsSelected()}
              indeterminate={pageSelectMode ? table.getIsSomePageRowsSelected() : table.getIsSomeRowsSelected()}
              onChange={
                pageSelectMode ? table.getToggleAllPageRowsSelectedHandler() : table.getToggleAllRowsSelectedHandler()
              }
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div className="center_align_ver_horiz">
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        minSize: 35,
        maxSize: 35,
        size: 35,
      },
      {
        header: () => <div>Business Partner</div>,
        id: "Business partner name",
        sticky: "left",
        enableSorting: true,
        accessorFn: (row) => row.businessPartnerName?.toLowerCase(),
        cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a
              onClick={() => {
                storeRowOpenListBusinessPartnerUsers.current = row;
                setOpenListBusinessPartnerUsers(true);
              }}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
        maxSize: 300,
        minSize: 280,
        size: 300,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row.vendorCode,
        enableSorting: true,
        size: 130,
      },
      {
        header: () => (
          <div className="vertical_center_align" style={{ gap: 8 }}>
            <div className={!statusDataReady ? "noSort" : ""}>Status</div>
            {statusDataFetching && (
              <CircularProgress
                size={18}
                // color="inherit"
                className="white-progress"
                sx={{ "svg *": { strokeWidth: 4 } }}
              />
            )}
          </div>
        ),
        id: "Status",
        accessorKey: "ledgerRequestStatus",
        enableSorting: statusDataReady,
        maxSize: 280,
        minSize: 220,
        size: 270,
        cell: ({ row }) => (
          <>
            {row.original.ledgerRequestStatus ? (
              <div className="vertical_center_align gap_10">
                <Button
                  onClick={() => {
                    // downloadLedger(row.original);
                    downloadEmailAttachments(row.original.businessPartnerId);
                    uiLogger(uiLoggerName.ui_DownloadLedgerStatus, companyId.current, branchCode.current);
                  }}
                  style={{
                    width: "200px",
                    overflowWrap: "break-word",
                    color:
                      row.original.ledgerRequestStatus === "Ledger requested" ||
                      row.original.ledgerRequestStatus === "Check partner reply" ||
                      row.original.ledgerRequestStatus === "Reminder sent" ||
                      row.original.ledgerRequestStatus === "Configuration Underway" ||
                      row.original.ledgerRequestStatus === "Recon shared with Partner"
                        ? "black"
                        : row.original.ledgerRequestStatus
                        ? "white"
                        : "gray",
                    backgroundColor:
                      row.original.ledgerRequestStatus === "Ledger requested" ||
                      row.original.ledgerRequestStatus === "Check partner reply" ||
                      row.original.ledgerRequestStatus === "Reminder sent" ||
                      row.original.ledgerRequestStatus === "Configuration Underway" ||
                      row.original.ledgerRequestStatus === "Send Reminder"
                        ? "#dfae38"
                        : row.original.ledgerRequestStatus === "Recon shared with Partner"
                        ? "green"
                        : row.original.ledgerRequestStatus === "Download ledger"
                        ? "#27b27c"
                        : row.original.ledgerRequestStatus === "Request ledger"
                        ? "#774570"
                        : null,
                  }}
                  className="status_theme_btn"
                  disabled={
                    row.original.ledgerRequestStatus === "Download ledger" ||
                    row.original.ledgerRequestStatus === "Configuration Underway"
                      ? false
                      : true
                  }
                >
                  {row.original.ledgerRequestStatus === "Reminder sent"
                    ? row.original.ledgerRequestStatusWithReminderCount
                    : row.original.ledgerRequestStatus}
                </Button>
                {row.original?.downloadLedgerKey ? (
                  <Tooltip title="Download Ledger" arrow={true}>
                    <span className="event_wrapper">
                      <IconButton
                        onClick={() => {
                          downloadEmailAttachments(row.original.businessPartnerId);
                          uiLogger(uiLoggerName.ui_DownloadLedgerIcon, companyId.current, branchCode.current);
                        }}
                        disabled={!row.original?.downloadLedgerKey}
                        color="primary"
                      >
                        <CloudDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : null}
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },

      {
        header: "Category",
        accessorFn: (row) => row.category?.[0]?.toLowerCase(),
        enableSorting: statusDataReady,
        maxSize: 200,
        minSize: 200,
        size: 200,
        cell: ({ row }) => (
          <div className="category_ui">
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              disabled={!statusDataReady}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option: any) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
            />
          </div>
        ),
      },

      {
        header: "Last Status Change Date",
        accessorKey: "dateOfLastLedgerRequestStatusChange",
        enableSorting: true,
        size: 185,
        cell: ({ row }) => (
          <p>
            {" "}
            {row.original.dateOfLastLedgerRequestStatusChange !== null
              ? moment(row.original.dateOfLastLedgerRequestStatusChange)?.format("DD-MM-YYYY")
              : null}
          </p>
        ),
      },
      {
        header: "Last Ledger Received Date",
        accessorKey: "latestLedgerReceivedOn",
        enableSorting: true,
        size: 200,
        cell: ({ row }) => (
          <p>
            {" "}
            {row.original.latestLedgerReceivedOn !== null
              ? moment(row.original.latestLedgerReceivedOn).format("DD-MM-YYYY")
              : null}
          </p>
        ),
      },
      {
        header: "Actions",
        accessorKey: "businessPartnerId",
        enableSorting: false,
        size: 160,
        sticky: "right",
        cell: ({ row }) => (
          <Stack direction="row" gap={1} alignItems="center" position="relative" top={-1}>
            {/* Send Email Button */}
            <Tooltip title={statusDataFetching ? `Please Wait while Status is Loading` : "Send Email"} arrow={true}>
              <span className="disabled_wrapper">
                <IconButton
                  disabled={statusDataFetching || isSendingBulkMailsLR}
                  onClick={async () => {
                    const date = await LastStatusRefreshDate();
                    const dateDiff = DateDifference(moment(), moment(date), "minutes");
                    const { ledgerRequestStatus } = row.original;

                    setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                    setSendSoleOrBulkMail(EmailSendCount.Sole);
                    getListUserEmailSetting();

                    if (ledgerRequestStatus === LedgerRequestStatuses.RequestLedger) {
                      setAllowSubjectChange(true);
                    } else setAllowSubjectChange(false);

                    if (Math.abs(dateDiff) > 60 && ledgerRequestStatus !== LedgerRequestStatuses.RequestLedger) {
                      setOpenStatusRefreshDialog(true);
                      setDifferenceTime(dateDiff);
                    } else {
                      setOpenSelectEmailTemplate(true);
                    }
                  }}
                >
                  <MailOutline sx={{ fill: "#541c4c" }} />
                </IconButton>
              </span>
            </Tooltip>
            {/* View Email Button */}
            <Tooltip title={"View Email"} arrow={true}>
              <IconButton
                disabled={isSendingBulkMailsLR}
                onClick={async () => {
                  setMailLoading(true);
                  setOpenMailThread(true);
                  setMailThreads([]);
                  setBpName(row.original.businessPartnerName);
                  const { mailThreads, s3Attachments, emailSubject } = await viewEmail(row.original.businessPartnerId);
                  setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                  setMailLoading(false);
                  setMailThreads(mailThreads || []);
                  setS3Attachments(s3Attachments || []);
                  setMailThreadSubject(emailSubject || "");
                  uiLogger(uiLoggerName.ui_ViewEmailClicked, companyId.current, branchCode.current);
                }}
              >
                <Visibility sx={{ fill: "#541c4c" }} />
              </IconButton>
            </Tooltip>
            {/* View Recon */}
            <Tooltip title={"View Recon"} arrow={true}>
              <IconButton
                disabled={isSendingBulkMailsLR}
                onClick={() => {
                  const { businessPartnerId, businessPartnerName } = row.original;
                  const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartnerName)}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank")?.focus();
                }}
              >
                <Cached sx={{ fill: "#541c4c" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history, statusDataReady, statusDataFetching, isSendingBulkMailsLR, pageSelectMode]
  );

  const PartnerCommunicationContextValue: ILedgerRequestContext = {
    rowsDataLedgerRequest,
    storeRowsDataLedgerRequest,
    setRowsDataLedgerRequest,
    setStoreRowsDataLedgerRequest,
    listAllBusinessPartnersWSR: () => listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh(),
  };

  const CheckDateSameWithLastStatusDate = async () => {
    const date = await LastStatusRefreshDate();
    const dateSame = moment().format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY");

    if (!dateSame) {
      listAllBusinessPartnersForLedgerRequest();
    }
  };

  useEffect(() => {
    // listPartnerCommunicationStatusResetMethod();
    listFrequencyTable();

    if (!actor.integration) {
      listAllBusinessPartnerIds();
      // listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh();
      // listAllBusinessPartnersForLedgerRequest();
      listUserEmailTemplateForLedgerRequest("");
      getListUserEmailSetting();
      pollIsLRStatusFetched();
      CheckDateSameWithLastStatusDate();
    }

    // clear All context state on component mount
    ResetContext();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (actor.branchLevelReconcilation ? lastCompany && lastBranch : lastCompany) pollIsLRStatusFetched();
  }, [actor, lastCompany, lastBranch]);

  useEffect(() => {
    if (statusDataFetching) {
      setStatusText("Syncing Now...");
      pollIsLRStatusFetched();
      const intervalsInMs = [10_000];

      intervalsInMs.forEach((interval) => {
        const intervalId = setInterval(() => {
          pollIsLRStatusFetched();
          // if (interval !== 30_000) clearInterval(intervalId);
        }, interval);
        lastInterval.current = intervalId;
      });
    } else {
      window.clearInterval(lastInterval.current);
      if (actor.integration) {
        if (companyId.current) listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh();
      } else listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh();
    }

    return () => window.clearInterval(lastInterval.current);
    // eslint-disable-next-line
  }, [statusDataFetching, actor]);

  const listAllBusinessPartnerIds = async () => {
    await useFetch(API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_IDS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_IDS.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        // storeAllBusinessPartnerIds.current = response.data?.businessPartnerIds;
        storeAllBusinessPartnerCheck.current = response.data?.businessPartnerCheck;
        listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh();
        setRowsDataLedgerRequest(getBlankData());
        setStatusDataReady(false);
      },
    });
  };

  // this API is called repeatedly until errorOccurred or isFetched is true
  const pollIsLRStatusFetched = async () => {
    await useFetch<{ errorOccurred: boolean; isFetched: boolean; lastSyncDate: string }>(
      API_ENDPOINTS.IS_LEDGER_REQUEST_STATUS_FETCHED.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.IS_LEDGER_REQUEST_STATUS_FETCHED.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: async (response) => {
          if (response.data.isFetched || response.data.errorOccurred) {
            setStatusDataFetching(false);
            setStatusButtonClicked(false);
            if (response.data.lastSyncDate) setStatusText(moment(response.data.lastSyncDate).format("DD-MM-YY hh:mmA"));
            if (openSelectMailTempOnFinish.current) setOpenSelectEmailTemplate(true);
            openSelectMailTempOnFinish.current = false;
          }
          if (!response.data.isFetched && !response.data.errorOccurred) setStatusDataFetching(true);
        },
      }
    );
  };

  // this API is called before sending mail to check what was last status refresh time
  const LastStatusRefreshDate = async () => {
    return new Promise<Date>((resolve, reject) => {
      useFetch<{ lastRefreshDate?: Date }>(API_ENDPOINTS.GET_LAST_STATUS_REFRESH_DATE.url, "POST", {
        failureMessage: API_ENDPOINTS.GET_LAST_STATUS_REFRESH_DATE.failureMessage,
        data: {
          templateType: UseCaseType.ledgerRequest,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: (res) => {
          if (res.data.lastRefreshDate) {
            resolve(res.data.lastRefreshDate);
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  // this function calls the API which initiates a backend request to fetch the status(es) for each business partners
  const listAllBusinessPartnersForLedgerRequest = async () => {
    setStatusButtonClicked(true);
    await useFetch<BusinessPartnersListForLedgerRequestRes>(
      API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_FOR_LEDGER_REQUEST.url,
      "POST",
      {
        failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_FOR_LEDGER_REQUEST.failureMessage,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: () => {
          setStatusDataFetching(true);
        },
        catchCallBack: () => {
          setStatusDataFetching(false);
          setStatusButtonClicked(false);
        },
      }
    );
  };

  const listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh = async () => {
    await useFetch<BusinessPartnersListForLedgerRequestRes>(
      API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_FOR_LEDGER_REQUEST_WITHOUT_STATUS_REFRESH.url,
      "GET",
      {
        failureMessage:
          API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_FOR_LEDGER_REQUEST_WITHOUT_STATUS_REFRESH.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (response) => {
          // Extract the businessPartnerId values and store them in a new array
          storeAllBusinessPartnerIds.current = response.data?.businessPartnersList?.map(
            (businessPartner) => businessPartner?.businessPartnerId
          );
          storeLedgerRequestData.current = response.data.businessPartnersList;
          storeAllCategories.current = response.data.businessPartnersList[0]?.allCategories || [];
          setRowsDataLedgerRequest(response.data.businessPartnersList);
          setStoreRowsDataLedgerRequest(response.data.businessPartnersList);
          setLoader(false);
          setStatusDataReady(true);
          if (Object.keys(appliedFilters).length) setReFilter(true);
        },
        catchCallBack: () => {
          setLoader(false);
        },
      }
    );
  };

  const listPDFTemplates = (createTemplateName = "") => {
    useFetch<ListPDFTemplatesResponse>(API_ENDPOINTS.LIST_PDF_TEMPLATES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_PDF_TEMPLATES.failureMessage,
      config: {
        params: {
          templateType: UseCaseType.ledgerRequest,
        },
      },
      thenCallBack: (response) => {
        setPdfTemplates(response.data.templates || []);
        // setPdfTemplates([1, 2, 3, 4, 5].map((id) => ({ templateId: id, templateName: `No. ${id} PDF Template` })));

        if (createTemplateName.trim() !== "") {
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  const listUserEmailTemplateForLedgerRequest = (createTemplateName: string) => {
    useFetch<ListUserEmailTemplateResponse>(API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.failureMessage,
      config: {
        params: {
          templateType: "LR",
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const { emailTemplates, lastEmailTemplateSelected } = response.data;
        setEmailTemplates(emailTemplates);
        listPDFTemplates();

        if (lastEmailTemplateSelected) SetLastSelectedTemplateID(lastEmailTemplateSelected);
        if (createTemplateName.trim() !== "") {
          if (response.data?.emailTemplates !== null && response.data?.emailTemplates.length > 0) {
            const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
              return item.templateName === createTemplateName;
            })[0];
            if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
          }
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          // setSelectedTemplate("");
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  const getListUserEmailSetting = async () => {
    await useFetch<ListUserEmailSettingRes>(API_ENDPOINTS.LIST_USER_EMAIL_SETTING.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_SETTING.failureMessage,
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (
          response.data.userEmailSetting.display_name !== null &&
          response.data.userEmailSetting.display_name !== undefined &&
          response.data.userEmailSetting.display_name !== ""
        ) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (
          response.data.userEmailSetting.from_email !== null &&
          response.data.userEmailSetting.from_email !== undefined &&
          response.data.userEmailSetting.from_email !== ""
        ) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }

        if (
          response.data.userEmailSetting.signature.image !== null &&
          response.data.userEmailSetting.signature.image !== undefined &&
          response.data.userEmailSetting.signature.image !== ""
        ) {
          // setFile(response.data.userEmailSetting.signature.image);
          setFile(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        }
        if (
          response.data.userEmailSetting.signature.text !== null &&
          response.data.userEmailSetting.signature.text !== undefined &&
          response.data.userEmailSetting.signature.text !== ""
        ) {
          setSignature(response.data.userEmailSetting.signature.text);
        }
      },
    });
  };

  const updateOwnAndBusinessPartnerMappingCategories = async (row: any, value: any) => {
    await useFetch(API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.failureMessage,
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        updateCategory(row, value);
      },
    });
  };

  const updateCategory = (row: any, value: any) => {
    storeLedgerRequestData.current.find((v: any) => v.businessPartnerId === row.original.businessPartnerId).category =
      value;
    // setRowsDataLedgerRequest(storeLedgerRequestData.current);
    // setStoreRowsDataLedgerRequest(storeLedgerRequestData.current);
  };

  const downloadEmailAttachments = (bpId: number) => {
    useFetch<{ s3Attachment: S3Attachment[] }>(API_ENDPOINTS.DOWNLOAD_EMAIL_ATTACHMENTS.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_EMAIL_ATTACHMENTS.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: bpId,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        templateType: UseCaseType.ledgerRequest,
      },
      thenCallBack: (_res) => {
        const { s3Attachment } = _res.data;
        if (s3Attachment?.length) {
          const links: HTMLAnchorElement[] = [];
          s3Attachment.forEach(async (attach) => {
            const link = document.createElement("a");
            link.href = attach.s3SignedUrl;
            link.download = attach.fileName;
            link.style.display = "none";
            links.push(link);
            await new Promise((resolve) => setTimeout(resolve, 500));
          });

          let interval: ReturnType<typeof setInterval> = null;
          const download = (urls: HTMLAnchorElement[]) => {
            const url = urls.pop();
            document.body.appendChild(url);
            url.click();
            document.body.removeChild(url);
            if (urls.length === 0) {
              clearInterval(interval);
            }
          };
          interval = setInterval(download, 1000, links);
        }
      },
    });
  };

  const resetStatesForDemoSales = async () => {
    await useFetch(API_ENDPOINTS.RESET_STATES_FOR_DEMO_SALES.url, "POST", {
      failureMessage: API_ENDPOINTS.RESET_STATES_FOR_DEMO_SALES.failureMessage,
      thenCallBack: (response) => {
        setShowLoadingIcon(false);
        listAllBusinessPartnerIds();
        // listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh();
        // listAllBusinessPartnersForLedgerRequest();
        setShowResetButton(false);
        if (response.data.message) {
          toast.warning(<CustomToast message={response?.data?.message} />);
        }
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  useEffect(() => {
    // add event listener to the document object
    document.addEventListener("click", handleClickOutside);

    return () => {
      // remove event listener when component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    // check if click target is outside of the button
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setShowResetButton(false);
    }
  };

  const companyNameSelect = (e: any, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        listAllBusinessPartnerIds(); // after this call, rows api auto called
        listUserEmailTemplateForLedgerRequest("");
        getListUserEmailSetting();
        getBulkMailStatus();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const AfterListCompanies = (_res: ListOwnTallyCompaniesRes | ListCompaniesRes) => {
    if (!actor.branchLevelReconcilation) CheckDateSameWithLastStatusDate();
  };

  const branchNameSelect = (e: any, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        listAllBusinessPartnerIds(); // after this call, rows api auto called
        listUserEmailTemplateForLedgerRequest("");
        getListUserEmailSetting();
        getBulkMailStatus();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };

  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setLastBranch(null);
    setRowsDataLedgerRequest([]);
    setStoreRowsDataLedgerRequest([]);
  };

  const AfterListBranches = (_res: ListBranchesRes) => {
    if (!actor.branchLevelReconcilation) CheckDateSameWithLastStatusDate();
  };

  const getDownloadLedgerRequestReport = () => {
    useFetch(API_ENDPOINTS.DOWNLOAD_LEDGER_REQUEST_REPORT.url, "GET", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_LEDGER_REQUEST_REPORT.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        setDownloadLedgerRequestReportBase64(response.data?.reportWorkbookBase64);
        downloadLedgerRequestReport(response.data?.reportWorkbookBase64);
      },
    });
  };
  const downloadLedgerRequestReport = (reportBase64) => {
    const excelData = Buffer.from(reportBase64 || downloadLedgerRequestReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Ledger Request.xlsx`);
  };

  const deleteUserEmailTemplate = (templateId) => {
    setShowDeleteLoadingIcon(true);
    useFetch(API_ENDPOINTS.DELETE_USER_EMAIL_TEMPLATE.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_USER_EMAIL_TEMPLATE.failureMessage,
      config: {
        data: {
          useCaseType: UseCaseType.ledgerRequest,
          templateId,
        },
      },
      thenCallBack: () => {
        listUserEmailTemplateForLedgerRequest("");
        handleCloseDeleteModal();
        setShowDeleteLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowDeleteLoadingIcon(false);
      },
    });
  };

  const listFrequencyTable = () => {
    useFetch(API_ENDPOINTS.LIST_FREQUENCY_TABLE.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_FREQUENCY_TABLE.failureMessage,
      thenCallBack: (response) => {
        const frequencyArray = response.data?.frequencyTableSettingsFilteredByCategories?.map(
          (item: { frequency: string }) => item.frequency
        );
        setFrequencyTableSettings(response.data?.frequencyTableSettingsFilteredByCategories);
        setOptionsLedgerRequestFrequency(frequencyArray);
      },
    });
  };

  const viewEmail = (businessPartner: number) => {
    return new Promise<ViewEmailThreadResponse>((resolve, reject) => {
      useFetch<ViewEmailThreadResponse>(API_ENDPOINTS.VIEW_EMAIL.url, "POST", {
        failureMessage: API_ENDPOINTS.VIEW_EMAIL.failureMessage,
        data: {
          businessPartnerId: businessPartner,
          ownCompanyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          businessPartnerCompanyId: null,
          templateType: UseCaseType.ledgerRequest,
        },
        thenCallBack: (response) => {
          if (response.data.mailThreads)
            resolve({
              mailThreads: response.data.mailThreads,
              s3Attachments: response.data.s3Attachments,
              emailSubject: response.data.emailSubject,
            });
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const getEmailsSentInBulkStatus = () => {
    return new Promise((resolve) => {
      useFetch<BulkMailStatusResponse>(API_ENDPOINTS.GET_EMAILS_SENT_IN_BULK_STATUS.url, "POST", {
        failureMessage: API_ENDPOINTS.GET_EMAILS_SENT_IN_BULK_STATUS.failureMessage,
        data: {
          templateType: UseCaseType.ledgerRequest,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: (response) => {
          if (response.data.isComplete) {
            if (response.data?.emailResponseMessage !== "") {
              listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh();
              toast.success(<CustomToast message={response.data?.emailResponseMessage} />);
            }

            // Stop the interval and cleanup
            clearInterval(intervalId);
            intervalId = null;
            dispatch(updateIsSendingBulkMailsLR(false));

            if (response.data.listOfFailedUsers && response.data.listOfFailedUsers?.length) {
              if (openBulkMailReportDialogLR) return;
              dispatch(updateOpenBulkMailReportDialogLR(true));
              dispatch(updateEmailResponseMsgLR(response.data.emailResponseMessage || ""));
              dispatch(updateMailFailedUsersLR(response.data.listOfFailedUsers || []));
              dispatch(updateMailFailedWorkbookLR(response.data?.failedEmailsWorkbook || ""));
              // reload templates after mail sent
              listUserEmailTemplateForLedgerRequest("");
            }
          } else {
            dispatch(updateIsSendingBulkMailsLR(true));
          }
          return resolve(response.data.isComplete);
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsLR(false));
          return resolve(true);
        },
      });
    });
  };
  const setIntervalLR = () => {
    intervalId = setInterval(async () => {
      const isComplete = await getEmailsSentInBulkStatus();
      if (isComplete) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30000);
  };

  const getBulkMailStatus = async () => {
    useFetch(API_ENDPOINTS.IS_BULK_EMAIL_COMPLETE.url, "GET", {
      failureMessage: API_ENDPOINTS.IS_BULK_EMAIL_COMPLETE.failureMessage,
      config: {
        params: {
          templateType: UseCaseType.ledgerRequest,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (res) => {
        if (res.data.isComplete === false && !intervalId) {
          setIntervalLR();
          dispatch(updateIsSendingBulkMailsLR(true));
        }
      },
    });
  };

  useEffect(() => {
    if (actor.integration === false) getBulkMailStatus();
  }, []);

  const GREEN = "#27B27C";

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Ledger Request", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <LedgerRequestContext.Provider value={PartnerCommunicationContextValue}>
        {
          <Grid className="table_list">
            <Grid position="relative">
              <Grid className="vertical_center_align mb_15" sx={{ gap: 2, flexWrap: "wrap" }}>
                {/* Left Side Dropdowns and Status Refresh Buttons */}
                <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
                  {!openLedgerRequestSettings && (
                    <>
                      {actor.integration === true && (
                        <IntegratedDropDown
                          tallyCompanyNameSelect={companyNameSelect}
                          AfterListOwnTallyCompanies={AfterListCompanies}
                          companyNameSelect={companyNameSelect}
                          AfterListCompanies={AfterListCompanies}
                          branchNameSelect={branchNameSelect}
                          AfterListBranches={AfterListBranches}
                          isDisabled={isSendingBulkMailsLR}
                        />
                      )}
                      <Button
                        variant="contained"
                        className="theme_btn"
                        disabled={statusButtonClicked || isSendingBulkMailsLR}
                        onClick={() => {
                          listAllBusinessPartnersForLedgerRequest();
                          uiLogger(uiLoggerName.ui_RefreshStatus, companyId.current, branchCode.current);
                        }}
                        startIcon={<Sync className={statusDataFetching ? "loading-active" : ""} />}
                      >
                        Refresh status
                      </Button>

                      <div>
                        <div style={{ fontSize: "10px" }}>Last Status Updated at:</div>
                        <Chip label={statusText} size="small" />
                      </div>
                      {((actor.id === 2866 && import.meta.env.VITE_APP_NAKAD_ENV === "production") ||
                        (actor.id === 1882 && import.meta.env.VITE_APP_NAKAD_ENV === "staging") ||
                        (actor.id === 11920 && import.meta.env.VITE_APP_NAKAD_ENV === "test")) && (
                        <span
                          ref={buttonRef}
                          onClick={() => {
                            setShowResetButton(true);
                          }}
                          style={{ width: "155px", marginLeft: "60px" }}
                        >
                          {showResetButton && (
                            <Button
                              variant="contained"
                              className="theme_outline_btn"
                              onClick={() => {
                                setShowLoadingIcon(true);
                                resetStatesForDemoSales();
                              }}
                            >
                              {<LoadingIcon loading={showLoadingIcon} />}
                              Reset States
                            </Button>
                          )}
                        </span>
                      )}
                    </>
                  )}
                </div>

                {/* Settings Back Button */}
                {openLedgerRequestSettings && (
                  <Tooltip title="Back" arrow={true}>
                    <Avatar sx={{ background: GREEN, ml: "auto", mr: 5, zIndex: 3 }}>
                      <IconButton onClick={() => setOpenLedgerRequestSettings(false)} color="inherit">
                        <span className="fa-icon">
                          <i className="fas fa-arrow-left" />
                        </span>
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                )}

                {/* Right Side Buttons */}
                {!openLedgerRequestSettings && (
                  <div className="d_flex" style={{ gap: 16, flexWrap: "wrap", marginLeft: "auto" }}>
                    <Tooltip title="Ledger Request Settings" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_ClickedOnSetting, companyId.current, branchCode.current)
                        }
                      >
                        <IconButton
                          onClick={() => setOpenLedgerRequestSettings(true)}
                          color="inherit"
                          disabled={isSendingBulkMailsLR}
                        >
                          <Settings />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip
                      title={!statusDataReady || statusDataFetching ? "Please wait till status gets updated" : "Filter"}
                      arrow={true}
                    >
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_ClickedOnFilter, companyId.current, branchCode.current)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <IconButton
                            onClick={() => setOpenLedgerRequestFilter(true)}
                            color="inherit"
                            disabled={!statusDataReady}
                            sx={{
                              pointerEvents: "auto !important",
                            }}
                          >
                            {isFilterAppliedLedgerRequest ? <FilterAlt /> : <FilterAltOff />}
                          </IconButton>
                        </span>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Bulk Email" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_clickedOnBulkEmail, companyId.current, branchCode.current)
                        }
                      >
                        <IconButton
                          onClick={async () => {
                            const date = await LastStatusRefreshDate();
                            const dateDiff = DateDifference(moment(), moment(date), "minutes");

                            const _foundImposterRow = selectedRow?.find(
                              (row) => row.ledgerRequestStatus !== LedgerRequestStatuses.RequestLedger
                            );

                            if (_foundImposterRow) setAllowSubjectChange(false);
                            else setAllowSubjectChange(true);

                            setSendSoleOrBulkMail(EmailSendCount.Bulk);

                            if (Math.abs(dateDiff) > 60) {
                              setOpenStatusRefreshDialog(true);
                              setDifferenceTime(dateDiff);
                            } else {
                              setOpenSelectEmailTemplate(true);
                            }
                          }}
                          disabled={statusDataFetching || (selectedRow.length < 2 ? true : isSendingBulkMailsLR)}
                          color="inherit"
                        >
                          {isSendingBulkMailsLR ? (
                            <span
                              className="file-icon"
                              style={{
                                position: "absolute",
                                transform: "scale(2.5)",
                                left: 8,
                                color: "white",
                              }}
                            >
                              <i className="fas fa-circle-notch fa-spin" />
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="fa-icon">
                            <i className="fas fa-envelope" />
                          </span>
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download Ledger Request Report" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_DownloadReport, companyId.current, branchCode.current)}
                      >
                        <IconButton
                          onClick={() => getDownloadLedgerRequestReport()}
                          color="inherit"
                          disabled={isSendingBulkMailsLR}
                        >
                          <Download />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </div>
                )}
              </Grid>

              {/* Filter Applied Bar */}
              {!openLedgerRequestSettings && Object.keys(appliedFilters).length > 0 && (
                <FilterAppliedBar appliedFilters={appliedFilters} />
              )}

              <div
                className="bpDashboard"
                style={{
                  height: "60vh",
                  position: "relative",
                  width: "100%",
                  top: openLedgerRequestSettings ? "-50px" : "unset",
                }}
              >
                <Grid
                  sx={{
                    "& > div": {
                      animation: "fade-in 0.25s ease-out",
                    },
                  }}
                >
                  {!openLedgerRequestSettings && (
                    <BpDashboardTable
                      columns={columnDefinitionLedgerRequest}
                      rows={rowsDataLedgerRequest}
                      sortEnable={true}
                      setSelectedRow={setSelectedRow as StateDispatch<any[]>}
                      actorType="BPDashboard"
                      loading={loader || !statusDataReady}
                      truncateHeaders={true}
                      renderBottomToolbarCustomActions={() => {
                        return (
                          <PageSelectModeToggle
                            pageSelectMode={pageSelectMode}
                            setPageSelectMode={setPageSelectMode}
                            useCaseType={UseCaseType.balanceConfirmationBeta}
                            companyId={companyId.current}
                            branchCode={branchCode.current}
                          />
                        );
                      }}
                    />
                  )}
                  {openLedgerRequestSettings && (
                    <LedgerRequestSettings
                      frequencyTableSettings={frequencyTableSettings}
                      optionsLedgerRequestFrequency={optionsLedgerRequestFrequency}
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                      listUserEmailTemplate={listUserEmailTemplateForLedgerRequest}
                    />
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }
        <LedgerRequestFilter
          openLedgerRequestFilter={openLedgerRequestFilter}
          setOpenLedgerRequestFilter={setOpenLedgerRequestFilter}
          allCategories={storeAllCategories.current}
          rowsDataLedgerRequest={rowsDataLedgerRequest}
          setRowsDataLedgerRequest={setRowsDataLedgerRequest}
          storeRowsDataLedgerRequest={storeRowsDataLedgerRequest}
          setIsFilterAppliedLedgerRequest={setIsFilterAppliedLedgerRequest}
          reFilter={reFilter}
          setReFilter={setReFilter}
          setAppliedFilters={setAppliedFilters}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />

        {/* Ledger request */}
        <SelectEmailTemplate
          deleteUserEmailTemplate={(templateID) => {
            deleteUserEmailTemplate(templateID);
          }}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          lastSelectedTemplateID={lastSelectedTemplateID}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
        <SendEmailTemplate
          companyId={companyId.current}
          branchCode={branchCode.current}
          base64Image={file}
          signature={signature}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          selectedRow={selectedRow}
          listAllBusinessPartnerMapping={listAllBusinessPartnersForLedgerRequest}
          // after sending mail to update date
          listAllBusinessPartnersWSR={listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh}
          // setLoader={setLoader}
          partnerCommunicationSelected={PartnerCommunicationSelected.ledgerRequest}
          templateType={"LR"}
          listUserEmailTemplate={listUserEmailTemplateForLedgerRequest}
          storeAllBusinessPartnerCheck={storeAllBusinessPartnerCheck}
          setIntervalBulkMails={setIntervalLR}
        />
        <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
          companyId={companyId.current}
          branchCode={branchCode.current}
          listUserEmailTemplate={listUserEmailTemplateForLedgerRequest}
          templateType={"LR"}
        />

        {/* ListBusinessPartnerUsers */}
        <Dialog
          open={openListBusinessPartnerUsers}
          onClose={() => setOpenListBusinessPartnerUsers(true)}
          width70Per={true}
        >
          <ListBusinessPartnerUsers
            setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
            storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        </Dialog>

        <MailThreadDialog
          open={openMailThread}
          setOpen={setOpenMailThread}
          mailThreads={mailThreads}
          s3Attachments={s3Attachments}
          businessPartnerName={bpName}
          loading={mailLoading}
          companyId={companyId.current}
          branchCode={branchCode.current}
          useCaseType={UseCaseType.ledgerRequest}
          emailSubject={mailThreadSubject}
          listAllBusinessPartnersWSR={listAllBusinessPartnersForLedgerRequestWithoutStatusRefresh}
        />

        <StatusRefreshDialog
          open={openStatusRefreshDialog}
          setOpen={setOpenStatusRefreshDialog}
          time={differenceTime}
          refreshFn={async () => {
            await listAllBusinessPartnersForLedgerRequest();
            openSelectMailTempOnFinish.current = true;
          }}
        />
        <BulkMailReportDialog
          open={openBulkMailReportDialogLR}
          setOpen={(value) => {
            dispatch(updateOpenBulkMailReportDialogLR(value));
          }}
          emailResponseMsg={emailResponseMsgLR}
          mailFailedUsers={mailFailedUsersLR}
          workbook={mailFailedWorkbookLR}
        />
      </LedgerRequestContext.Provider>
    </LoggedInSkeleton>
  );
};

export default LedgerRequest;
