import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { uiLoggerNamesPartnerRiskManagement } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import {
  FUNNEL_REPORT_COLUMNS,
  MIS_FILTERS_MAPPING,
  renderValueForMISFilters,
  REPORT_TYPES,
  REPORT_VIEWS_MAPPING,
  STATUS_REPORT_COLUMNS,
} from "./Interfaces";

const ViewReportCustomisationsModal = ({ open, setOpen, reportDetails, setPreviewReportDetails }) => {
  const entries = Object.entries(reportDetails.filters);
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px 24px" }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Report View and Filters
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
          }}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        {reportDetails.views.length === 0 && entries.length === 0 && reportDetails.hiddenColumns.length === 0 && (
          <Box sx={{ height: "320px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="body1" className="fw_400 fs_16" color={"rgba(0, 0, 0, 0.38)"}>
              No view or filter applied
            </Typography>
          </Box>
        )}
        {reportDetails.views.length > 0 && (
          <Box sx={{ mb: 2, border: "1px solid #e0e0e0", borderRadius: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                gap: 1,
              }}
            >
              <ViewModuleIcon color="primary" />
              <Typography variant="body2">Report View</Typography>
            </Box>
            <Divider />

            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, padding: 1, overflow: "scroll" }}>
              {reportDetails.views.map((item, index) => (
                <React.Fragment key={item}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                      lineHeight: "166%",
                      color: "#00000061",
                    }}
                  >
                    {index === 0 ? "Showing data basis on" : "then"}
                  </Typography>
                  <Chip
                    label={REPORT_VIEWS_MAPPING[item]}
                    sx={{
                      "& .MuiChip-label": { fontSize: 13, padding: 1 },
                    }}
                  />
                </React.Fragment>
              ))}
            </Box>
          </Box>
        )}
        {entries.length > 0 && (
          <Box sx={{ my: 2, border: "1px solid #e0e0e0", borderRadius: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                gap: 1,
              }}
            >
              <FilterListIcon color="primary" />
              <Typography variant="body2">Filters Applied</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: 1,
                maxHeight: "200px",
                overflow: "auto",
              }}
            >
              {entries.map(([key, value]) => (
                <Box key={key} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                      lineHeight: "166%",
                      color: "#00000061",
                    }}
                  >
                    {MIS_FILTERS_MAPPING[key]}
                  </Typography>
                  <Chip
                    label={renderValueForMISFilters(key, value)}
                    sx={{
                      "& .MuiChip-label": { fontSize: 13, padding: 1 },
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {reportDetails.hiddenColumns.length > 0 && (
          <Box sx={{ mt: 2, border: "1px solid #e0e0e0", borderRadius: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                gap: 1,
              }}
            >
              <RemoveRedEyeIcon color="primary" />
              <Typography variant="body2">Columns Hidden</Typography>
            </Box>
            <Divider />
            <Box sx={{ padding: 1, display: "flex", flexWrap: "wrap", gap: 1, maxHeight: "120px", overflow: "auto" }}>
              {reportDetails.hiddenColumns.map((column) => (
                <Chip
                  key={column}
                  label={
                    reportDetails.reportType === REPORT_TYPES.Funnel
                      ? FUNNEL_REPORT_COLUMNS[column]
                      : STATUS_REPORT_COLUMNS[column]
                  }
                  sx={{
                    "& .MuiChip-label": { fontSize: 13, padding: 1 },
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => setOpen(false)} sx={{ color: "#00000099" }}>
          Close
        </Button>
        <NdButton
          variant="contained"
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_REPORTVIEWSANDFILTERS_EDIT_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              {
                message:
                  uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MOREOPTIONS_REPORTVIEWSANDFILTERS_EDIT_CLICK.message,
              }
            );
            setOpen(false);
            setPreviewReportDetails({
              id: reportDetails.id,
              reportType: reportDetails.reportType,
              reportAction: "editReport",
            });
          }}
        >
          Edit
        </NdButton>
      </DialogActions>
    </Dialog>
  );
};

export default ViewReportCustomisationsModal;
