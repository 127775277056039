import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import BalanceConfirmationPortal from "../BalanceConfirmationPortal/BalanceConfirmationPortal";
import { CommunicationDetails } from "../BalanceConfirmationPortal/BalanceConfirmationPortalCommon";
import BalanceConfirmationPortalContext from "../BalanceConfirmationPortal/BalanceConfirmationPortalContext";
import LedgerRequestPortal from "../LedgerRequestPortal/LedgerRequestPortal";
import { LoadingPage } from "../MsmePartnerPortal/PartnerPortal.common";

const PartnerPortalWrapper = () => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  const [balanceConfirmation, setBalanceConfirmation] = useState(undefined);

  const GetBalanceConfirmationEnabled = () => {
    return useFetch<CommunicationDetails>(API_ENDPOINTS.GET_COMMUNICATION_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_COMMUNICATION_DETAILS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        if (typeof res.data?.isBalanceConfirmationEnabled === "boolean")
          setBalanceConfirmation(Boolean(res.data.isBalanceConfirmationEnabled));
      },
    });
  };

  useEffect(() => {
    GetBalanceConfirmationEnabled();
  }, []);

  console.log({ balanceConfirmation });

  return (
    <>
      <BalanceConfirmationPortalContext>
        {typeof balanceConfirmation === "undefined" ? (
          <LoadingPage />
        ) : balanceConfirmation ? (
          <BalanceConfirmationPortal />
        ) : (
          <LedgerRequestPortal />
        )}
      </BalanceConfirmationPortalContext>
    </>
  );
};

export default PartnerPortalWrapper;
