import { MenuItem, Pagination, Select, Skeleton, Tooltip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
// import { useBlockLayout, useExpanded, useFilters, usePagination, useRowSelect, useSortBy } from "react-table";
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { ColumnFilter } from "./ColumnFilter";
import "./Table.scss";

const defaultPropGetter = () => ({});

const Styles = styled.div`
  .table {
    // border: 1px solid #ddd;
    border-collapse: collapse;

    thead tr {
      background-color: #531c4c;
      user-select: none;
    }
    tbody tr {
      align-items: center;
      min-height: 30px;
      font-family: "WorkSans";
    }

    tr.evenRow {
      background-color: #ffffff;
      // border-bottom: 0.1px solid #6c6c6c;
    }

    tr.oddRow {
      background-color: #eaf0f9;
      // border-bottom: 0.1px solid #6c6c6c;
    }
    tr:last-child {
      border-bottom: 1px solid #dbdbdb;
    }

    th {
      display: inline-flex !important;
      justify-content: center;
      align-items: center;
      font-family: "WorkSans";
      text-align: center;
      background-color: #531c4c !important;
      color: white;
      padding: 8px 0px 8px 0px;
      border-bottom: 1px solid #53526c;
    }
    .th-bg {
      background-color: #391033;
    }
    td {
      padding: 8px 4px;
      font-family: "WorkSans";
      text-align: center;
      color: black;
      border: none;
      min-height: 30px;
      word-break: break-word;

      &:not(.noHide),
      &:not(:has(.noHide)) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:has(.noHide) {
        overflow: auto;
        text-overflow: initial;
      }

      &:not(.noHide) > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & > *.noHide {
        overflow: auto;
        text-overflow: initial;
        white-space: nowrap;
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        background-color: inherit;
      }
      .stickyLeft,
      .stickyRight {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        left: var(--data-val, 0);
        height: var(--height, 30px);
        z-index: 2;
        background-color: inherit;
      }
      .stickyRight {
        left: unset;
        right: var(--data-val, 0);
      }

      [data-sticky-last-left-td],
      [data-sticky-first-right-td] {
        content: " ";
        // box-shadow: -4px 0 8px -6px rgba(0, 0, 0, 0.2) inset;
        clip-path: inset(0px -30px 0px 0px);
        box-shadow: -4px 0px 10px 2px rgba(0, 0, 0, 0.2);
      }

      [data-sticky-first-right-td] {
        clip-path: inset(0px 0px 0px -30px);
        box-shadow: -3px 0px 10px 1px rgb(0 0 0 / 10%);
      }

      tr:first-child th:last-child {
        border-top-right-radius: 12px;
      }
    }
  }
`;

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type Modify<T, R> = Omit<T, keyof R>;

type _PartialColumnDef = Modify<
  ColumnDef<any, any>,
  {
    accessorFn?: () => undefined;
  }
>;

type ColumnDefs = _PartialColumnDef & { sticky?: "left" | "right" };

enum stickyClassName {
  left = "stickyLeft",
  right = "stickyRight",
}

type CallableUpdater<T = any> = (old: T) => T;

interface TableProps<T extends object> {
  columns: ColumnDefs[];
  rows: T[];
  sortEnable?: boolean;
  setSelectedRow?: StateDispatch<T[]> | StateDispatch<Record<string, unknown>[]>;
  actorType?: string;
  id?: string;
  loading?: boolean;
  reconInsightSortOn?: string;
  fromTab?: string;
  callApiOnPagination?: () => undefined | void;
  getCellProps?: (props: any) => Record<string, unknown>;
  renderRowSubComponent?: (...props: any) => undefined;
  setTableInstance?: StateDispatch<Table<any>>;
  setAllLeafColumns?: StateDispatch<Column<any>[]>;
  truncateHeaders?: boolean;
  savedSortState?: SortingState;
  setSavedSortingState?: StateDispatch<SortingState>;
  paddings?: "none" | "minimal" | "full" | number;
  renderBottomToolbarCustomActions?: () => React.ReactNode;
}

function BpDashboardTable<T extends object>({
  columns,
  rows,
  getCellProps = defaultPropGetter,
  id,
  loading,
  // sortEnable,
  setSelectedRow,
  actorType,
  reconInsightSortOn,
  fromTab,
  callApiOnPagination,
  setTableInstance,
  setAllLeafColumns,
  paddings = "full",
  ...props
}: TableProps<T>) {
  const columnsData = useMemo(() => columns, [columns]);

  const rowsData = useMemo(() => rows, [rows]);

  const [rowSelection, setRowSelection] = React.useState({});
  const [sortingState, setSortingState] = React.useState<SortingState>([]);

  const tableInstance = useReactTable({
    columns: columnsData as ColumnDef<any>[],
    data: rowsData,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageSize: 100 } },
    state: {
      rowSelection,
      globalFilter: ColumnFilter,
      sorting: props.savedSortState?.length ? props.savedSortState : sortingState,
    },
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: (sortingFn: SortingState | CallableUpdater<SortingState>) => {
      const _currentSortState = (sortingFn as CallableUpdater<SortingState>)(sortingState);
      setSortingState(_currentSortState);
      if (props.setSavedSortingState) props.setSavedSortingState(_currentSortState);
    },
  });

  const {
    getHeaderGroups,
    getRowModel,
    getPageCount,
    setPageSize,
    setPageIndex,
    getState,
    setSorting,
    getFilteredRowModel,
    getSelectedRowModel,
    getAllLeafColumns,
  } = tableInstance;

  const {
    pagination: { pageIndex, pageSize },
  } = getState();

  const isOverFlowing = (element: Element) => {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth ||
      element?.children[0]?.scrollWidth > element?.children[0]?.clientWidth
    );
  };

  const setCSSVarsOnStickyEl = (elArr: NodeListOf<Element>, reverse = false) => {
    elArr.forEach((stickyEl, i) => {
      const el = stickyEl as HTMLDivElement;
      const lastI = elArr.length - 1;
      let prevWidths = 0;
      let nextWidths = 0;
      Array.from(elArr)
        .slice(0, i)
        ?.forEach((_el) => (prevWidths += _el.clientWidth));
      Array.from(elArr)
        .slice(i + 1, lastI + 1)
        ?.forEach((_el) => (nextWidths += _el.clientWidth));

      const leftNextWidth = i !== 0 ? prevWidths.toString() + "px" : "0";
      const rightPrevWidth = i !== lastI ? nextWidths.toString() + "px" : "0";
      el.style.setProperty("--height", el.parentElement.scrollHeight + "px");

      if (reverse) {
        el.style.setProperty("--data-val", rightPrevWidth);
        elArr[0].setAttribute(`data-sticky-first-right-td`, "");
      } else {
        el.style.setProperty("--data-val", leftNextWidth);
        i === lastI
          ? elArr[i].setAttribute(`data-sticky-last-left-td`, "")
          : elArr[i].removeAttribute(`data-sticky-last-left-td`);
      }
    });
  };

  useEffect(() => {
    const pageCount = getPageCount();
    if (pageIndex > pageCount - 1) setPageIndex(0);
  }, [rowsData]);

  useEffect(() => {
    if (actorType === "admin" || actorType === "BPDashboard") {
      const filterSelectedFlatRows = getSelectedRowModel().flatRows.map((item) => item.original);

      if (setSelectedRow) setSelectedRow(filterSelectedFlatRows);
    }

    if (setTableInstance && tableInstance) {
      setTableInstance(tableInstance);
    }

    if (setAllLeafColumns && getAllLeafColumns) {
      setAllLeafColumns(getAllLeafColumns());
    }
  }, [
    actorType,
    getAllLeafColumns,
    getSelectedRowModel,
    rowSelection,
    setAllLeafColumns,
    setSelectedRow,
    setTableInstance,
    tableInstance,
  ]);

  useEffect(() => {
    if (fromTab === "reconMIS") {
      setSorting([{ id: reconInsightSortOn, desc: true }]);
    }
    // eslint-disable-next-line
  }, []);
  const callApiOnPaginationHandler = () => {
    // this will no need when pagination apply on all table
    if (fromTab === "reconMIS") {
      callApiOnPagination();
    }
  };

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rowsData.length < pageSize ? rowsData.length : pageSize,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: (_i) => (fromTab !== "ManualRecon" ? 45 : 30),
    overscan: 5,
  });

  const rowModelRows = getRowModel().rows;

  const getPixelsToCh = (width: number, fontSize: number) => Math.floor(width / ((fontSize / 16) * 8));

  const CardPadding = paddings === "full" ? 2.5 : paddings === "minimal" ? 0.5 : paddings === "none" ? null : paddings;

  return (
    <>
      <Card
        sx={{
          overflowX: "scroll",
          p: fromTab !== "ManualRecon" ? CardPadding : null,
          borderRadius: fromTab !== "ManualRecon" ? 1.5 : null,
        }}
      >
        {/* <CssBaseline /> */}
        <Styles>
          <div
            ref={tableContainerRef}
            className="table_fixed_div"
            style={{ borderRadius: fromTab !== "ManualRecon" ? "12px" : null }}
          >
            <table
              id={id}
              style={{
                borderRadius: fromTab !== "ManualRecon" ? "1%" : null,
                maxWidth: fromTab === "ManualRecon" || fromTab === "CustomGapDescription" ? null : 1000,
                minHeight: fromTab === "CustomGapDescription" ? 150 : 250,
                height: `${rowVirtualizer.getTotalSize() + 50}px`,
                position: "relative",
              }}
              className="table sticky"
            >
              <thead>
                {getHeaderGroups().map((headerGroup, iHead) => (
                  <tr key={"thead_tr" + iHead} className="tr" style={{ display: "flex" }}>
                    {headerGroup.headers.map((header, iRow) => {
                      const isSorted = header.column.getIsSorted();
                      const sticky = (header.column.columnDef as ColumnDefs).sticky;

                      return (
                        <th
                          colSpan={header.colSpan}
                          key={header.id + iRow}
                          // table returns minimum 20px for cells even with 0 width
                          style={{ width: header.getSize(), gap: 8, padding: fromTab !== "ManualRecon" ? "" : "0px" }}
                          onClick={header.column.getToggleSortingHandler()}
                          className={header.column.getCanSort() ? "" : "noSort"}
                          ref={(th) => {
                            if (th && isOverFlowing(th)) th.title = th.innerText;
                            if (th) {
                              if (stickyClassName[sticky]) th.classList.add(stickyClassName[sticky]);
                              const leftStick = th.parentElement.querySelectorAll("th.stickyLeft");
                              const rightStick = th.parentElement.querySelectorAll("th.stickyRight");
                              setCSSVarsOnStickyEl(leftStick);
                              setCSSVarsOnStickyEl(rightStick, true);
                            }
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <div className={props.truncateHeaders ? "textOverflow_hidden" : "header_node"}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                          )}{" "}
                          {isSorted && (
                            <div
                              style={{ transition: "all .25s ease-out" }}
                              ref={(div) => div?.classList?.toggle("rotated", isSorted === "desc")}
                            >
                              <i className="fa fa-long-arrow-alt-up" aria-hidden="true" />
                            </div>
                          )}
                          {header.column.getCanSort() && isSorted === false && (
                            <div className="header">
                              <i className="sort-icon fa fa-sort" aria-hidden="true" />
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody className="body">
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  return (
                    <tr
                      key={"tbody_tr" + virtualRow.index}
                      className={virtualRow.index % 2 ? "oddRow" : "evenRow"}
                      style={{
                        display: "flex",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        // height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                    >
                      {rowModelRows[virtualRow.index]?.getVisibleCells().map((cell, iCell) => {
                        const sticky = (cell.column.columnDef as ColumnDefs).sticky;
                        return (
                          <td
                            key={cell.id + iCell}
                            // table returns minimum 20px for cells even with 0 width
                            style={{ width: cell.column.getSize(), padding: fromTab !== "ManualRecon" ? "" : "0" }}
                            ref={(td) => {
                              if (td && isOverFlowing(td)) {
                                if (td.innerText) {
                                  if (fromTab !== "ManualRecon") td.title = td.innerText;
                                  td.setAttribute("data-tooltip", td.innerText);
                                }
                              }
                              if (td) {
                                if (stickyClassName[sticky]) td.classList.add(stickyClassName[sticky]);
                                const leftStick = td.parentElement.querySelectorAll("td.stickyLeft");
                                const rightStick = td.parentElement.querySelectorAll("td.stickyRight");
                                setCSSVarsOnStickyEl(leftStick);
                                setCSSVarsOnStickyEl(rightStick, true);
                              }
                            }}
                            {...getCellProps(cell)}
                          >
                            {loading ? (
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: 12, borderRadius: 2, width: "100%" }}
                                animation="wave"
                              />
                            ) : (
                              <>
                                {fromTab === "ManualRecon" &&
                                cell.getValue() &&
                                getPixelsToCh(cell.column.getSize(), 16) <= String(cell.getValue()).length ? (
                                  <Tooltip title={String(cell.getValue())}>
                                    <span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                                  </Tooltip>
                                ) : (
                                  flexRender(cell.column.columnDef.cell, cell.getContext())
                                )}
                              </>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Styles>
        {/* Pagination And Table Footer Component */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: fromTab !== "ManualRecon" ? 15 : 5,
            paddingLeft: 10,
            marginTop: fromTab !== "ManualRecon" ? 15 : 5,
            marginBottom: fromTab !== "ManualRecon" ? 10 : 5,
            textAlign: "center",
          }}
        >
          <Typography variant="body2">
            {`${pageSize * pageIndex + 1}–${
              pageSize * (pageIndex + 1) < getFilteredRowModel().rows.length
                ? pageSize * (pageIndex + 1)
                : getFilteredRowModel().rows.length
            } of ${getFilteredRowModel().rows.length}`}
          </Typography>
          <Select
            value={pageSize}
            size="small"
            sx={{
              "&>div": { py: "2px" },
              "& > fieldset": { borderWidth: "0.5px" },
            }}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50, 100, 200].map((PageSize) => (
              <MenuItem key={PageSize} value={PageSize}>
                {PageSize} per Page
              </MenuItem>
            ))}
          </Select>
          {props.renderBottomToolbarCustomActions && props.renderBottomToolbarCustomActions()}
          <div style={{ flex: 1 }} />
          <Pagination
            count={getPageCount()}
            page={pageIndex + 1}
            defaultPage={1}
            siblingCount={2}
            boundaryCount={2}
            onChange={(_e, pageNum) => {
              setPageIndex(pageNum - 1);
              callApiOnPaginationHandler();
            }}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "rgba(84, 28, 76, 0.1) !important",
              },
            }}
          />
        </div>
        {/* <NgTooltip /> */}
      </Card>
    </>
  );
}

export default BpDashboardTable;
