import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";

export default function AddReportConfirmationModal({ open, setOpen, reportAction, handleSaveReport, isSavingReport }) {
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>
        You haven’t updated the report preview, are you sure you want to{" "}
        {reportAction === "editReport" ? "save" : "add"} report?
      </DialogTitle>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          disabled={isSavingReport}
          variant="contained"
          color="error"
          sx={{ borderRadius: "4px !important" }}
          onClick={() => {
            handleSaveReport();
          }}
        >
          {isSavingReport && <LoadingIcon loading={true} />}
          {reportAction === "editReport" ? "Update and Save" : "Update and Add"} Report
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
