import React, { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { User } from "../../PartnerCommunication/BalanceConfirmationBeta/MoveTaskToReconModal";
import { TabPanel } from "../../ReconSettings/ReconSettings";
import AddMISReport from "./AddMISReport";
import AddNewReportModal from "./AddNewReportModal";
import AppliedFiltersBar from "./AppliedFiltersBar";
import Header from "./Header";
import { ListAllReportResponse, MISFilters, MISReportDetails, REPORT_TYPES } from "./Interfaces";
import MISFilter from "./MISFilter";
import ReportTables from "./ReportTables";
import TabsHeader from "./TabsHeader";

export default function PartnerRiskManagement() {
  const { actor } = useContext(userContext);
  const location = useLocation<any>();

  const [showMISFilterModal, setShowMISFilterModal] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<MISFilters>({});
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [showAddNewReportModal, setShowAddNewReportModal] = useState<boolean>(false);
  const [previewReportDetails, setPreviewReportDetails] = useState<{
    id: number;
    reportType: REPORT_TYPES;
    reportAction: string;
  }>({
    id: null,
    reportType: null,
    reportAction: null,
  });
  const [tabValue, setTabValue] = useState(0);
  const [isLoadingReport, setIsLoadingReports] = useState<boolean>(false);
  const [allCategories, setAllCategories] = useState<string[]>([]);
  const [allLabels, setAllLabels] = useState<string[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [allReports, setAllReports] = useState<MISReportDetails[]>([]);

  const funnelReportColumns = useMemo(
    () => [
      {
        accessorKey: "metric",
        header: "Metric",
        size: 108,
        accessorFn: (row) => row.metric ?? "",
      },
      {
        accessorKey: "numberOfPartners",
        header: "Total Partners",
        size: 121,
        accessorFn: (row) => row.numberOfPartners ?? "",
      },
      {
        accessorKey: "partnersContacted",
        header: "Partners Contacted",
        size: 130,
        accessorFn: (row) => row.partnersContacted ?? "",
      },
      {
        accessorKey: "partnersResponded",
        header: "Partners Responded",
        size: 137,
        accessorFn: (row) => row.partnersResponded ?? "",
      },
      {
        accessorKey: "partnersConfirmedBalance",
        header: "Partner confirmed balance",
        size: 184,
        accessorFn: (row) => row.partnersConfirmedBalance ?? "",
      },
      {
        accessorKey: "partnersSharedLedger",
        header: "Partner Shared Ledger",
        size: 160,
        accessorFn: (row) => row.partnersSharedLedger ?? "",
      },
      {
        accessorKey: "reconDone",
        header: "Recon done",
        size: 139,
        accessorFn: (row) => row.reconDone ?? "",
      },
      {
        accessorKey: "CBDiff",
        header: "CB difference",
        size: 154,
        accessorFn: (row) => row.CBDiff ?? "",
      },
      {
        accessorKey: "ownBalance",
        header: "Own balance",
        size: 147,
        accessorFn: (row) => row.ownBalance ?? "",
      },
      {
        accessorKey: "partnerBalance",
        header: "Partner balance",
        size: 171,
        accessorFn: (row) => row.partnerBalance ?? "",
      },
      {
        accessorKey: "invoiceNotBookedByPartner",
        header: "Invoice Not booked By Partner",
        size: 183,
        accessorFn: (row) => row.invoiceNotBookedByPartner ?? "",
      },
      {
        accessorKey: "invoiceNotBookedByOwn",
        header: "Invoice not booked by Own",
        size: 164,
        accessorFn: (row) => row.invoiceNotBookedByOwn ?? "",
      },
      {
        accessorKey: "invoiceMisMatch",
        header: "Invoice Mismatch",
        size: 128,
        accessorFn: (row) => row.invoiceMisMatch ?? "",
      },
      {
        accessorKey: "paymentNotBookedByPartner",
        header: "Payment Not booked By Partner",
        size: 185,
        accessorFn: (row) => row.paymentNotBookedByPartner ?? "",
      },
      {
        accessorKey: "paymentNotBookedByOwn",
        header: "Payment not booked by Own",
        size: 164,
        accessorFn: (row) => row.paymentNotBookedByOwn ?? "",
      },
      {
        accessorKey: "paymentMisMatch",
        header: "Payment Mismatch",
        size: 129,
        accessorFn: (row) => row.paymentMisMatch ?? "",
      },

      {
        accessorKey: "dncnNotBookedByPartner",
        header: "DN/CN Not booked By Partner",
        size: 189,
        accessorFn: (row) => row.dncnNotBookedByPartner ?? "",
      },
      {
        accessorKey: "dncnNotBookedByOwn",
        header: "DN/CN not booked by Own",
        size: 163,
        accessorFn: (row) => row.dncnNotBookedByOwn ?? "",
      },
      {
        accessorKey: "dncnMisMatch",
        header: "DN/CN Mismatch",
        size: 131,
        accessorFn: (row) => row.dncnMisMatch ?? "",
      },
      {
        accessorKey: "tdsNotBookedByPartner",
        header: "TDS Not booked By Partner",
        size: 184,
        accessorFn: (row) => row.tdsNotBookedByPartner ?? "",
      },
      {
        accessorKey: "tdsNotBookedByOwn",
        header: "TDS not booked by Own",
        size: 164,
        accessorFn: (row) => row.tdsNotBookedByOwn ?? "",
      },
      // {
      //   accessorKey: "invoiceReconciled",
      //   header: "Invoice Reconciled",
      //   size: 250,
      //   accessorFn: (row) => row.invoiceReconciled ?? "",
      // },
      // {
      //   accessorKey: "paymentReconciled",
      //   header: "Payment Reconciled",
      //   size: 250,
      //   accessorFn: (row) => row.paymentReconciled ?? "",
      // },
      {
        accessorKey: "taskLabel",
        header: "Task Label",
        size: 165,
        accessorFn: (row) => row.taskLabel ?? "",
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 240,
        accessorFn: (row) => row.category ?? "",
      },
      {
        accessorKey: "approver",
        header: "Approver",
        size: 185,
        accessorFn: (row) => row.approver ?? "",
      },
      {
        accessorKey: "activityPeriod",
        header: "Activity Period",
        size: 185,
        accessorFn: (row) => row.activityPeriod ?? "",
      },
      {
        accessorKey: "company",
        header: "Company",
        size: 250,
        accessorFn: (row) => row.company ?? "",
      },
      {
        accessorKey: "branch",
        header: "Branch",
        size: 250,
        accessorFn: (row) => row.branch ?? "",
      },
      {
        accessorKey: "relationship",
        header: "Relationship",
        size: 145,
        accessorFn: (row) => row.relationship ?? "",
      },
    ],
    []
  );
  const statusReportColumns = useMemo(
    () => [
      {
        accessorKey: "taskLabel",
        header: "Task Label",
        size: 165,
        accessorFn: (row) => row.taskLabel ?? "",
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 240,
        accessorFn: (row) => row.category ?? "",
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 250,
        accessorFn: (row) => row.status ?? "",
      },
      {
        accessorKey: "approver",
        header: "Approver",
        size: 185,
        accessorFn: (row) => row.approver ?? "",
      },
      {
        accessorKey: "maker",
        header: "Maker",
        size: 185,
        accessorFn: (row) => row.maker ?? "",
      },
      {
        accessorKey: "activityPeriod",
        header: "Activity Period",
        size: 159,
        accessorFn: (row) => row.activityPeriod ?? "",
      },
      {
        accessorKey: "company",
        header: "Company",
        size: 250,
        accessorFn: (row) => row.company ?? "",
      },
      {
        accessorKey: "branch",
        header: "Branch",
        size: 250,
        accessorFn: (row) => row.branch ?? "",
      },
      {
        accessorKey: "relationship",
        header: "Relationship",
        size: 145,
        accessorFn: (row) => row.relationship ?? "",
      },
      {
        accessorKey: "numberOfPartners",
        header: "Number Of Partners",
        size: 194,
        accessorFn: (row) => row.numberOfPartners ?? "",
      },
      {
        accessorKey: "actionOwner",
        header: "Action Owner",
        size: 150,
        accessorFn: (row) => row.actionOwner ?? "",
      },
      {
        accessorKey: "ownCB",
        header: "Own Closing Balance",
        size: 199,
        accessorFn: (row) => row.ownCB ?? "",
      },
      {
        accessorKey: "CBDiff",
        header: "Closing Balance Difference",
        size: 239,
        accessorFn: (row) => row.CBDiff ?? "",
      },
      {
        accessorKey: "averageAging",
        header: "Average Aging (In Days)",
        size: 222,
        accessorFn: (row) => row.averageAging ?? "",
      },
    ],
    []
  );

  const listAllReports = () => {
    setIsLoadingReports(true);
    useFetch<ListAllReportResponse>(API_ENDPOINTS.LIST_ALL_REPORTS_PARTNER_RISK_MANAGEMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.LIST_ALL_REPORTS_PARTNER_RISK_MANAGEMENT.failureMessage,
      data: {
        reportType: tabValue === 0 ? REPORT_TYPES.Funnel : REPORT_TYPES.Status,
        filters: appliedFilters,
      },
      thenCallBack: (response) => {
        setAllCategories(response.data.categories || []);
        setAllLabels(response.data.labels || []);
        setAllUsers(response.data.users || []);
        setAllReports(response.data.reports || []);
        setIsLoadingReports(false);
      },
      catchCallBack: () => {
        setIsLoadingReports(false);
      },
    });
  };

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      {previewReportDetails.reportType ? (
        <AddMISReport
          allLabels={allLabels}
          allCategories={allCategories}
          allUsers={allUsers}
          setPreviewReportDetails={setPreviewReportDetails}
          previewReportDetails={previewReportDetails}
          listAllReports={listAllReports}
          statusReportColumns={statusReportColumns}
          funnelReportColumns={funnelReportColumns}
        />
      ) : (
        <>
          <Header setShowAddNewReportModal={setShowAddNewReportModal} setShowMISFilterModal={setShowMISFilterModal} />
          {isFilterApplied && (
            <AppliedFiltersBar
              appliedFilters={appliedFilters}
              setIsFilterApplied={setIsFilterApplied}
              setAppliedFilters={setAppliedFilters}
            />
          )}
          <TabsHeader tabValue={tabValue} setTabValue={setTabValue} />

          <TabPanel value={tabValue} index={0}>
            <ReportTables
              appliedFilters={appliedFilters}
              reportColumns={funnelReportColumns}
              listAllReports={listAllReports}
              allReports={allReports}
              isLoadingReport={isLoadingReport}
              setPreviewReportDetails={setPreviewReportDetails}
              reportType={REPORT_TYPES.Funnel}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ReportTables
              appliedFilters={appliedFilters}
              reportColumns={statusReportColumns}
              listAllReports={listAllReports}
              allReports={allReports}
              isLoadingReport={isLoadingReport}
              setPreviewReportDetails={setPreviewReportDetails}
              reportType={REPORT_TYPES.Status}
            />
          </TabPanel>
        </>
      )}
      {showAddNewReportModal && (
        <AddNewReportModal
          open={showAddNewReportModal}
          setOpen={setShowAddNewReportModal}
          setPreviewReportDetails={setPreviewReportDetails}
        />
      )}

      {showMISFilterModal && (
        <MISFilter
          useCaseType="mainFilter"
          open={showMISFilterModal}
          setOpen={setShowMISFilterModal}
          allCategories={allCategories}
          allLabels={allLabels}
          allUsers={allUsers}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          setIsFilterApplied={setIsFilterApplied}
        />
      )}
    </LoggedInSkeleton>
  );
}
