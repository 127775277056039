export const ENV_NAME = {
  test: "test",
  staging: "staging",
  production: "production",
} as const;

export const environmentWiseBaseURL = {
  production: "https://app.nakad.co/",
  staging: "https://app-staging.nakad.co/",
  development: "https://app-test.nakad.co/",
  local: "http://localhost:3000/",
  localBackend: "http://localhost:8082/",
  productionBackend: "https://api.nakad.co/",
  stagingBackend: "https://api.staging.nakad.co/",
  developmentBackend: "https://api.dev.nakad.co/",
} as const;

export const getBaseURL = () => {
  let baseURL = "";
  const CURRENT_ENV = import.meta.env.VITE_APP_NAKAD_ENV;
  if (CURRENT_ENV === ENV_NAME.production) {
    baseURL = environmentWiseBaseURL.production;
  } else if (CURRENT_ENV === ENV_NAME.staging) {
    baseURL = environmentWiseBaseURL.staging;
  } else if (CURRENT_ENV === ENV_NAME.test) {
    baseURL = environmentWiseBaseURL.development;
  } else {
    baseURL = environmentWiseBaseURL.local;
  }

  return baseURL;
};
