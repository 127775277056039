import { Delete } from "@mui/icons-material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Box, Button, IconButton, ListSubheader, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext } from "react";
import { userContext } from "src/Components/Contexts/userContext";
import { REPORT_TYPES, REPORT_VIEWS_MAPPING } from "./Interfaces";

const SetReportView = ({ setReportViews, reportViews, setEnableUpdatePreview, reportType }) => {
  const { actor } = useContext(userContext);

  let options = ["taskLabel", "category", "relationship", "approver"];
  if (reportType === REPORT_TYPES.Status) options = ["taskLabel", "category", "relationship", "approver", "maker"];
  if (actor.integration) {
    if (reportType === REPORT_TYPES.Status)
      options = ["taskLabel", "category", "relationship", "company", "branch", "approver", "maker"];
    options = ["taskLabel", "category", "relationship", "company", "branch", "approver"];
  }
  const activityPeriodOptions = ["monthly", "quarterly", "halfYearly", "yearly"];
  const allOptions = [...options, ...activityPeriodOptions];
  const handleAdd = () => {
    if (reportViews.length < allOptions.length && reportViews[reportViews.length - 1] !== "") {
      setReportViews([...reportViews, ""]);
    }
  };

  const handleDelete = (index: number) => {
    setEnableUpdatePreview(true);
    const updatedSelections = reportViews.filter((_, i) => i !== index);
    setReportViews(updatedSelections);
  };

  const handleChange = (value: string, index: number) => {
    setEnableUpdatePreview(true);
    const updatedSelections = reportViews.map((selection, i) => (i === index ? value : selection));
    setReportViews(updatedSelections);
  };

  const isActivityPeriodSelected = reportViews.some((selection) => activityPeriodOptions.includes(selection));

  const availableOptions = (index: number) => {
    const currentSelection = reportViews[index];
    const disabled = reportViews[index + 1] !== currentSelection && isActivityPeriodSelected;

    return {
      mainOptions: options.filter((option) => !reportViews.includes(option) || currentSelection === option),
      periodOptions: activityPeriodOptions.map((option) => ({
        option,
        disabled: disabled && currentSelection !== option,
      })),
    };
  };

  return (
    <Box
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2.5,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ViewModuleIcon color="primary" />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: "14px",
              letterSpacing: "0.1px",
              lineHeight: "157%",
              color: "#000000de",
            }}
          >
            Set Report View
          </Typography>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAdd}
          disabled={reportViews.length >= allOptions.length || reportViews.includes("")}
          sx={{
            fontWeight: 500,
            fontSize: "13px",
            letterSpacing: "0.46px",
            padding: "4px 10px",
            borderRadius: "4px !important",
          }}
        >
          + Add
        </Button>
      </Box>
      {reportViews.map((selection, index) => (
        <Box key={index}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              letterSpacing: "0.4px",
              lineHeight: "166%",
              color: "#00000099",
            }}
          >
            {index === 0 ? "Show data basis on" : "then by"}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            <Select
              size="small"
              value={selection}
              onChange={(e) => handleChange(e.target.value as string, index)}
              displayEmpty
              sx={{ borderRadius: "4px !important", padding: "4px", width: "80%" }}
            >
              {availableOptions(index).mainOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {REPORT_VIEWS_MAPPING[option]}
                </MenuItem>
              ))}
              <ListSubheader>Activity Period</ListSubheader>
              {availableOptions(index).periodOptions.map(({ option, disabled }) => (
                <MenuItem key={option} value={option} disabled={disabled}>
                  {REPORT_VIEWS_MAPPING[option]}
                </MenuItem>
              ))}
            </Select>
            <IconButton onClick={() => handleDelete(index)} sx={{ padding: 1 }}>
              <Delete color="error" />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SetReportView;
