import React, { createContext, Dispatch, SetStateAction, useState } from "react";
import { PageStatus } from "./BalanceConfirmationPortal";
import { BcCustomisationDetails } from "./BalanceConfirmationPortalCommon";
import { NameAndLogo } from "./FinalPage";

type StateDispatch<T = any> = Dispatch<SetStateAction<T>>;

export const BcPortalContext = createContext<IBcPortalContext>(null);

export interface IBcPortalContext {
  confirmStatus: boolean;
  setConfirmStatus: StateDispatch<boolean>;
  pageStatus: PageStatus;
  setPageStatus: StateDispatch<PageStatus>;
  editing: boolean;
  setEditing: StateDispatch<boolean>;
  nameAndLogo: NameAndLogo;
  setNameAndLogo: StateDispatch<NameAndLogo>;
  uploadedFiles: UploadedFiles;
  setUploadedFiles: StateDispatch<UploadedFiles>;
  customization: Customization;
  setCustomization: StateDispatch<Customization>;
}

export type UploadedFiles = {
  ledgers: string[];
  confirmationDocument: string;
  openItems: string[];
};

export type Customization = {
  balanceConfirmed: BcCustomisationDetails;
  balanceNotConfirmed: BcCustomisationDetails;
  ledgerRequest: BcCustomisationDetails;
};

export default function BalanceConfirmationPortalContext({ children }: { children: React.ReactNode }) {
  const [confirmStatus, setConfirmStatus] = useState<boolean>(null);
  const [pageStatus, setPageStatus] = useState<PageStatus>("NotFilled");
  const [editing, setEditing] = useState<boolean>(false);

  const [nameAndLogo, setNameAndLogo] = useState<NameAndLogo>({} as any);

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>({
    confirmationDocument: "",
    ledgers: [],
    openItems: [],
  });

  const [customization, setCustomization] = useState<Customization>({
    balanceConfirmed: {} as BcCustomisationDetails,
    balanceNotConfirmed: {} as BcCustomisationDetails,
    ledgerRequest: {} as BcCustomisationDetails,
  });

  const commonContextValues: IBcPortalContext = {
    confirmStatus,
    setConfirmStatus,
    pageStatus,
    setPageStatus,
    editing,
    setEditing,
    nameAndLogo,
    setNameAndLogo,
    uploadedFiles,
    setUploadedFiles,
    customization,
    setCustomization,
  };

  return <BcPortalContext.Provider value={commonContextValues}>{children}</BcPortalContext.Provider>;
}
